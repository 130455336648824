import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap styles
import Button from 'react-bootstrap/Button';
import styles from './login.module.css'; // Reusing styles from login.module.css
import Login2Image from '../assets/img/studio.svg';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function ResetPassword() {
  const { uidb64, token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    document.title = 'Reset Password';

    // Optionally, you can reset the title when the component unmounts
    return () => {
      document.title = 'Original Title';
    };
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://nachdijawani-melanie.com/api/reset-password/', {
        uidb64,
        token,
        password,
        confirmPassword
      });
      alert(response.data.message);
      navigate('/login'); // Redirect to login page after successful password reset
    } catch (error) {
      console.error('Error resetting password:', error);
      if (error.response && error.response.status === 400 && error.response.data.error === 'Token has expired') {
        alert('The password reset token has expired. Please try again by going back and requesting a new password reset link.');
      } else {
        setError(error.response.data.error || 'Error resetting password. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid position-relative">
      <div className="row">
        <div className={`col-6 col-md-2 position-absolute top-0 end-0 p-3 ${styles.zButton}`}>
          <Button className={`w-100 ${styles.topButton}`}>Home</Button>
        </div>

        <div className={styles.imgcontainer}>
          <img src={Login2Image} alt="Reset Password" />
        </div>
        <div className={styles.LoginForm}>
          <h1>Reset Password</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <input 
                type="password" 
                className="form-control" 
                id="password" 
                required 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
              />
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
              <input 
                type="password" 
                className="form-control" 
                id="confirmPassword" 
                required 
                value={confirmPassword} 
                onChange={(e) => setConfirmPassword(e.target.value)} 
              />
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <button type="submit" className={`btn btn-primary ${styles.formButton}`} disabled={loading}>
              {loading ? 'Resetting...' : 'Reset Password'}
            </button>
          </form>
          <p id="designed">Designed and Developed By <a href='https://codedelic.com'>Codedelic</a></p>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
