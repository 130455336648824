import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbarin from '../components/Navbarin';
import styles from './DholBookings.module.css'
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';;

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-US', options);
}

function DholBookingsPage() {
  const [bookings, setBookings] = useState([]);
  const auth = useAuth() || {};
  const { userName, isLoggedIn, lastName } = auth;

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Dhol Bookings';
    // Make an API request to fetch all dhol bookings
    axios.get('https://nachdijawani-melanie.com/api/dholbookings/')
      .then((response) => {
        // Filter bookings based on payment_status === 'yes'
        const filteredBookings = response.data.filter((booking) => booking.payment_status === 'yes');
        setBookings(filteredBookings);
      })
      .catch((error) => {
        console.error('Error fetching dhol bookings:', error);
      });
  }, []);

  const storedUserType = localStorage.getItem('userType');

  if (!isLoggedIn || storedUserType !== 'admin') {
    navigate('/404');
    return null;
  };

  return (
    <div className={`container-fluid position-relative ${styles.main}`}>
      <div className="row">
        <Navbarin />
        <div className={styles.dholBookingsList}>
          <h1 className={styles.heading}>View All Dhol Bookings</h1>
          <table className={`table table-bordered table-light table-responsive ${styles.table}`}>
            <thead>
              <tr>
                <th>Email</th>
                <th>First Name</th>
                <th>Phone Number</th>
                <th>Event Address</th>
                <th>Event Date</th>
                <th>Dholi Time</th>
                <th>Package Dhol</th>
                <th>Event</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking) => (
                <tr key={booking.id}>
                  <td>{booking.Email}</td>
                  <td>{booking.First_Name}</td>
                  <td>{booking.PhoneNumber}</td>
                  <td>{booking.Event_Address}</td>
                  <td>{formatDate(booking.Event_DateTime)}</td>
                  <td>{booking.Dholi_Time}</td>
                  <td>{booking.Package_Dhol}</td>
                  <td>{booking.Event}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
 
export default DholBookingsPage;