import React, { useEffect } from 'react';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import L from 'leaflet'; // Import Leaflet library
import 'leaflet.locatecontrol/dist/L.Control.Locate.css'; // Import LocateControl CSS
import 'leaflet.locatecontrol'; // Import LocateControl

const centerCoordinates = [43.70940050000001, -79.68300611035428]; // [latitude, longitude]

function MapComponent() {
    useEffect(() => {
        // Define the custom icon using Font Awesome
        const customIcon = L.divIcon({
            className: 'font-awesome-icon', // Custom CSS class
            html: '<i class="fa fa-map-marker fa-2x"></i>', // Font Awesome marker icon
            iconSize: [32, 32], // Set the size of the icon
            iconAnchor: [16, 32], // Set the anchor point of the icon
        });

        // Create a map instance
        const map = L.map('map').setView(centerCoordinates, 15); // Set initial zoom level

        // Add a tile layer (e.g., OpenStreetMap)
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

        // Add a marker to the map with the custom icon
        const marker = L.marker(centerCoordinates, { icon: customIcon }).addTo(map);

        // Add a popup to the marker for additional information
        marker.bindPopup('<b>This is where we are located!</b>').openPopup();

        // Add a control to zoom in and out
        L.control.zoom({ position: 'topright' }).addTo(map);

        // Add a scale bar
        L.control.scale({ position: 'bottomright' }).addTo(map);

        // Add a geolocation button to find the user's location
        L.control.locate().addTo(map);

        // Event handler for clicking on the map
        function onMapClick(e) {
            alert(`You clicked the map at ${e.latlng}`);
        }
        map.on('click', onMapClick);

        // Clean up when the component unmounts
        return () => {
            map.remove();
        };
    }, []);

    return (
        <div>
            <div id="map" style={{ width: '100%', height: '400px' }}></div>
        </div>
    );
}

export default MapComponent;
