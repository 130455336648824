import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';
import styles from './studentdets.module.css';
import Navbarin from '../components/Navbarin';
import { Table } from 'react-bootstrap';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';

function CardPaymentModal({ show, onHide, handlePayment }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Payment Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please press the category of this student to make the payment:</p>
                <Button variant="primary" id={styles.paymentbtn} onClick={() => handlePayment('Gidda')}>
                    Pay for Gidda
                </Button>
                <Button variant="primary" id={styles.paymentbtn} onClick={() => handlePayment('Dhol')}>
                    Pay for Dhol
                </Button>
                <Button variant="primary" id={styles.paymentbtn} onClick={() => handlePayment('bhangra')}>
                    Pay for Bhangra
                </Button><br />


                <div className={styles.termsAndConditionsContainer}>
                    <div className={styles.formField}>
                        <h3 className={styles.sectionTitle}>Terms and Conditions</h3>
                        <p className={styles.sectionText}>
                            1. It is your responsibility to disclose any medical conditions or health issues to management upon registration
                        </p>
                        <p className={styles.sectionText}>
                            2. In order to sign up for a membership, you must agree to obey all class and facility rules and take full responsibility for yourself/your child(ren)s behavior in addition to damage that may be caused by yourself/your child(ren) to the facility used by Nachdi Jawani
                        </p>
                        <p className={styles.sectionText}>
                            3. By signing up for a membership, you acknowledge that participation in dance classes and activities could involve possible personal injury; despite precautions, accidents may occur
                        </p>
                        <p className={styles.sectionText}>
                            <h3 className={styles.highlight}>  By signing up for a membership, you assume the following responsibilities: </h3><br />
                            ● I/We assume all risks related to using any and all spaces by and programs, activities,
                            rehearsals or shows run by Nachdi Jawani <br />
                            ● I/We agree to release and hold harmless Nachdi Jawani, including its teachers,
                            dancers, staff members, volunteers and facilities from any cause of action, claims or
                            demands now and in the future <br />
                            ● I/We will not hold Nachdi Jawani liable for personal injury or personal property damage
                            that may occur before, during or after classes <br />
                            ● I/We agree to allow Nachdi Jawani to photograph/video students and families of Nachdi
                            Jawani and share on the Nachdi Jawani website, social media pages, brochures, or other promotional material - no identifying information is ever published without permission <br />
                        </p>
                        <p className={styles.sectionText}>
                            <h3 className={styles.highlight}>Communication:</h3> Any changes regarding schedule, class cancellations, events, etc. <br />
                            ● By email and NJ Student Account  <br />
                            *The academy maintains the right to cancel or reschedule classes - There are no make-up classes  <br />
                            NOTE: It is your responsibility to check emails & student box for weekly updates  <br />
                        </p>
                        <p className={styles.sectionText}>
                            <h3 className={styles.highlight}> Annual Academy Closure </h3> <br />
                            ● During all long weekends, Inter-Academy competitions and Malton Nagar Kirtan  <br />
                            ● This does not affect any monthly class fees  <br />
                        </p>
                        <p className={styles.sectionText}>
                            <h3 className={styles.highlight}> Membership: </h3> <br />
                            ● A non-refundable one time registration fee of $20 per person must be paid upon registration <br />
                            ● This registration fee applies all new students as well as returning students ; following previous cancellation <br />
                            ● Fees are subject to change <br />
                            ● Payment will be automatically withdrawn from your account on 1st of every month   <br />
                            ● Any NSF payment will result in a $30 fee <br />
                            ● 4 week notice in person along with a signed cancellation waiver is required for   <br />
                            membership cancellation in order to stop payment from your account <br />
                            ● ALL payments are non-refundable and cannot be reversed <br />
                            ● The studio shall have no liability to individuals to refund fees after they have been
                            collected and remitted to third parties in accordance with stated purpose for the collection of the fee <br />
                        </p>
                        <p className={styles.sectionText}>
                            <h3 className={styles.highlight}> Fees: </h3> <br />
                            Cash payment options are available in 6 month increments ; you must prepay for 6 months of classes  <br />
                            ● Cash payments must be paid within the first week of January and the first week of July  <br />
                            ● Any late payments will result in a $30 late fee  <br />
                            Membership Fees   <br />
                            ● One and a half hour - Once per week: $80+hst per month   <br />
                            ● Once hour class - Once per week: $70+hst per month   <br />
                            Dress Service Fee:   <br />
                            ● To be charged once your child is selected to be on a team - $250.00 for 2 years   <br />
                            ● You must remain on the team for a continuous 2 years; you must register and make   <br />
                            payment for this service again if membership was previously canceled   <br />
                            ● Payment is non-refundable   <br />
                        </p>
                        <p className={styles.sectionText}>
                            <h3 className={styles.highlight}> Attendance: </h3><br />
                            ● Weekly class attendance is mandatory <br />
                            ● Valid reason for absence is required for missing classes <br />
                            ● Teams: All vacations must approved by management and 50% of the monthly fee will be
                            charged <br />
                            ● Regular Classes: $10 per month is applied for keep membership valid <br />
                            ● Upon completion of your vacation, please call the academy to confirm your weekly
                            schedules and get any updates to timings/days <br />
                            ● NO refunds will be given for missed classes <br />
                        </p>
                        <p className={styles.sectionText}>
                            <h3 className={styles.highlight}> Rules and Regulations: </h3><br />
                            ● Be on time; arrive 5 mins prior to the start of class  <br />
                            ● Videography is not permitted  <br />
                            ● Parents must not interfere with ongoing classes; see front desk with for concerns
                            or questions  <br />
                            ● All shoes to be put on shoe rack  <br />
                            ● NO plastic water bottles - please ensure you bring a reusable water bottle  <br />
                            ● Must show respect to instructors and peers at all time; inappropriate behavior will not
                            tolerated and may result in termination of your membership  <br />
                            ● Do not bring money or valuables to the studio; we are not responsible for lost or stolen
                            items  <br />
                            ● Teams only: Weekly video upload is mandatory  <br />
                            ● Teams only: A $20 fee will be charged per competition for all Inter-Academy
                            competitions   <br />
                            ● Teams only: All team members must have a valid dress contract   <br />

                        </p>
                        <p className={styles.sectionText}>
                            I acknowledge and agree to all membership rules and regulations outlined in this agreement. I have thoroughly read this agreement and fully understand the contents, meaning and impacts of this agreement. I understand that I am free to express any specific questions regarding this agreement prior to signing and I agree that my failure to do so will be interpreted as a free knowledgeable acceptance of the terms of this agreements.

                        </p>
                    </div>
                </div>


            </Modal.Body>
        </Modal>
    );
}


function EditStudentInfoModal({ show, onHide, studentData, onUpdate }) {
    const [editedData, setEditedData] = useState({
        First_Name: studentData.First_Name,
        Last_Name: studentData.Last_Name,
        DOB: studentData.DOB,
        Email: studentData.Email,
        phoneNumber: studentData.phoneNumber,
        parentPhoneNumber: studentData.parentPhoneNumber || '',
        parentFirstName: studentData.parentFirstName || '',
        parentLastName: studentData.parentLastName || '',
        HealthConditions: studentData.HealthConditions || '',
        gender: studentData.gender || '',
        cust_id: parseInt(studentData.cust_id, 10) || null,
        discounted: studentData.status == 'discounted' ? true : false
    });
    console.log(editedData.discounted)
    const [selectedImage, setSelectedImage] = useState(null);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        // Handle checkbox differently
        const newValue = type === 'checkbox' ? checked : value;

        setEditedData((prevData) => ({
            ...prevData,
            [name]: newValue,
        }));
    };




    const handleSubmit = async () => {
        try {
            // Assuming you have the Django API endpoint for updating a student
            const apiUrl = `https://nachdijawani-melanie.com/api/update_student/${studentData.ID}/`;

            // Create a FormData object to handle multipart/form-data (including image upload)
            const formData = new FormData();

            // Append existing data to the form data
            Object.entries(editedData).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // Append new image to the form data, if selected
            if (selectedImage) {
                formData.append('Image', selectedImage);
            }

            // Make an API request to update the student information
            const response = await axios.put(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Check if the request was successful
            if (response.status === 200) {
                // Handle the success, e.g., close the modal or show a success message
                onHide();
                onUpdate(editedData);

            } else {
                // Handle the error, e.g., show an error message
                console.error('Error updating student information:', response.data);
            }
        } catch (error) {
            // Handle any unexpected errors
            console.error('Unexpected error updating student information:', error);
        }
    };





    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Student Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="First_Name">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="First_Name"
                            value={editedData.First_Name}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="Last_Name">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="Last_Name"
                            value={editedData.Last_Name}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="DOB">
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control
                            type="date"
                            name="DOB"
                            value={editedData.DOB}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="cust_id">
                        <Form.Label>Customer ID</Form.Label>
                        <Form.Control
                            type="number"
                            name="cust_id"
                            value={editedData.cust_id}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="gender">
                        <Form.Label>Gender</Form.Label>
                        <Form.Control
                            as="select"
                            name="gender"
                            value={editedData.gender}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="Email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="Email"
                            value={editedData.Email}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="phoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="tel"
                            name="phoneNumber"
                            value={editedData.phoneNumber}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="parentPhoneNumber">
                        <Form.Label>Parent's Phone Number</Form.Label>
                        <Form.Control
                            type="tel"
                            name="parentPhoneNumber"
                            value={editedData.parentPhoneNumber}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="parentFirstName">
                        <Form.Label>Parent's First Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="parentFirstName"
                            value={editedData.parentFirstName}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="parentLastName">
                        <Form.Label>Parent's Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="parentLastName"
                            value={editedData.parentLastName}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="HealthConditions">
                        <Form.Label>Health Conditions</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="HealthConditions"
                            value={editedData.HealthConditions}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="Image">
                        <Form.Label>Upload Image</Form.Label>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            onChange={(e) => setSelectedImage(e.target.files[0])}
                        />
                    </Form.Group>
                    <Form.Group controlId="discounted">
                        <Form.Label>Discounted</Form.Label>
                        <Form.Check
                            type="checkbox"
                            label="Discounted"
                            name="discounted"
                            checked={editedData.discounted}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}



function StudentDetailsPage() {

    const [studentData, setStudentData] = useState(null);
    const { studentName, email } = useParams();
    const [allClasses, setAllClasses] = useState([]);
    const [allTeams, setAllTeams] = useState([]);
    const [showEditClassModal, setShowEditClassModal] = useState(false);
    const [showEditTeamModal, setShowEditTeamModal] = useState(false);
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const userType = localStorage.getItem('userType');
    const [attendanceData, setAttendanceData] = useState([]);
    const [studentUpdateData, setStudentUpdateData] = useState([]);
    const { isLoggedIn } = useAuth() || {};
    const navigate = useNavigate();
    const [showEditPaymentModal, setShowEditPaymentModal] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(''); // Default to cash
    const [showSetOnHoldModal, setShowSetOnHoldModal] = useState(false);
    const [onHoldStartDate, setOnHoldStartDate] = useState('');
    const [onHoldEndDate, setOnHoldEndDate] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [showCardPaymentModal, setShowCardPaymentModal] = useState(false);
    const [cardPaymentModalContent, setCardPaymentModalContent] = useState('');
    const [showEditStudentModal, setShowEditStudentModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successModalButtons, setSuccessModalButtons] = useState([]);
    const [receiptStartDate, setReceiptStartDate] = useState('');
    const [receiptEndDate, setReceiptEndDate] = useState('');
    const [showSendReceiptModal, setShowSendReceiptModal] = useState(false);


    const options = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };



    useEffect(() => {
        document.title = 'Students Details';

        return () => {
            document.title = 'Original Title';
        };
    }, []);

    useEffect(() => {


        // Fetch student details
        axios.get(`https://nachdijawani-melanie.com/api/student-details/${studentName}/${email}/`)
            .then((response) => {
                setStudentData(response.data);
                setSelectedClasses(response.data.Classes_Enrolled_In || []);
                axios.post('https://nachdijawani-melanie.com/api/check-and-update-student-status/', {
                    first_name: response.data.First_Name,
                    last_name: response.data.Last_Name,
                })
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.error('Error calling check-and-update-student-status API', error);
                    });
                setSelectedTeams(response.data.Teams_Enrolled_In || []);
            })
            .catch((error) => {
                console.error('Error fetching student details', error);
            });

        // Fetch all classes and teams
        axios.get('https://nachdijawani-melanie.com/api/all-classes/')
            .then((response) => {
                setAllClasses(response.data);
            })
            .catch((error) => {
                console.error('Error fetching classes', error);
            });

        axios.get('https://nachdijawani-melanie.com/api/all-teams/')
            .then((response) => {
                setAllTeams(response.data);
            })
            .catch((error) => {
                console.error('Error fetching teams', error);
            });

        if (userType === 'admin') {
            axios.get(`https://nachdijawani-melanie.com/api/attendance/${studentName}/${email}/`)
                .then((response) => {
                    setAttendanceData(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching attendance data', error);
                });

            axios.get(`https://nachdijawani-melanie.com/api/get-payment-method/${studentName}/${email}/`)
                .then((response) => {
                    setPaymentMethod(response.data.Payment_Method || null);
                })
                .catch((error) => {
                    console.error('Error fetching payment method', error);
                });
        }

        if (userType === 'admin') {
            axios.get(`https://nachdijawani-melanie.com/api/student-updates/${studentName}/${email}/`)
                .then((response) => {
                    setStudentUpdateData(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching student update data', error);
                });
        }
    }, [studentName, isLoggedIn, userType, navigate]);

    if (!isLoggedIn) {
        navigate('/404');
    }

    const generateRandomCustomerId = () => {
        return Math.floor(Math.random() * 1000000);
    };


    const handleUpdateStudentData = (updatedData) => {
        // Fetch student details
        axios.get(`https://nachdijawani-melanie.com/api/student-details/${studentName}/${email}/`)
            .then((response) => {
                setStudentData(response.data);
                setSelectedClasses(response.data.Classes_Enrolled_In || []);
                axios.post('https://nachdijawani-melanie.com/api/check-and-update-student-status/', {
                    first_name: response.data.First_Name,
                    last_name: response.data.Last_Name,
                })
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.error('Error calling check-and-update-student-status API', error);
                    });
                setSelectedTeams(response.data.Teams_Enrolled_In || []);
            })
            .catch((error) => {
                console.error('Error fetching student details', error);
            });

        // Fetch all classes and teams
        axios.get('https://nachdijawani-melanie.com/api/all-classes/')
            .then((response) => {
                setAllClasses(response.data);
            })
            .catch((error) => {
                console.error('Error fetching classes', error);
            });

        axios.get('https://nachdijawani-melanie.com/api/all-teams/')
            .then((response) => {
                setAllTeams(response.data);
            })
            .catch((error) => {
                console.error('Error fetching teams', error);
            });

        if (userType === 'admin') {
            axios.get(`https://nachdijawani-melanie.com/api/attendance/${studentName}/${email}/`)
                .then((response) => {
                    setAttendanceData(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching attendance data', error);
                });

            axios.get(`https://nachdijawani-melanie.com/api/get-payment-method/${studentName}/${email}/`)
                .then((response) => {
                    setPaymentMethod(response.data.Payment_Method || null);
                })
                .catch((error) => {
                    console.error('Error fetching payment method', error);
                });
        }

        if (userType === 'admin') {
            axios.get(`https://nachdijawani-melanie.com/api/student-updates/${studentName}/${email}/`)
                .then((response) => {
                    setStudentUpdateData(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching student update data', error);
                });
        }

    };

    const handleEditClass = () => {
        axios.patch(`https://nachdijawani-melanie.com/api/edit-classes/${studentData.ID}/`, { Classes_Enrolled_In: selectedClasses })
            .then((response) => {
                setStudentData({ ...studentData, Classes_Enrolled_In: selectedClasses });
                setShowEditClassModal(false);
            })
            .catch((error) => {
                console.error('Error updating classes', error);
            });
    };

    const handleEditTeam = () => {
        axios.patch(`https://nachdijawani-melanie.com/api/edit-teams/${studentData.ID}/`, { Teams_Enrolled_In: selectedTeams })
            .then((response) => {
                setStudentData({ ...studentData, Teams_Enrolled_In: selectedTeams });
                setShowEditTeamModal(false);
            })
            .catch((error) => {
                console.error('Error updating teams', error);
            });
    };

    const handleEditPayment = () => {
        axios
            .patch(`https://nachdijawani-melanie.com/api/update-payment-method/${studentData.ID}/`, { paymentMethod })
            .then((response) => {
                setShowEditPaymentModal(false);
                setFormSubmitted(true);

                if (paymentMethod === 'card') {
                    const customerId = generateRandomCustomerId();
                    const email = encodeURIComponent(studentData.Email);
                    setCardPaymentModalContent(`https://nachdijawani-melanie.com/recurpay/${customerId}/${email}`);
                    setShowCardPaymentModal(true);
                }
            })
            .catch((error) => {
                console.error('Error updating payment method', error);
            });
    };

    if (!studentData) {
        return <p>Loading...</p>;
    }

    const handleSetOnHold = () => {
        axios.post(`https://nachdijawani-melanie.com/api/set-student-on-hold/`, {
            First_Name: studentData.First_Name,
            Last_Name: studentData.Last_Name,
            Email: studentData.Email,
            start_date: onHoldStartDate,
            end_date: onHoldEndDate,
        })
            .then((response) => {
                setShowSetOnHoldModal(false);

                const customerId = generateRandomCustomerId();
                const email = encodeURIComponent(studentData.Email);
                const firstName = encodeURIComponent(studentData.First_Name);
                const lastName = encodeURIComponent(studentData.Last_Name);
                const phoneNumber = encodeURIComponent(studentData.Phone_Number);



                setSuccessModalButtons([
                    { label: 'Team', link: `https://nachdijawani-melanie.com/holdpay/Team/${customerId}/${email}/${firstName}/${lastName}/${phoneNumber}/` },
                    { label: 'Gidda/Bhangra/Dhol Class', link: `https://nachdijawani-melanie.com/holdpay/Class/${customerId}/${email}/${firstName}/${lastName}/${phoneNumber}/` },

                ]);
                setShowSuccessModal(true);
            })
            .catch((error) => {
                console.error('Error setting student on hold', error);
            });
    };

    const handleEditStudent = () => {
        setShowEditStudentModal(true);
    };

    const handleSendReceipt = () => {
        const apiUrl = `https://nachdijawani-melanie.com/api/send-receipt/${studentData.First_Name}/${studentData.Last_Name}/`;

        // Make an API request to send the receipt
        axios.post(apiUrl, {
            start_date: receiptStartDate,
            end_date: receiptEndDate,
        })
            .then(() => {
                // Request was successful
                // Handle the success, e.g., close the modal or show a success message
                setShowSendReceiptModal(false);
                // Add any additional logic here based on the response
            })
            .catch(error => {
                // Handle any unexpected errors or errors from the server
                // Show an error message or perform other error-handling actions
                console.error('Error sending receipt:', error);
            });
    };


    const handlePayment = (category) => {
        // Implement your payment logic based on the selected category
        const customerId = generateRandomCustomerId();
        const email = encodeURIComponent(studentData.Email);
        const firstName = encodeURIComponent(studentData.First_Name);
        const lastName = encodeURIComponent(studentData.Last_Name);
        const phoneNumber = encodeURIComponent(studentData.Phone_Number);


        let paymentUrl = '';

        if (category === 'Gidda') {
            paymentUrl = `https://nachdijawani-melanie.com/recurpay/Gidda/${customerId}/${email}/${firstName}/${lastName}/${phoneNumber}/`;
        } else if (category === 'Dhol') {
            paymentUrl = `https://nachdijawani-melanie.com/recurpay/Dhol/${customerId}/${email}/${firstName}/${lastName}/${phoneNumber}/`;
        } else if (category === 'bhangra') {
            paymentUrl = `https://nachdijawani-melanie.com/recurpay/bhangra/${customerId}/${email}/${firstName}/${lastName}/${phoneNumber}/`;
        }

        // Now, you can handle redirection or other actions based on paymentUrl
        window.location.href = paymentUrl;
    };




    return (
        <div className={styles.wrapper}>
            <div className="container-fluid position-relative" id={styles.main}>
                <div className="row">
                    <Navbarin />
                    <div className={styles.container}>
                        <div className={styles.avatarContainer}>
                            <img src={studentData.Image_URL} alt={`${studentData.First_Name} ${studentData.Last_Name}`} className={styles.avatarImage} />
                            <h1>{studentData.First_Name} {studentData.Last_Name}</h1>
                        </div>
                        <div>
                            <p>Email: {studentData.Email}</p>
                            <p>Customer ID: {studentData.cust_id || 'Not Set'}</p>
                            <p>Class: {studentData.Classes_Enrolled_In ? studentData.Classes_Enrolled_In.join(', ') : 'N/A'}</p>
                            <p>Team: {studentData.Teams_Enrolled_In ? studentData.Teams_Enrolled_In.join(', ') : 'N/A'}</p>
                            {/* Add other sections for dress information, attendance, payment details, etc. */}
                            {userType === 'admin' && (
                                <>
                                    <Button variant="primary" className={styles.editButton} onClick={() => setShowEditClassModal(true)}>
                                        Edit Classes
                                    </Button>
                                    <Button variant="primary" className={styles.editButton} onClick={() => setShowEditTeamModal(true)}>
                                        Edit Teams
                                    </Button>
                                    <Link to={`/cancel?first_name=${studentData.First_Name}&last_name=${studentData.Last_Name}&email=${studentData.Email}`}>
                                        <Button variant="primary" className={styles.editButton} >
                                            Cancel Membership
                                        </Button>
                                    </Link>
                                    <Button variant="primary" className={styles.editButton} onClick={() => setShowSetOnHoldModal(true)}>
                                        Set on Hold
                                    </Button>

                                    <Button variant="primary" className={styles.editButton} onClick={handleEditStudent}>
                                        Edit Student Information
                                    </Button>

                                    <Button variant="primary" className={styles.editButton} onClick={() => setShowSendReceiptModal(true)}>
                                        Send Receipt
                                    </Button>
                                    <Button variant="primary" className={styles.editButton} onClick={() => setShowEditPaymentModal(true)}>
                                        Edit Payment Method
                                    </Button>


                                </>
                            )}

                        </div>
                        {userType === 'admin' && (
                            <div className={styles.attendancetab}>
                                <h2 id={styles.headingattendance}>Attendance Data</h2>
                                <Table striped bordered hover responsive id={styles.attendancetable}>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Class ID</th>
                                            <th>Class Name</th>
                                            <th>Team ID</th>
                                            <th>Team Name</th>
                                            <th>Present</th>
                                            <th>Extra Class</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attendanceData.map((attendanceItem) => (
                                            <tr key={attendanceItem.ID}>
                                                <td>{new Date(attendanceItem.Attendance_Datetime).toLocaleString('en-US', { timeZone: 'UTC', ...options })}</td>
                                                <td>{attendanceItem.Class_ID}</td>
                                                <td>{attendanceItem.Class_Name ? attendanceItem.Class_Name : "None"}</td>
                                                <td>{attendanceItem.Team_ID}</td>
                                                <td>{attendanceItem.Team_Name ? attendanceItem.Team_Name : "None"}</td>
                                                <td>{attendanceItem.Present ? 'Yes' : 'No'}</td>
                                                <th>{attendanceItem.Extra_Class ? 'Yes' : 'No'}</th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        )}
                        {userType === 'admin' && (
                            <>
                                <h2 id={styles.headingupdates}>Student Updates</h2>
                                <Table striped bordered hover responsive id={styles.updatesTable}>
                                    <thead>
                                        <tr>
                                            <th>Timestamp</th>
                                            <th>Update Content</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentUpdateData.map((updateItem) => (
                                            <tr key={updateItem.id}>
                                                <td>{new Date(updateItem.timestamp).toLocaleString()}</td>
                                                <td>{updateItem.content}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </>
                        )}

                    </div>
                </div>

                {/* Modals */}
                <Modal show={showEditClassModal} onHide={() => setShowEditClassModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Classes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {allClasses.map((classItem) => (
                                <Form.Check
                                    key={classItem.ID}
                                    type="checkbox"
                                    id={classItem.ID}
                                    label={classItem.Class_Name_Batch}
                                    checked={selectedClasses.includes(classItem.Class_Name_Batch)}
                                    onChange={() => {
                                        setSelectedClasses((prevSelectedClasses) =>
                                            prevSelectedClasses.includes(classItem.Class_Name_Batch)
                                                ? prevSelectedClasses.filter((item) => item !== classItem.Class_Name_Batch)
                                                : [...prevSelectedClasses, classItem.Class_Name_Batch]
                                        );
                                    }}
                                />
                            ))}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowEditClassModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleEditClass}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showEditPaymentModal} onHide={() => setShowEditPaymentModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Payment Method</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Check
                                type="radio"
                                id="cash"
                                label="Cash"
                                checked={paymentMethod === 'cash'}
                                onChange={() => setPaymentMethod('cash')}
                            />
                            <Form.Check
                                type="radio"
                                id="card"
                                label="Card"
                                checked={paymentMethod === 'card'}
                                onChange={() => setPaymentMethod('card')}
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowEditPaymentModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleEditPayment}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showEditTeamModal} onHide={() => setShowEditTeamModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Teams</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {allTeams.map((teamItem) => (
                                <Form.Check
                                    key={teamItem.ID}
                                    type="checkbox"
                                    id={teamItem.ID}
                                    label={teamItem.Team_Name_Batch}
                                    checked={selectedTeams.includes(teamItem.Team_Name_Batch)}
                                    onChange={() => {
                                        setSelectedTeams((prevSelectedTeams) =>
                                            prevSelectedTeams.includes(teamItem.Team_Name_Batch)
                                                ? prevSelectedTeams.filter((item) => item !== teamItem.Team_Name_Batch)
                                                : [...prevSelectedTeams, teamItem.Team_Name_Batch]
                                        );
                                    }}
                                />
                            ))}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowEditTeamModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleEditTeam}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showSetOnHoldModal} onHide={() => setShowSetOnHoldModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Set Student On Hold</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="onHoldStartDate">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={onHoldStartDate}
                                    onChange={(e) => setOnHoldStartDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="onHoldEndDate">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={onHoldEndDate}
                                    onChange={(e) => setOnHoldEndDate(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowSetOnHoldModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSetOnHold}>
                            Set On Hold
                        </Button>
                    </Modal.Footer>
                </Modal>

                <EditStudentInfoModal
                    show={showEditStudentModal}
                    onHide={() => setShowEditStudentModal(false)}
                    studentData={studentData}
                    onUpdate={handleUpdateStudentData}
                />


                <CardPaymentModal
                    show={showCardPaymentModal}
                    onHide={() => setShowCardPaymentModal(false)}
                    content={cardPaymentModalContent}
                    handlePayment={handlePayment}
                />



                <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Set Student On Hold Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Set on hold operation was successful!</p>
                        <p>Choose an action:</p>
                        {successModalButtons.map((button, index) => (
                            <Button key={index} style={{ marginLeft: '5%' }} variant="primary" onClick={() => window.location.href = button.link}>
                                {button.label}
                            </Button>
                        ))}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowSuccessModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showSendReceiptModal} onHide={() => setShowSendReceiptModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Receipt</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="receiptStartDate">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={receiptStartDate}
                                    onChange={(e) => setReceiptStartDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="receiptEndDate">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={receiptEndDate}
                                    onChange={(e) => setReceiptEndDate(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowSendReceiptModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSendReceipt}>
                            Send Receipt
                        </Button>
                    </Modal.Footer>
                </Modal>


            </div>
        </div>
    );
}

export default StudentDetailsPage;
