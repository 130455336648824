import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Button from 'react-bootstrap/Button'; // Import the Button component
import styles from './Home.module.css'; // Import local CSS module
import LandingImage from '../assets/img/main.jpg';
import { Link } from 'react-router-dom';
import main from '../assets/img/logo4.png';

function Home() {
    useEffect(() => {
        document.title = 'Home';

        // Optionally, you can reset the title when the component unmounts
        return () => {
            document.title = 'Original Title';
        };
    }, []);

    return (
        <div className="container-fluid position-relative" id={styles.maincontt}>
            <div className="row">
           
                <div className={`col-6 col-md-2 position-absolute top-0 start-0 p-3 ${styles.zButton}`}>
                    <Link to="/login">
                        <Button className={`w-100 ${styles.topButton}`}>Login</Button>
                    </Link>
                </div>
                <div className={`col-6 col-md-2 position-absolute top-0 end-0 p-3 ${styles.zButton}`}>
                    <Link to="/explore">
                        <Button className={`w-100 ${styles.topButton}`}>Explore</Button>
                    </Link>
                </div>
                <div className={styles.imgcontainer}>
                <img src={LandingImage} alt="Nachdi Jawani" id={styles.logo} />
                </div>
                <div className={styles.containerlogo}>
                    <img src={main} alt="Nachdi Jawani" id={styles.mainImage} />
                    <h6 className={styles.overlayheading}>Melanie</h6> 
                </div>
            </div>
        </div>
    );
}

export default Home;