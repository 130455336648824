// Login.js
import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import styles from './login.module.css';
import LoginImage from '../assets/img/studio.png';
import Login2Image from '../assets/img/studio.svg';
import { Link } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import { AuthProvider } from '../components/AuthContext';
import '../global.css'
 
function Login() {
  useEffect(() => {
    document.title = 'Login';

    // Optionally, you can reset the title when the component unmounts
    return () => {
      document.title = 'Original Title';
    };
  }, []);

  return (
    <div className="container-fluid position-relative">
      <div className="row">
        <div className={`col-6 col-md-2 position-absolute top-0 end-0 p-3 ${styles.zButton}`}>
          <Link to="/">
            <Button className={`w-100 ${styles.topButton}`}>Home</Button>
          </Link>
        </div>

        <div className={styles.imgcontainer}>
          <img src={Login2Image} alt="Example" />
        </div>
        <div className={styles.LoginForm}>
         
            <LoginForm />

            <p id="designed">Designed and Developed By <a href='https://codedelic.com'>Codedelic</a></p>
        </div>
       

      </div>
    </div>
  );
}

export default Login;
