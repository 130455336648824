import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const PaymentHoldRecur = () => {
  const {
    category,
    customerId,
    email,
    firstName,
    lastName,
    phoneNumber,
  } = useParams();
  const navigate = useNavigate();
  let amount = 0.00;
  let taxRate = 0.13;
  let taxAmount = 0.00;
  let totalAmount = 0.00;

  // Check if the category contains specific keywords and set the amount accordingly
  if (category.toLowerCase().includes('team')) {
    amount = 35.00;
  } else if (category.toLowerCase().includes('class')) {
    amount = 10.00;
  }

  taxAmount = amount * taxRate;
  totalAmount = amount + taxRate;

  const paymentData = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    customerId: customerId,
    amount: totalAmount.toFixed(2),
    taxAmount: taxAmount.toFixed(2),
  };

  const [ticket, setTicket] = useState(null);

  useEffect(() => {
    // Make the initial axios POST request to get the ticket
    axios.post('https://nachdijawani-melanie.com/api/moneris/', paymentData)
      .then(response => {
        const ticketFromResponse = response.data?.response?.ticket;

        if (ticketFromResponse) {
          setTicket(ticketFromResponse);
        } else {
          console.error('Ticket not found in the response.');
        }
      })
      .catch(error => {
        console.error('Error fetching ticket:', error);
      });
  }, []); // Empty dependency array to run the effect only once

  useEffect(() => {
    if (window.monerisCheckout && ticket) {
      const myCheckout = new window.monerisCheckout();
      myCheckout.setMode('prod');
      myCheckout.setCheckoutDiv('monerisCheckout');

      // Set up callbacks (replace these with your actual callback functions)
      myCheckout.setCallback('page_loaded', () => {
        console.log('Moneris Checkout page loaded.');
      });

      myCheckout.setCallback('cancel_transaction', () => {
        console.log('Transaction cancelled.');
      });

      myCheckout.setCallback('error_event', (error) => {
        console.error('Moneris Checkout error:', error);

      });

      myCheckout.setCallback('payment_receipt', (receipt) => {
        console.log('Payment receipt:', receipt);

      });

      myCheckout.setCallback('payment_complete', () => {
        console.log('Payment complete.');
        axios.post(`https://nachdijawani-melanie.com/api/moneris_receipt_hold/${ticket}/`)
          .then(response => {


            if (response.status == 200) {
              window.alert(response.data.status);
              window.location.href = 'https://nachdijawani-melanie.com/admin/';

            }
          })
          .catch(error => {
            window.alert('error processing payment')
          });
      });

      // Start Moneris Checkout with the received ticket
      myCheckout.startCheckout(ticket);
    } else {
      console.error('Moneris Checkout library not available or ticket not available.');
    }
  }, [ticket]); // Run this effect whenever the ticket changes

  return (
    <div>
      <p>Redirecting to Moneris hosted page for recurring payment...</p>
      <div id="monerisCheckout"></div>
    </div>
  );
};

export default PaymentHoldRecur;
