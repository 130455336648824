import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Navbarin from '../components/Navbarin';
import axios from 'axios';
import styles from './classes.module.css';
import { Modal, Form, Button } from 'react-bootstrap';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import ProductCard from '../components/ProductCard';

function Products() {
    const [products, setProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        Name: '',
        Description: '',
        Price: '',
        Image: null,
    });

    const { isLoggedIn } = useAuth() || {};
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Products';

        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://nachdijawani-melanie.com/api/all-products/');
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, [isLoggedIn]);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setFormData({
            Name: '',
            Description: '',
            Price: '',
            Image: null,
        });
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;

        setFormData({
            ...formData,
            [name]: name === 'Image' ? files[0] : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }

        try {
            await axios.post('https://nachdijawani-melanie.com/api/submit-product/', formDataToSend);

            const response = await axios.get('https://nachdijawani-melanie.com/api/all-products/');
            setProducts(response.data);
            handleCloseModal();
        } catch (error) {
            console.error('Error creating product:', error);
        }
    };

    return (
        <div className="container-fluid position-relative" id={styles.main}>
            <Navbarin />

            {localStorage.getItem('userType') === 'admin' && isLoggedIn && (
                <button onClick={handleOpenModal} className="btn btn-primary" id={styles.createbtn}>
                    Add Product
                </button>
            )}

            <h1 className={styles.headings}>
                Available <span>Products:</span>
            </h1>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a Product</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Name:</Form.Label>
                            <Form.Control type="text" name="Name" value={formData.Name} onChange={handleInputChange} required />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description:</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="Description"
                                value={formData.Description}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Price:</Form.Label>
                            <Form.Control type="text" name="Price" value={formData.Price} onChange={handleInputChange} required />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Image:</Form.Label>
                            <Form.Control type="file" accept="image/*" name="Image" onChange={handleInputChange} required />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button type="submit" variant="primary">
                            Save Product
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <div className="product-list" id={styles.cardlist}>
                {products.map((product) => (
                    <ProductCard key={product.id} product={product} />
                ))}
            </div>
        </div>
    );
}

export default Products;
