// LoginForm.js
import React, { useState } from 'react';
import styles from '../pages/login.module.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username || !password) { 
      setError('Please fill in all fields.');
      return;
    }

    try {
      const response = await axios.post('https://nachdijawani-melanie.com/api/login/', { username, password });
      const { user_type, First_Name, Last_Name, instructor_classes, student_classes, instructor_teams, student_teams, userID,Email } = response.data;

      if (user_type) {
        login(First_Name, Last_Name, instructor_classes || student_classes, instructor_teams || student_teams,userID,Email);

        if (user_type === 'Admin') {
          navigate('/admin');
          localStorage.setItem('userType', 'admin');
        } else if (user_type === 'Instructor') {
          navigate('/instructor/');
          localStorage.setItem('userType', 'Instructor');
        } else if (user_type === 'student') {
          navigate('/user/');
          localStorage.setItem('userType', 'student');
        }
      else if (user_type === 'volunteer') {
        navigate('/volunteerAnnouncements/');
        localStorage.setItem('userType', 'volunteer');
    
      }
      } else {
        setError('Invalid username or password.');
      }
    } catch (error) {
      // Handle errors as needed
      setError('An error occurred during login.');
    }
  };

  return (
    <div className="container" id={styles.container}>
      <div className="row justify-content-center">
        <div className="col-md-6" id={styles.columnform}>
          <h1 className={styles.loginheading}>Login</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">Username</label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {error && <p className="text-danger bold" id={styles.error}>Incorrect Username or Password</p>}
            <button type="submit" className={`btn btn-primary ${styles.formButton}`}>LOGIN</button>
            
            <p className={styles.forgot}>
            <a href="/forgot-password">Forgot Password?</a>
            </p>
  
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
