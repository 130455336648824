// TwoCards.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './2cards.module.css';
import dhol from '../assets/img/dhol.png';
import bhangra from '../assets/img/bhangra.jpg';
import gidda from '../assets/img/gidda.png';
import { Link } from 'react-router-dom';

function TwoCards() {
  return (
    <div className="container">
      <div className="row">
        {/* Card 1 */}
        <div className="col-md-6">
          <div className={`card ${styles.custom}`}>
            <img src={dhol} className="card-img-top" alt="Card Image 1" />
            <div className="card-body">
              <h3 className="card-title">DHOL</h3>
              <p className="card-text">
                Experience the pulse of Punjabi music with our dynamic dhol service.
              </p>
              <Link
                to="https://nachdijawani-melanie.com/dholform" // Replace FORM_ID_1 with the actual Form ID
                className="btn btn-primary"
                id={styles.cardbutton}
              >
                Book
              </Link>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="col-md-6">
          <div className={`card ${styles.custom}`}>
            <img src={bhangra} className="card-img-top" alt="Card Image 2" />
            <div className="card-body">
              <h3 className="card-title">BHANGRA</h3>
              <p className="card-text">
                Infuse your event with Bhangra's exhilarating beats through our captivating performance.
              </p>
              <Link
                to="https://nachdijawani-melanie.com/bhangraform" // Replace FORM_ID_2 with the actual Form ID
                className="btn btn-primary"
                id={styles.cardbutton}
              >
                Book
              </Link>
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div className="col-md-6">
          <div className={`card ${styles.custom}`}>
            <img src={gidda} className="card-img-top" alt="Card Image 3" />
            <div className="card-body">
              <h3 className="card-title">GIDDA</h3>
              <p className="card-text">
                Infuse your event with Gidda's exhilarating beats through our captivating performance.
              </p>
              <Link
                to="http://nachdijawani-melanie.com/giddaform" // Replace FORM_ID_3 with the actual Form ID
                className="btn btn-primary"
                id={styles.cardbutton}
              >
                Book
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwoCards;
