import React from 'react';
import { Card } from 'react-bootstrap';
import styles from './TeamCard.module.css';
import { Link } from 'react-router-dom';

const TeamCard = ({ teamData }) => {
    const getRandomLightColor = () => {
        const letters = '89ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 8)];
        }
        return color;
    };


    const randomBackgroundColor = getRandomLightColor();

    return (
        <Card className={styles.teamCard} style={{ backgroundColor: randomBackgroundColor }}>
            <Link to={`/team/${teamData.Team_Name_Batch}`}>
                {teamData.Image && (
                    <div className={styles.imageContainer}>
                        <Card.Img
                            className={styles.teamImage}
                            variant="top"
                            src={teamData.Image}
                        />
                    </div>
                )}
                <Card.Body>
                    <Card.Title><b>{teamData.Team_Name_Batch}</b></Card.Title>
                    <Card.Text>
                        <strong>Category: {teamData.Category}</strong>
                        <br />
                        <strong>Days: {teamData.Days.join(", ")}</strong>
                        <br />
                        <strong>
                            Time: {teamData.Start_Time} - {teamData.End_Time}
                        </strong>
                    </Card.Text>
                </Card.Body>
            </Link>
        </Card>
    );
};

export default TeamCard;
