import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Button from 'react-bootstrap/Button'; // Import the Button component
import styles from './Explore.module.css'; // Import local CSS module
import { Link } from 'react-router-dom';
import ExploreMainImage from '../assets/img/mainExplore.jpg';
import VideoCard from '../components/VideoCard';
import TwoCards from '../components/TwoCards';
import gradient from '../assets/img/gradient.jpg';
import MapComponent from '../components/MapComponent';
import { Modal, Form } from 'react-bootstrap';
import StudentForm from '../components/StudentForm';
import VolunteerForm from '../components/VolunteerForm';
import main from '../assets/img/logo2.png';

function Explore() {
    useEffect(() => {
        document.title = 'Explore';

        // Optionally, you can reset the title when the component unmounts
        return () => {
            document.title = 'Original Title';
        };
    }, []);

    const [showStudentForm, setShowStudentForm] = useState(false);
    const [showVolunteerForm, setShowVolunteerForm] = useState(false);

    // Function to handle opening the student form modal
    const handleStudentClick = () => {
        setShowStudentForm(true);
    };

    // Function to handle opening the volunteer form modal
    const handleVolunteerClick = () => {
        setShowVolunteerForm(true);
    };

    return (
        <div className="container-fluid position-relative">
            <div className="row">
                 <h6 className={styles.overlayheading}>Melanie</h6> 
                <img src={main} alt="Nachdi Jawani" id={styles.mainImage} />
                <div className={`col-6 col-md-2 position-absolute top-0 end-0 p-3 ${styles.zButton}`}>
                    <Link to="/">
                        <Button className={`w-100 ${styles.topButton}`}>Home</Button>
                    </Link>
                </div>
                <div className={styles.imgcontainer}>
                    <img src={ExploreMainImage} alt="Gradient" /> 
                </div>
                <div className={styles.centered}>
                    <h4 className={styles.clipsheading}>CLIPS</h4>
                </div>
                <div className={styles.centered}>
                    <VideoCard />
                </div>
                <div className={styles.centered}>
                    <h4 className={styles.clipsheading}>SERVICES</h4>
                </div>
                <div className={styles.centered}>
                    <TwoCards />
                </div>

                <div className={styles.cardContainer}>
                    <div className={styles.card}>
                        <h4 className={styles.cardHeading}>Register as:</h4>
                        <a href='/externalstudent'>
                            <Button className={styles.cardButton}>
                                Student
                            </Button>
                        </a>
                        <Button className={styles.cardButton} onClick={handleVolunteerClick}>
                            Volunteer
                        </Button>
                    </div>
                </div>



                <div className={styles.Infocontainer}>
                    <img src={gradient} alt="Example" />
                    <div className={styles.overlayinfo}>
                        <h4 className={styles.headinginfo}>GET IN TOUCH</h4>
                        <h6 className={styles.phone}>Call Us at <a href="tel:+1234567890">647-960-2900</a><br /><br />OR
                            <br />   <br />
                            Visit us at <a href='https://www.google.com/maps/place/Nachdi+Jawani:+Melanie/@43.7094005,-79.6863213,17z/data=!3m1!4b1!4m6!3m5!1s0x882b3c261db0d35b:0xd41b8d8bf590594d!8m2!3d43.7094005!4d-79.6837464!16s%2Fg%2F11g7_hw_p4?entry=ttu' target='_blank'>4 Melanie Dr Unit 17, Brampton, ON L6T 4K8</a>
                        </h6>
                        <div className={styles.MapContainer}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2884.055269033198!2d-79.68632132360916!3d43.709400471099535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3c261db0d35b%3A0xd41b8d8bf590594d!2sNachdi%20Jawani%3A%20Melanie!5e0!3m2!1sen!2sca!4v1705441537268!5m2!1sen!2sca" width="1000" height="500"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div className={styles.spacer}></div>
                <p id="designed">Designed and Developed By <a href='https://codedelic.com'>Codedelic</a></p>

                <Modal show={showStudentForm} onHide={() => setShowStudentForm(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Student Registration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <StudentForm />
                    </Modal.Body>
                </Modal>

                {/* Modal for Volunteer Form */}
                <Modal show={showVolunteerForm} onHide={() => setShowVolunteerForm(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Volunteer Registration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <VolunteerForm />
                    </Modal.Body>
                </Modal>

            </div>
        </div>
    );
}

export default Explore;