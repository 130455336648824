import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import './CancellationRequestForm.css'; // Import custom CSS for styling
import { useNavigate } from 'react-router-dom';

const CancellationRequestForm = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [classes, setClasses] = useState([]);
    const [teams, setTeams] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    useEffect(() => {
            document.title = 'Cancellation Form';
        const fetchData = async () => {
            try {
                const [eventsResponse, teamsResponse] = await Promise.all([
                    axios.get('https://nachdijawani-melanie.com/api/Classes/?username=Sukhpal&lastname=Bal'),
                    axios.get('https://nachdijawani-melanie.com/api/Teams/?username=Sukhpal&lastname=Bal')
                ]);
                const weekendClasses = eventsResponse.data.weekend_classes;
                const weekdayClasses = eventsResponse.data.weekday_classes;
                setClasses([...weekendClasses, ...weekdayClasses]);
                const weekendTeams = teamsResponse.data.weekend_teams;
                const weekdayTeams = teamsResponse.data.weekday_teams;
                setTeams([...weekendTeams, ...weekdayTeams]);
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        // console.log("teams after")
        // console.log(teams)
        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        document.getElementById('submitbtn').innerHTML = "Submitting...";
        document.getElementById('submitbtn').disabled = true
        if (selectedOption) {
            try {
                // Get current time in 'America/Toronto' timezone
                const currentTime = new Date().toLocaleString('en-US', {
                    timeZone: 'America/Toronto'
                });
    
                // Make POST request with cancellation request data and timestamp
                const response = await axios.post('https://nachdijawani-melanie.com/api/cancellation-request/', {
                    firstName,
                    lastName,
                    email,
                    selectedOption,
                    termsAgreed: true,
                    timestamp: currentTime // Include timestamp in the request data
                });
    
                navigate('/cancellation-success');
                
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    // Student not found in the database
                    alert('Student not found. Please check your details.');
                } else {
                    console.error('Error submitting cancellation request:', error);
                    alert('An error occurred while submitting the cancellation request.');
                }
            }
        } else {
            alert('Please select a class or team');
        }
    };
    

    return (
        <div className="container-fluid cancellation-form-container">
            <div className="row">
                {/* Left half with image */}
                <div className="col-lg-6 bg-image d-flex align-items-center justify-content-center">
                    <p className='melanie-text'>MELANIE</p>
                </div>

                {/* Right half with form */}
                <div className="col-lg-6 d-flex align-items-center justify-content-center">
                    <div className="card card-form">
                        <div className="card-body">
                            <h1 className="card-title text-center mb-4"><b>CANCELLATION REQUEST</b></h1>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="firstName" className="mb-3">
                                    <Form.Label className="text-light">Student First Name</Form.Label>
                                    <Form.Control type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Enter your first name" className="dark-form-input" />
                                </Form.Group>

                                <Form.Group controlId="lastName" className="mb-3">
                                    <Form.Label className="text-light">Student Last Name</Form.Label>
                                    <Form.Control type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Enter your last name" className="dark-form-input" />
                                </Form.Group>

                                <Form.Group controlId="email" className="mb-3">
                                    <Form.Label className="text-light">Student Email address</Form.Label>
                                    <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" className="dark-form-input" />
                                </Form.Group>

                                <Form.Group controlId="selectedOption" className="mb-3">
                                    <Form.Label className="text-light">Select a Class or Team</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={selectedOption}
                                        onChange={(e) => setSelectedOption(e.target.value)}
                                        className="dark-form-input"
                                    >
                                        <option value="">Select a Class or Team</option>
                                        {classes.map((cls) => (
                                            <option key={cls.ID} value={cls.Class_Name_Batch}>{cls.Class_Name_Batch}</option>
                                        ))}
                                        {teams.map((team) => (
                                            <option key={team.ID} value={team.Team_Name_Batch}>{team.Team_Name_Batch}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="termsAgreed" className="mb-3">
                                    <div className="text-light mt-2">
                                        <p className="termslight">
                                            I acknowledge and fully understand that my membership will remain in effect for a 4-week period upon submission of this form.
                                        </p>
                                        <p className="termslight">
                                            I acknowledge and fully understand that I am responsible for all membership fees to be paid during this 4-week period until my membership is void.
                                        </p>
                                        <p className="termslight">
                                            I acknowledge and fully understand that I will need to re-register and pay all fees associated with re-registering should I decide to join Nachdi Jawani again in the future.
                                        </p>
                                    </div>
                                    <Form.Check type="checkbox" label="I agree to the terms and conditions" checked={termsAgreed} onChange={(e) => setTermsAgreed(e.target.checked)} className="dark-form-checkbox" />
                                </Form.Group>

                                <Button variant="primary" id="submitbtn" type="submit" className="btn-primary-dark w-100">Submit</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CancellationRequestForm;
