import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function DeclinedPaymentPage() {
  const location = useLocation();

  useEffect(() => {
    // Accessing data from the location state (assuming you're passing data via state)
    const postData = location.state;

    // If you're passing data as query parameters in the URL, you can use location.search
    // const searchParams = new URLSearchParams(location.search);
    // const postData = Object.fromEntries(searchParams.entries());

    // Log the received data to the console
    console.log('Received data:', postData);

    // Make a POST request to your Django API with the received data
    axios.post('http://your-django-api-url', postData)
      .then((response) => {
        console.log('POST request successful:', response.data);
      })
      .catch((error) => {
        console.error('Error making POST request:', error);
      });
  }, [location.state]); // Add any other dependencies if needed

  return (
    <div>
      <h1>Payment Declined</h1>
      <p>Sorry, your payment was declined. Please try again.</p>
    </div>
  );
}

export default DeclinedPaymentPage;
