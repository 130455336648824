    import React, { useState } from 'react';
    import { Form, Button, Container, Row, Col } from 'react-bootstrap';
    import axios from 'axios';

    const DholForm = () => {
    const [formData, setFormData] = useState({
        email: '',
        fullName: '',
        phoneNumber: '',
        altContactFullName: '',
        altContactPhoneNumber: '',
        eventVenue: '',
        eventAddress: '',
        occasion: '',
        dateOfEvent: '',
        dholTime: '',
        dholPackage: 'Dhol-with-Boliyan-300',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
      
        // Handle the select element separately
        if (name === 'dholPackage') {
          console.log('Selected Dhol Package:', value);
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        } else {
          console.log(`Updating ${name} with value:`, value);
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
        const response = await axios.post(
            'https://nachdijawani-melanie.com/api/receive_form_data/',
            formData
        );

        // Extract necessary data from the form data
        const { email, dholPackage } = formData;
        const encodedEmail = encodeURIComponent(email);
        const encodedDholPackage = encodeURIComponent(dholPackage);

        // Redirect to the payment page with the required parameters
        window.location.href = `https://nachdijawani-melanie.com/bookingpay/${encodedEmail}/${encodedDholPackage}`;
        } catch (error) {
        console.error('Error submitting form:', error);
        }
    };


    return (
        <Container className="mt-4">
        <Row>
            <Col md={{ span: 6, offset: 3 }}>
            <Form onSubmit={handleSubmit}>

                <Form.Group controlId="email">
                <Form.Label>Email:</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                </Form.Group>

                <Form.Group controlId="fullName">
                <Form.Label>Full Name:</Form.Label>
                <Form.Control
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    required
                />
                </Form.Group>

                <Form.Group controlId="phoneNumber">
                <Form.Label>Phone Number:</Form.Label>
                <Form.Control
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                />
                </Form.Group>

                <Form.Group controlId="altContactFullName">
                <Form.Label>Alternative Contact Full Name:</Form.Label>
                <Form.Control
                    type="text"
                    name="altContactFullName"
                    value={formData.altContactFullName}
                    onChange={handleChange}
                />
                </Form.Group>

                <Form.Group controlId="altContactPhoneNumber">
                <Form.Label>Alternative Contact Phone Number:</Form.Label>
                <Form.Control
                    type="tel"
                    name="altContactPhoneNumber"
                    value={formData.altContactPhoneNumber}
                    onChange={handleChange}
                />
                </Form.Group>

                <Form.Group controlId="eventVenue">
                <Form.Label>Event Venue:</Form.Label>
                <Form.Control
                    type="text"
                    name="eventVenue"
                    value={formData.eventVenue}
                    onChange={handleChange}
                    required
                />
                </Form.Group>

                <Form.Group controlId="eventAddress">
                <Form.Label>Event Address:</Form.Label>
                <Form.Control
                    as="textarea"
                    name="eventAddress"
                    value={formData.eventAddress}
                    onChange={handleChange}
                    rows="4"
                    required
                />
                </Form.Group>

                <Form.Group controlId="occasion">
                <Form.Label>Occasion:</Form.Label>
                <Form.Control
                    type="text"
                    name="occasion"
                    value={formData.occasion}
                    onChange={handleChange}
                    required
                />
                </Form.Group>

                <Form.Group controlId="dateOfEvent">
                <Form.Label>Date of Event:</Form.Label>
                <Form.Control
                    type="date"
                    name="dateOfEvent"
                    value={formData.dateOfEvent}
                    onChange={handleChange}
                    required
                />
                </Form.Group>

                <Form.Group controlId="dholTime">
                <Form.Label>What time do you need the Dholi to be at your Event?</Form.Label>
                <Form.Control
                    type="time"
                    name="dholTime"
                    value={formData.dholTime}
                    onChange={handleChange}
                    required
                />
                </Form.Group>

                <Form.Group controlId="dholPackage">
                <Form.Label>Select your Dhol package:</Form.Label>
                <Form.Control
                    as="select"
                    name="dholPackage"
                    value={formData.dholPackage}
                    onChange={handleChange}
                    required
                >
                   
                    <option value="Dhol-only-300">Dhol Only($300.00)</option>
                    <option value="Dhol-with-Boliyan-400">Dhol with Boliyan ($400.00)</option>
                  
                </Form.Control>
                </Form.Group>

                <Button variant="primary" type="submit">
                Submit
                </Button>

            </Form>
            </Col>
        </Row>
        </Container>
    );
    };

    export default DholForm;
