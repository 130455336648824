// VideoPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../components/AuthContext';
import { Modal, Button, Form } from 'react-bootstrap';
import Navbarin from '../components/Navbarin';
import { useParams } from 'react-router-dom';
import styles from './videos.module.css';
import { Link,useNavigate } from 'react-router-dom';

function VideoTeamsPage() {
  const { teamName } = useParams();
  const [videos, setVideos] = useState([]);
  const navigate = useNavigate();
  const { userName, isLoggedIn, userType, userID } = useAuth() || {};
  const canViewTable = localStorage.getItem('userType') === 'Instructor' ||  localStorage.getItem('userType') === 'admin';

  // Fetch videos for the class
  useEffect(() => {
    axios.get(`https://nachdijawani-melanie.com/api/videos/${teamName}/`)
      .then((response) => {
        setVideos(response.data);
      })
      .catch((error) => {
        console.error('Error fetching videos', error);
      });
  }, [teamName]);

  const canUpload = localStorage.getItem('userType') === 'admin' ||  localStorage.getItem('userType') === 'Instructor';

  const [showModal, setShowModal] = useState(false);
  const [videoTitle, setVideoTitle] = useState('');
  const [videoFile, setVideoFile] = useState(null);

  if (!isLoggedIn) {
    navigate('/404');
  }

  const handleClose = () => {
    setShowModal(false);
    setVideoTitle('');
    setVideoFile(null);
  };

  const handleShow = () => setShowModal(true);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setVideoFile(file);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('videoTitle', videoTitle);
    formData.append('videoFile', videoFile);

    // Include class name in the upload request
    axios.post(`https://nachdijawani-melanie.com/api/upload-video/${teamName}/`, formData)
      .then((response) => {
        console.log('Video uploaded successfully', response.data);
        setVideos([...videos, response.data]); // Update the videos state with the new video
        handleClose();
      })
      .catch((error) => {
        console.error('Error uploading video', error);
      });
  };

  return (
    <div className={`container-fluid position-relative ${styles.main}`}>
      <div className="row">
        <Navbarin />
        <div className={styles.container}>
        <div className={styles.navCard}>
          <ul>
          <li><h4><a href={`/team/${teamName}`}>Home</a></h4></li>
          {canViewTable && (
                <li>
                  <h4>
                    <a href={`/team-attendance/${teamName}`}>Attendance</a>
                  </h4>
                </li>
              )}
            <li><h4><a href={`/team-announcements/${teamName}`}>Announcements</a></h4></li>
            <li><h4><a href={`/team-video/${teamName}`}>Videos</a></h4></li>
          </ul>
        </div>
        <div className={styles.videopage}>
          <div className={styles.header}>
            <h1>Videos for {teamName}</h1>
            {canUpload && (
              <Button variant="primary" onClick={handleShow} className={styles.uploadButton}>
                Upload Video
              </Button>
            )}
          </div>
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formVideoTitle">
                  <Form.Label>Video Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter video title"
                    value={videoTitle}
                    onChange={(e) => setVideoTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formVideoFile">
                  <Form.Label>Choose Video File</Form.Label>
                  <Form.Control type="file" accept="video/*" onChange={handleFileChange} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Upload
              </Button>
            </Modal.Footer>
          </Modal>
          <ul className={styles.videoList}>
            {videos.map((video) => (
              <li key={video.id} className={styles.videoItem}>
                <h3>{video.title}</h3>
                {/* Use the correct URL provided by the backend for video playback */}
                <video width="640" height="360" controls>
                  <source src={`https://nachdijawani-melanie.com/api/videos/play/${video.id}/`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </li>
            ))}
          </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoTeamsPage;
