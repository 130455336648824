import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbarin from '../components/Navbarin';
import styles from './CommunicationsPage.module.css';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function CommunicationsPage() {
  const [communications, setCommunications] = useState([]);
  const [selectedCommunication, setSelectedCommunication] = useState(null);
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [emailContent, setEmailContent] = useState('');
  const { userName, isLoggedIn, lastName } = useAuth() || {};
  const navigate = useNavigate();
  const storedUserType  = localStorage.getItem('userType');

  const formatDateTime = (dateTimeString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateTimeString).toLocaleDateString('en-US', options);
  };


  useEffect(() => {
    document.title = 'Communications';

    return () => {
      document.title = 'Original Title';
    };
  }, []);

  useEffect(() => {
    axios
      .get('https:///nachdijawani-melanie.com/api/communications/')
      .then((response) => {
        const communications = response.data;
        setCommunications(communications);
      })
      .catch((error) => {
        console.error('Error fetching communications:', error);
      });
  }, []);

  if (!isLoggedIn) {
    navigate('/404');
    return null;
  };
  
  const handleOpenEmailModal = (communication) => {
    setSelectedCommunication(communication);
    setEmailModalShow(true);
  };

  const handleCloseEmailModal = () => {
    setEmailContent('');
    setEmailModalShow(false);
    setSelectedCommunication(null);
  };

  const handleSendEmail = () => {
    const { student } = selectedCommunication;

    // Call the new API endpoint to send an email
    axios
      .post('https:///nachdijawani-melanie.com/api/communications/', {
        to_email: student.email,
        content: emailContent,
      })
      .then((response) => {
        console.log('Email sent successfully:', response.data.message);
        window.alert('Email sent successfully')
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        // Handle error appropriately
      });

    // Close the modal
    handleCloseEmailModal();
  };

  return (
    <div className="container-fluid position-relative">
      <div className="row">
        <Navbarin />
        <div className={styles.tablecontainer}>
          <h1 className={styles.heading}>Student Communications</h1>
          <table className={`table table-bordered table-light table-responsive ${styles.table}`}>
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Message</th>
                <th>Type</th>
                <th>Date/Time</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {communications.map((communication) => (
                <tr key={communication.ID}>
                  <td>{communication.student.first_name} {communication.student.last_name}</td>
                  <td>{communication.communication.Message}</td>
                  <td>{communication.communication.Type}</td>
                  <td>{formatDateTime(communication.communication.DateTime)}</td>
                  <td>{communication.student.email}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => handleOpenEmailModal(communication)}
                    >
                      Send Email
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Email Modal */}
      <Modal show={emailModalShow} onHide={handleCloseEmailModal}>
        <Modal.Header closeButton>
          <Modal.Title>Send Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="emailContent">
            <Form.Label>Email Content</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={emailContent}
              onChange={(e) => setEmailContent(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEmailModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSendEmail}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CommunicationsPage;
