import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import styles from './successcancel.module.css';

const CancellationSuccessPage = () => {
  useEffect(() => {
    document.title = 'Success!';
    })
  return (

    <div className="container-fluid bg-dark text-light p-5" id={styles.cancelsuccesscont}>
      <h1 className={styles.successcancellogo}>Nachdi Jawani <span className={styles.successcancelspann}>Melanie</span></h1>
      <h1 className="mb-4">Cancellation Request Successful</h1>
      <p className={styles.successcancelp}>Your cancellation request has been successfully submitted.</p>
      <p className={styles.successcancelp}>Thank you for using our services.</p>
      <a href="https://nachdijawani-melanie.com" className="text-light text-decoration-none">
        <Button variant="primary">Go to Home</Button>
      </a>
    </div>
  );
};

export default CancellationSuccessPage;
