import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Navbarin from '../components/Navbarin';
import axios from 'axios';
import styles from './announcements.module.css';
import { useAuth } from '../components/AuthContext';
import { Link,useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Modal, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

function formatDateTime(dateTimeString) {
  const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  return new Date(dateTimeString).toLocaleString('en-US', options);
}

function TeamAnnouncements() {
  const [showModal, setShowModal] = useState(false);
  const [announcementTitle, setAnnouncementTitle] = useState('');
  const [announcementContent, setAnnouncementContent] = useState('');
  const [selectedClass, setSelectedClass] = useState(null);
  const [classData, setClassData] = useState(null);
  const [announcements, setAnnouncements] = useState([]);

  const { teamName } = useParams();
  const { userName, isLoggedIn, userType, userID } = useAuth() || {};
  const navigate = useNavigate();
  const canViewTable = localStorage.getItem('userType') === 'Instructor' ||  localStorage.getItem('userType') === 'admin';

  useEffect(() => {
    document.title = 'Announcements';

    axios.get(`https://nachdijawani-melanie.com/api/teams/${teamName}/`)
      .then(response => {
        setClassData(response.data);
      })
      .catch(error => {
        console.error('Error fetching class data', error);
      });

    axios.get(`https://nachdijawani-melanie.com/api/announcementbyteam/${teamName}/`)
      .then(response => {
        const filteredAnnouncements = response.data.announcements.filter((announcement) => {
          if (localStorage.getItem("userType") === 'admin' || localStorage.getItem("userType") === 'Instructor') {
            return true; // Admin and Instructor can see all announcements
          }

          if (localStorage.getItem("userType") === 'student') {
            // Convert the announcement's Student ID to a string for type consistency

            const announcementStudentID = String(announcement.Student_ID);
           
            // Check if the announcement is for the logged-in student
            return announcementStudentID === userID || announcement.Student_ID === null;

          }

          return false;
        },[teamName, userID, userType]);

        const sortedAnnouncements = filteredAnnouncements.sort(
          (a, b) => new Date(b.Announcement_Date_Time) - new Date(a.Announcement_Date_Time)
        );

        setAnnouncements(sortedAnnouncements);
      })
      .catch(error => {
        console.error('Error fetching announcements', error);
      });
  }, [teamName, userID, userType]);

  if (!isLoggedIn) {
    navigate('/404');
  }

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedClass(null);
    setAnnouncementContent('');
    setAnnouncementTitle('');
  };

  const handleAnnouncementSubmit = () => {
    if (classData) {
      const payload = {
        Title: announcementTitle,
        Content: announcementContent,
        team_name: teamName,
        team_id: classData.ID,
        instructor_name: userName,
        last_name: localStorage.getItem('lastName'),
        type: 'announcement',
      };

      axios.post('https://nachdijawani-melanie.com/api/announcement/', payload)
        .then((response) => {
          if (response.data.message === 'sent successfully') {
            axios.get(`https://nachdijawani-melanie.com/api/announcementbyteam/${teamName}/`)
              .then(response => {
                const filteredAnnouncements = response.data.announcements.filter((announcement) => {
                  if (localStorage.getItem("userType") === 'admin' || localStorage.getItem("userType") === 'Instructor') {
                    return true; // Admin and Instructor can see all announcements
                  }

                  if (userType === 'student') {
                    // Convert the announcement's Student ID to a string for type consistency
                    const announcementStudentID = String(announcement.Student_ID);
                    // Check if the announcement is for the logged-in student
                    return announcementStudentID === userID;
                  }

                  return false;
                });

                const sortedAnnouncements = filteredAnnouncements.sort(
                  (a, b) => new Date(b.Announcement_Date_Time) - new Date(a.Announcement_Date_Time)
                );

                setAnnouncements(sortedAnnouncements);
                handleCloseModal();
              })
              .catch(error => {
                console.error('Error fetching announcements', error);
              });
          } else {
            console.error('Error sending announcement:', response.data);
          }
        })
        .catch((error) => {
          console.error('Error sending announcement:', error);
        });
    } else {
      console.error('classData is null');
    }
  };

  return (
    <div className="container-fluid position-relative" id={styles.main}>
      <Navbarin />
      <div className={styles.container}>
        <div className={styles.navCard}>
          <ul>
          <li><h4><a href={`/team/${teamName}`}>Home</a></h4></li>
          {canViewTable && (
                <li>
                  <h4>
                    <a href={`/team-attendance/${teamName}`}>Attendance</a>
                  </h4>
                </li>
              )}
            <li><h4><a href={`/team-announcements/${teamName}`}>Announcements</a></h4></li>
            <li><h4><a href={`/team-video/${teamName}`}>Videos</a></h4></li>
          </ul>
        </div>
        <div className='container' id={styles.maincont}>
          <h2>Announcements</h2>
          {(localStorage.getItem("userType") === 'admin' || localStorage.getItem("userType") === 'Instructor') && (
            <button className={styles.announcebtn} onClick={handleShowModal}>
              Make Announcement
            </button>
          )}
          {announcements.length > 0 && (
            <div className={styles.announcements}>
              {announcements.map((announcement) => (
                <div key={announcement.ID} className={styles.announcementPost}>
                  <div className={styles.announcementPostContent}>
                    <h4>{announcement.Title}</h4>
                    <p>{announcement.Content}</p>
                  </div>
                  <div className={styles.announcementPostMeta}>
                    <p>{formatDateTime(announcement.Announcement_Date_Time)}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {classData && (
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Make Announcement for : {classData.Class_Name_Batch}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="announcementTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={announcementTitle}
                  onChange={(e) => setAnnouncementTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="announcementContent">
                <Form.Label>Content</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={announcementContent}
                  onChange={(e) => setAnnouncementContent(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="primary" onClick={handleAnnouncementSubmit}>
                Submit Announcement
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default TeamAnnouncements;
