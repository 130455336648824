// Import necessary libraries and components
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Navbarin from '../components/Navbarin';
import axios from 'axios';
import styles from './classesinside.module.css';
import { useAuth } from '../components/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';

function getBorderStyle(paymentStatus) {
  let borderColor;
  let textColor;

  if (paymentStatus === "Success") {
    borderColor = "#2e8540";
    textColor = "#2e8540"; // Match text color with border color
  } else if (paymentStatus === "Pending") {
    borderColor = "#f39c11";
    textColor = "#f39c11"; // Match text color with border color
  } else if (paymentStatus === "declined" || paymentStatus === "Declined") {
    borderColor = "#e843cd";
    textColor = "#e843cd"; // Match text color with border color
  } else {
    borderColor = "transparent";
    textColor = "black"; // Default text color
  }
  return {
    border: `1px solid ${borderColor}`,
    color: textColor, // Set text color
    padding: '5px',
    borderRadius: '5px'
  };
}


function Classinside() {

  const categories = [
    'Select a category',
    'Kids-Gidda',
    'Ladies-Gidda',
    'Kids-Bhangra',
    'Adult-Bhangra',
    'Dhol',
    'Free Pagg Tying Class',
  ];


  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  // Retrieve class name from the URL params
  const { className } = useParams();

  // Retrieve authentication information
  const { isLoggedIn } = useAuth() || {};
  const navigate = useNavigate();

  // State to store class data, students, and dress data
  const [classData, setClassData] = useState(null);
  const [students, setStudents] = useState([]);
  const [dressData, setDressData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
  const [announcementTitle, setAnnouncementTitle] = useState('');
  const [announcementContent, setAnnouncementContent] = useState('');
  const [selectedStudentForAnnouncement, setSelectedStudentForAnnouncement] = useState(null);
  const [showAttendanceModal, setShowAttendanceModal] = useState(false);
  const [selectedStudentAttendance, setSelectedStudentAttendance] = useState(null);
  const [selectedStudentAttendanceDetails, setSelectedStudentAttendanceDetails] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [showCashHistoryModal, setShowCashHistoryModal] = useState(false);
  const [selectedStudentCashHistory, setSelectedStudentCashHistory] = useState(null);
  const [cashHistoryData, setCashHistoryData] = useState([]);
  const [showCashPaymentModal, setShowCashPaymentModal] = useState(false); // Define this variable
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedStudentForCashPayment, setSelectedStudentForCashPayment] = useState(null); // Define this variab
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditClassModal, setShowEditClassModal] = useState(false);
  const [newClassName, setNewClassName] = useState('');
  const [newInstructor, setNewInstructor] = useState('');
  const [backupInstructor, setBackupInstructor] = useState('');
  const [instructors, setInstructors] = useState([]);
  const [amount, setAmount] = useState('');
  const [paymentType, setPaymentType] = useState('cash');
  const [cardStatus, setCardStatus] = useState('success');



  // Function to handle opening the delete class modal
  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true);
  };

  // Function to handle closing the delete class modal
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleDeleteClass = () => {
    // Add your logic to delete the class here
    axios.delete(`https://nachdijawani-melanie.com/api/deleteclass/${className}/`)
      .then((response) => {
        console.log('Class deleted successfully:', response.data);

        // Close the modal after deletion
        handleCloseDeleteModal();
        navigate('/classes');

        // Redirect the user or perform any other action after deletion
      })
      .catch((error) => {
        console.error('Error deleting class', error);
      });
  };

  const handleOpenEditClassModal = () => {
    setShowEditClassModal(true);
    setNewClassName(classData ? classData.Class_Name_Batch : '');

    // Update the property name based on the actual structure
    setNewInstructor(classData ? classData.instructor : '');
  };


  // Function to handle closing the edit class modal
  const handleCloseEditClassModal = () => {
    setShowEditClassModal(false);
    setNewClassName('');
    setNewInstructor('');
  };

  // Function to handle submitting the edited class
  const handleEditClassSubmit = () => {
    // Add your logic to update the class information here
    const updatedClassData = {
      new_class_name: newClassName,
      instructor_name: newInstructor,
      new_category: category,
      backup_instructor: backupInstructor
      // Add other properties you want to update
    };


    axios.put(`https://nachdijawani-melanie.com/api/update_class_name/${className}/`, updatedClassData)
      .then((response) => {
        console.log('Class updated successfully:', response.data);
        fetchData();
        // Close the modal after submission
        handleCloseEditClassModal();

        // Use the useHistory hook to navigate to '/classes'
        navigate('/classes');
      })
      .catch((error) => {
        console.error('Error updating class', error);
        navigate('/classes');
      });
  };



  // Function to fetch data
  const fetchData = () => {
    axios.get(`https://nachdijawani-melanie.com/api/instructor-names/`)
      .then(response => {

        setInstructors(response.data);

      })
      .catch((error) => {
        console.error('Error fetching students', error);
      });
    axios.get(`https://nachdijawani-melanie.com/api/classes/${className}/`)
      .then(response => {
        setClassData(response.data);

        axios
          .get(`https://nachdijawani-melanie.com/api/studentsbyclass/${response.data.Class_Name_Batch}/`)
          .then((studentsResponse) => {
            const sortedStudents = studentsResponse.data.sort((a, b) => {
              // Extracting first names
              const firstNameA = a.First_Name.toLowerCase();
              const firstNameB = b.First_Name.toLowerCase();
              // Extracting last names
              const lastNameA = a.Last_Name.toLowerCase();
              const lastNameB = b.Last_Name.toLowerCase();

              // Compare first names
              if (firstNameA < firstNameB) {
                return -1;
              }
              if (firstNameA > firstNameB) {
                return 1;
              }

              // If first names are the same, compare last names
              if (lastNameA < lastNameB) {
                return -1;
              }
              if (lastNameA > lastNameB) {
                return 1;
              }

              return 0; // Names are equal
            });

            setStudents(sortedStudents);
          })
          .catch((error) => {
            console.error('Error fetching students', error);
          });

        axios
          .get(`https://nachdijawani-melanie.com/api/dress-by-class/${className}/`)
          .then((dressResponse) => {
            setDressData(dressResponse.data);
          })
          .catch((dressError) => {
            console.error('Error fetching dress data', dressError);
          });

        axios
          .get(`https://nachdijawani-melanie.com/api/attendance/${response.data.ID}/`)
          .then((attendanceResponse) => {
            setAttendanceData(attendanceResponse.data);
          })
          .catch((attendanceError) => {
            console.error('Error fetching attendance data', attendanceError);
          });
      })
      .catch(error => {
        console.error('Error fetching class data', error);
      });

    axios
      .post('https://nachdijawani-melanie.com/api/get_payment_data_by_type/', {
        name: className,
        type: 'class',  // Added type variable with value 'team'
      })
      .then((paymentDetailsResponse) => {
        setPaymentDetails(paymentDetailsResponse.data.payment_data);
      })
      .catch((paymentDetailsError) => {
        console.error('Error fetching payment details', paymentDetailsError);
      });
  };

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    document.title = 'Class';

    axios.post('https://nachdijawani-melanie.com/api/check-and-update-student-status/', {
      type: 'class',
      name: className,
    })

    fetchData();
    const interval = setInterval(() => {
      fetchData();
      const result = isWithinWindow();
      // Do something with the result, for example, update UI or take further actions based on the value

    }, 60000); //
    return () => clearInterval(interval);
  }, [className]);

  // Redirect to 404 page if not logged in
  if (!isLoggedIn) {
    navigate('/404');
  }
  const [category, setCategory] = useState(classData ? classData.Category : '');

  const handleExtraClassesButtonClick = (studentID) => {
    // Make an HTTP request to your Django backend

    var presentButtons = document.querySelectorAll(`.extrapresent-${studentID}`);
    presentButtons.forEach(button => {
      button.disabled = true;
    });

    // Disable absent buttons for the clicked student
    var absentButtons = document.querySelectorAll(`.extraabsent-${studentID}`);
    absentButtons.forEach(button => {
      button.disabled = true;
    });
    const localTimestamp = new Date().toLocaleString();
    const requestData = {
      student_ID: studentID,
      class_ID: classData.ID,
      present: 'present',
      extra_classes: true,
      timestamp: localTimestamp,
    };

    axios.post('https://nachdijawani-melanie.com/api/markAttendance/', requestData)
      .then(response => {
        axios
          .get(`https://nachdijawani-melanie.com/api/attendance/${classData.ID}/`)
          .then((attendanceResponse) => {
            setAttendanceData(attendanceResponse.data);
          })
          .catch((attendanceError) => {
            console.error('Error fetching updated attendance data', attendanceError);
          });
      })
      .catch(error => {
        // Handle error, e.g., display an error message to the user
        console.error('Error:', error);
      });
  };

  const markAttendance = (studentID, present) => {
    // Disable present buttons for the clicked student
    var presentButtons = document.querySelectorAll(`.present-${studentID}`);
    presentButtons.forEach(button => {
      button.disabled = true;
    });

    // Disable absent buttons for the clicked student
    var absentButtons = document.querySelectorAll(`.absent-${studentID}`);
    absentButtons.forEach(button => {
      button.disabled = true;
    });

    // Define the timestamp based on the user type, class start time, class end time, and whether today is the day of the class
    let timestamp;
    const userType = localStorage.getItem('userType');
    const todayIsClassDay = classData.Days.includes(new Date().toLocaleDateString('en-US', { weekday: 'long' }));

    if (todayIsClassDay && userType === 'admin') {
      // If today is the day of the class and the user is an admin
      const currentTime = new Date();
      const classStartTimeStr = classData.Start_Time;
      const classEndTimeStr = classData.End_Time;

      const classStartDateStr = currentTime.toISOString().split('T')[0];
      const classStartDateTimeStr = `${classStartDateStr}T${classStartTimeStr}`;
      const classEndDateTimeStr = `${classStartDateStr}T${classEndTimeStr}`;

      const classStartTime = new Date(classStartDateTimeStr);
      const classEndTime = new Date(classEndDateTimeStr);

      if (currentTime >= classStartTime && currentTime < classEndTime) {
        // If the current time is within the window (from class start time to class end time), set timestamp to current time
        timestamp = currentTime.toLocaleString();
      } else {
        // If the current time is outside the window, set timestamp to class start time
        timestamp = classStartTime.toLocaleString();
      }
    } else {
      // If today is not the day of the class or the user is not an admin, set timestamp to class start time
      const currentDateStr = new Date().toISOString().split('T')[0]; // Get current date in "YYYY-MM-DD" format
      const classStartDateTimeStr = `${currentDateStr}T${classData.Start_Time}`;
      const classStartTime = new Date(classStartDateTimeStr); // Convert class start time string to Date object
      timestamp = classStartTime.toLocaleString(); // Set timestamp to class start time
    }

    const data = {
      student_ID: studentID,
      class_ID: classData.ID, // Assuming classData contains class ID
      present: present,
      timestamp: timestamp,
    };

    axios.post('https://nachdijawani-melanie.com/api/markAttendance/', data)
      .then((response) => {
        // Assuming there's a function to fetch updated attendance data
        axios.get(`https://nachdijawani-melanie.com/api/attendance/${classData.ID}/`)
          .then((attendanceResponse) => {
            setAttendanceData(attendanceResponse.data); // Update attendance data
          })
          .catch((attendanceError) => {
            console.error('Error fetching updated attendance data', attendanceError);
          });
      })
      .catch((error) => {
        console.error('Error marking attendance', error);
      });
  };


  const markExtraAttendance = (studentID, present) => {
    // Proceed with marking attendance
    const localTimestamp = new Date().toLocaleString();
    const data = {
      student_ID: studentID,
      class_ID: classData.ID,
      present: present,
      extra_classes: true,
      timestamp: localTimestamp,
    };

    axios.post('https://nachdijawani-melanie.com/api/markAttendance/', data)
      .then((response) => {
        axios
          .get(`https://nachdijawani-melanie.com/api/attendance/${classData.ID}/`)
          .then((attendanceResponse) => {
            setAttendanceData(attendanceResponse.data);
          })
          .catch((attendanceError) => {
            console.error('Error fetching updated attendance data', attendanceError);
          });
      })
      .catch((error) => {
        console.error('Error marking attendance', error);
      });
  };


  // Function to handle opening the announcement modal
  const handleOpenAnnouncementModal = (student) => {
    setSelectedStudentForAnnouncement(student);
    setShowAnnouncementModal(true);
  };

  // Function to handle closing the announcement modal
  const handleCloseAnnouncementModal = () => {
    setShowAnnouncementModal(false);
    setAnnouncementTitle('');
    setAnnouncementContent('');
    setSelectedStudentForAnnouncement(null);
  };

  // Function to handle submitting the announcement
  const handleAnnouncementSubmit = () => {
    if (selectedStudentForAnnouncement) {
      const instructorNameParts = classData.instructor.split(' ');
      const firstName = instructorNameParts[0];
      const lastName = localStorage.getItem('lastName');

      const payload = {
        Title: announcementTitle,
        Content: announcementContent,
        class_name: className,
        class_id: classData.ID,
        instructor_name: firstName,
        last_name: lastName, // Adjust as needed
        type: 'announcement',
        student_id: selectedStudentForAnnouncement.ID,
      };

      axios
        .post('https://nachdijawani-melanie.com/api/announcement/', payload)
        .then((response) => {
          if (response.data.message === 'sent successfully') {
            console.log('Announcement sent successfully:', response.data);
            handleCloseAnnouncementModal();
          } else {
            console.error('Error sending announcement:', response.data);
          }
        })
        .catch((error) => {
          console.error('Error sending announcement:', error);
        });
    }
  };

  // Function to handle opening the attendance modal
  const handleOpenAttendanceModal = (student) => {
    setSelectedStudentAttendance(student);
    setShowAttendanceModal(true);

    // Fetch attendance details for the selected student
    axios
      .get(`https://nachdijawani-melanie.com/api/attendance/${classData.ID}/${student.ID}/`)
      .then((attendanceDetailsResponse) => {
        setSelectedStudentAttendanceDetails(attendanceDetailsResponse.data);
      })
      .catch((attendanceDetailsError) => {
        console.error('Error fetching attendance details', attendanceDetailsError);
      });
  };

  // Function to handle closing the attendance modal
  const handleCloseAttendanceModal = () => {
    setShowAttendanceModal(false);
    setSelectedStudentAttendance(null);
    setSelectedStudentAttendanceDetails(null);
  };

  const userType = localStorage.getItem('userType');
  const canViewTable = userType === 'Instructor' || userType === 'admin';

  const handleSetCashMethod = (student) => {
    setShowCashPaymentModal(true);
    setSelectedStudentForCashPayment(student); // Set the selected student
    setStartDate(''); // Set initial values as empty
    setEndDate('');
  };

  const handleCloseCashPaymentModal = () => {
    setShowCashPaymentModal(false);
    setStartDate(''); // Reset values when the modal is closed
    setEndDate('');
  };


  const handleViewCashHistory = (student) => {
    setSelectedStudentCashHistory(student);

    // Fetch cash history data for the selected student
    axios
      .get(`https://nachdijawani-melanie.com/api/payment-updates/${student.First_Name}/${student.Last_Name}/${student.Email}/`)
      .then((cashHistoryResponse) => {
        setCashHistoryData(cashHistoryResponse.data);
      })
      .catch((cashHistoryError) => {
        console.error('Error fetching cash history data', cashHistoryError);
      });

    setShowCashHistoryModal(true);
  };

  const handleCloseCashHistoryModal = () => {
    setShowCashHistoryModal(false);
    setSelectedStudentCashHistory(null);
    setCashHistoryData([]);
  };

  const handleCashPaymentSubmit = () => {
    if (selectedStudentForCashPayment) {
      const data = {
        first_name: selectedStudentForCashPayment.First_Name,
        last_name: selectedStudentForCashPayment.Last_Name,
        phone_number: selectedStudentForCashPayment.phoneNumber,
        email: selectedStudentForCashPayment.Email,
        new_next_recurring_date: startDate,
        new_payment_date: endDate,
        amount: amount,
        paymentType: paymentType,
        cardStatus: cardStatus
      };

      axios
        .post('https://nachdijawani-melanie.com/api/set-cash-method/', data)
        .then((response) => {
          handleCloseCashPaymentModal();

          // Call the students API again to get updated student information
          fetchData(); // Assuming fetchData() is a function that fetches data
        })
        .catch((error) => {
          console.error('Error setting cash method:', error);
        });
    } else {
      console.warn('Invalid student data.');
    }
  };

  const isWithinWindow = () => {
    const currentTime = new Date();
    const classStartTimeStr = classData.Start_Time;

    // Combine class start time with the current date
    const currentDateStr = currentTime.toISOString().split('T')[0]; // Get current date in "YYYY-MM-DD" format
    const classStartDateTimeStr = `${currentDateStr}T${classStartTimeStr}`;

    const classStartTime = new Date(classStartDateTimeStr);

    // Check if classStartTime is a valid date
    if (isNaN(classStartTime.getTime())) {
      console.error('Invalid class start time:', classData.Start_Time);
      return false; // or handle it appropriately
    }

    console.log('Current Time:', currentTime);
    console.log('Class Start Time:', classStartTime);

    const timeDiffInMinutes = Math.floor((currentTime - classStartTime) / (1000 * 60));
    console.log('Time Difference in Minutes:', timeDiffInMinutes);

    // Check if today is the day of the class
    const todayIsClassDay = classData.Days.includes(currentTime.toLocaleDateString('en-US', { weekday: 'long' }));

    // Check if it's within the window (30 minutes after class start) or after 12 am next day
    const isAdmin = localStorage.getItem('userType') === 'admin';

    if (isAdmin || (todayIsClassDay && (0 <= timeDiffInMinutes && timeDiffInMinutes <= 30))) {
      console.log('User is an admin or instructor. Allow access.');
      return true;
    }

    console.log('Class has already started or today is not the day of the class.');
    return false;
  };

  const handleWelcome = (firstName, lastName) => {
    // Replace 'your-api-endpoint' with the actual endpoint of your Django API
    const apiUrl = 'https://nachdijawani-melanie.com/api/welcome/';

    // Prepare the data to be sent in the POST request
    const postData = {
      firstName: firstName,
      lastName: lastName,
    };

    // Send the POST request using Axios
    axios.post(apiUrl, postData)
      .then(response => {
        // Handle the successful response, if needed
        window.alert('Email sent')
        console.log(response.data);
      })
      .catch(error => {
        // Handle the error, if needed
        console.error(error);
      });
  };

  const handleDeleteRecord = (record, selectedStudentCashHistory) => {
    // Extracting required data from the record object
    const { First_Name, Last_Name } = selectedStudentCashHistory
    const { new_next_recurring_date, new_payment_date, amount } = record;

    // Prepare data to be sent in the request body
    const requestData = {
      first_name: First_Name,
      last_name: Last_Name,
      next_payment_date: new_next_recurring_date,
      new_payment_date: new_payment_date,
      amount: amount
    };

    // Assuming your Django API endpoint is '/api/delete_cash_record/'
    const apiUrl = 'https://nachdijawani-melanie.com/api/delete_payment_record/';

    // Send POST request to the Django API
    axios.post(apiUrl, requestData)
      .then(response => {
        // Handle successful response from the server
        console.log('Record deleted successfully:', response.data);
        setShowCashHistoryModal(false);
        setSelectedStudentCashHistory(null);
        setCashHistoryData([]);
        axios
          .post('https://nachdijawani-melanie.com/api/get_payment_data_by_type/', {
            name: className,
            type: 'class',  // Added type variable with value 'team'
          })
          .then((paymentDetailsResponse) => {
            setPaymentDetails(paymentDetailsResponse.data.payment_data);
          })
          .catch((paymentDetailsError) => {
            console.error('Error fetching payment details', paymentDetailsError);
          });
        // Assuming you want to update the UI or perform any other action after successful deletion
        // For example, you may want to refresh the cash history data
        // You can call a function here to update the UI accordingly
      })
      .catch(error => {
        // Handle error case
        console.error('Error deleting record:', error);
        // You may want to show an error message to the user or handle the error in any other way
      });
  };


  return (
    <div className="container-fluid position-relative" id={styles.main}>
      <div className="row">
        {/* Navbar component */}
        <Navbarin />

        {/* Class information and navigation links */}

        <h1 className={styles.heading}>{className}</h1>
        {userType === 'admin' && (
          <div className={styles.adminButtons}>
            <Button variant="danger" onClick={handleOpenDeleteModal}>
              Delete Class
            </Button>
            <Button variant="primary" onClick={handleOpenEditClassModal}>
              Edit Class
            </Button>
          </div>
        )}

        <div className={styles.container}>
          <div className={styles.navCard}>
            <ul>
              <li><h4><Link to={`/class/${className}`}>Home</Link></h4></li>
              {canViewTable && (
                <li><h4><Link to={`/attendance/${className}`}>Attendance</Link></h4></li>
              )}
              <li><h4><Link to={`/announcements/${className}`}>Announcements</Link></h4></li>
              <li><h4><Link to={`/video/${className}`}>Videos</Link></h4></li>
              {/* Add more navigation links as needed */}
            </ul>
          </div>

          {/* Class details */}
          <div className={styles.gridContainer}>
            <div className={styles.card}>
              <h4>{classData && `${classData.Days.join(', ')} ${classData.Start_Time} - ${classData.End_Time}`}</h4>
            </div>

            <div className={styles.card}>
              <h4>Coach: {classData && classData.instructor || 'Not Assigned'}</h4>
            </div>

            <div className={styles.card}>
              <h4>Count: {classData && classData.students_count}</h4>
            </div>

            <div className={styles.card}>
              <h4>Category: {classData && classData.Category}</h4>
            </div>
          </div>
        </div>


        {/* Table displaying student information with attendance field */}
        {canViewTable && (
          <table className="table table-light table-bordered" id={styles.tablestudent}>
            <thead>
              <tr>
                <th>Student ID</th>
                <th>Name</th>
                <th>Image</th>
                <th>Email</th>
                <th>Phone</th>
                {/* <th>Last Dress Payment Date</th>
                <th>Next Dress Payment Date</th>
                <th>Rate Amount</th> */}
                <th>Attendance</th>
                <th>Actions</th>
                <th>Payment Tracker</th>
              </tr>
            </thead>
            <tbody>
              {students.map(student => {
                const dressInfo = dressData.find(dress => dress.student_id === student.ID);
                const studentAttendance = attendanceData.find((attendance) => attendance.Student_ID === student.ID);
                const paymentDetail = paymentDetails.find(
                  (payment) => payment.first_name === student.First_Name && payment.last_name === student.Last_Name
                );

                let paymentTrackerStatus;

                if (paymentDetail) {
                  const nextRecurringDate = new Date(paymentDetail.next_recurring_date);
                  const currentDate = new Date();

                  if ((currentDate <= nextRecurringDate || currentDate.getTime() === nextRecurringDate.getTime()) && student.status === 'active') {
                    paymentTrackerStatus = 'Success';

                  } else if (currentDate > nextRecurringDate && student.paymentMethod === null) {
                    paymentTrackerStatus = (
                      <Button variant="primary" onClick={() => handleSetCashMethod(student)} id={styles.paybutton}>
                        Update Payment
                      </Button>
                    );
                  } else if (student.status === 'declined') {
                    paymentTrackerStatus = 'Declined';
                  } else if (student.status === 'Hold') {
                    paymentTrackerStatus = 'On Hold';
                  } else if (currentDate > nextRecurringDate && student.status === 'active') {
                    paymentTrackerStatus = 'Pending';
                  }
                } else {
                  // Display a button saying 'Set Cash Payment'
                  paymentTrackerStatus = (
                    <Button variant="primary" onClick={() => handleSetCashMethod(student)} id={styles.paybutton}>
                      Set Payment
                    </Button>
                  );
                }


                return (
                  <tr key={student.ID}>
                    <td>{student.ID}</td>
                    <td>
                      <Link to={`/student-details/${encodeURIComponent(`${student.First_Name} ${student.Last_Name}`)}/${encodeURIComponent(student.Email)}`}>
                        {`${student.First_Name} ${student.Last_Name}`}
                      </Link>
                    </td>
                    <td>
                      {student.Image_URL && (
                        <img
                          src={student.Image_URL}
                          alt={`${student.First_Name} ${student.Last_Name}`}
                          style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                        />
                      )}
                    </td>
                    <td><a href={`mailto:${student.Email}`}>{student.Email}</a></td>
                    <td><a href={`tel:${student.phoneNumber}`}>{student.phoneNumber}</a></td>
                    {/* <td>
                      {dressInfo && dressInfo.Last_Dress_Payment_Date ? (
                        new Date(dressInfo.Last_Dress_Payment_Date).toLocaleDateString()
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>
                      {dressInfo && dressInfo.Next_Dress_Payment_Date ? (
                        new Date(dressInfo.Next_Dress_Payment_Date).toLocaleDateString()
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>
                      {dressInfo && dressInfo.Rate_Amount !== undefined && dressInfo.Rate_Amount !== null ? (
                        dressInfo.Rate_Amount
                      ) : (
                        'N/A'
                      )}
                    </td> */}
                    <td>
                      {studentAttendance ? (
                        <p className={`text-${studentAttendance.Present ? 'success' : 'danger'}`}>
                          {studentAttendance.Present ? 'Present' : 'Absent'}
                        </p>

                      ) : (
                        student.status === 'Hold' && new Date(student.hold_start_date) <= new Date() ? (
                          <p>On Hold</p>
                        ) : (
                          <div id={styles.attendancebtn}>
                            <button
                              className={`btn btn-success present-${student.ID}`}
                              id={styles.attendancepresent}
                              onClick={() => markAttendance(student.ID, 'present')}
                              disabled={!isWithinWindow()}
                            >
                              Mark Present
                            </button>
                            <br /> <br />
                            <button
                              className={`btn btn-danger absent-${student.ID}`}
                              id={styles.attendanceabsent}
                              onClick={() => markAttendance(student.ID, 'absent')}
                              disabled={!isWithinWindow()}
                            >
                              Mark Absent
                            </button>
                            {isWithinWindow() && userType === 'admin' && (
                              <>
                                <button className={`btn btn-primary extrapresent-${student.ID}`} onClick={() => handleExtraClassesButtonClick(student.ID)}>
                                  Extra Classes Present
                                </button>
                                <button
                                  className={`btn btn-danger extraabsent-${student.ID}`}
                                  id={styles.attendanceabsent}
                                  onClick={() => markExtraAttendance(student.ID, 'absent')}

                                >
                                  Extra Classes Absent
                                </button>
                              </>
                            )}
                            {!isWithinWindow() && (
                              <>
                                <button className={`btn btn-primary extrapresent-${student.ID}`} onClick={() => handleExtraClassesButtonClick(student.ID)}>
                                  Extra Classes Present
                                </button>
                                <button
                                  className={`btn btn-danger extraabsent-${student.ID}`}
                                  id={styles.attendanceabsent}
                                  onClick={() => markExtraAttendance(student.ID, 'absent')}

                                >
                                  Extra Classes Absent
                                </button>
                              </>
                            )}
                          </div>
                        )
                      )}
                    </td>

                    <td className={styles.actionButtons}>
                      <Button variant="primary" onClick={() => handleWelcome(student.First_Name, student.Last_Name)} id={styles.announcebtn}>
                        Send Welcome Email
                      </Button>{' '}
                      <Button variant="primary" onClick={() => handleOpenAnnouncementModal(student)} id={styles.announcebtn}>
                        Make Announcement
                      </Button>{' '}
                      <Button variant="info" onClick={() => handleOpenAttendanceModal(student)} id={styles.attendbtn}>
                        View Attendance
                      </Button>
                    </td>
                    <td>
                      <div className={styles.tdflexed}>
                        <p style={getBorderStyle(paymentTrackerStatus)}>{paymentTrackerStatus}</p>
                        <Button variant="info" onClick={() => handleViewCashHistory(student)} id={styles.cashHistoryBtn}>
                          View Payment History
                        </Button>
                        <Button variant="primary" onClick={() => handleSetCashMethod(student)} id={styles.paybutton}>
                          Update Payment
                        </Button>
                      </div>
                    </td>


                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {/* Announcement modal */}
        <Modal show={showAnnouncementModal} onHide={handleCloseAnnouncementModal}>
          <Modal.Header closeButton>
            <Modal.Title>Make Announcement for: {selectedStudentForAnnouncement ? `${selectedStudentForAnnouncement.First_Name} ${selectedStudentForAnnouncement.Last_Name}` : ''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="announcementTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={announcementTitle}
                onChange={(e) => setAnnouncementTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="announcementContent">
              <Form.Label>Content</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={announcementContent}
                onChange={(e) => setAnnouncementContent(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAnnouncementModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAnnouncementSubmit}>
              Submit Announcement
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Attendance modal */}
        <Modal show={showAttendanceModal} onHide={handleCloseAttendanceModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              View Attendance for:{' '}
              {selectedStudentAttendance
                ? `${selectedStudentAttendance.First_Name} ${selectedStudentAttendance.Last_Name}`
                : ''}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedStudentAttendanceDetails && selectedStudentAttendanceDetails.length > 0 ? (
              <div>
                <h5>Attendance Records:</h5>
                <table className="table table-light table-bordered">
                  <thead>
                    <tr>
                      <th>Attendance Datetime</th>
                      <th>Present</th>
                      <th>Extra Class</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedStudentAttendanceDetails.map((record, index) => (
                      <tr key={index}>
                        <td>
                          {record.attendance_datetime
                            ? new Date(record.attendance_datetime).toLocaleString('en-US', options)
                            : 'Invalid Date'}
                        </td>
                        <td>{record.present ? 'Yes' : 'No'}</td>
                        <td>{record.Extra_Class ? 'Yes' : 'No'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No Attendance Data Found</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAttendanceModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <Modal show={showCashHistoryModal} onHide={handleCloseCashHistoryModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Cash History for: {selectedStudentCashHistory ? `${selectedStudentCashHistory.First_Name} ${selectedStudentCashHistory.Last_Name}` : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cashHistoryData.length > 0 ? (
            <table className="table table-light table-bordered">
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>New Next Recurring Date</th>
                  <th>New Payment Date</th>
                  <th>Actions</th>
                  <th>Classes and Teams Enrolled</th>
                </tr>
              </thead>
              <tbody>
                {cashHistoryData.map((record, index) => {

                  const matchingStudent = students.find(student => (student.First_Name === record.student_first_name && student.Last_Name === record.student_last_name));
                  return (
                    <tr key={index}>
                      <td>{record.amount}</td>
                      <td>{record.new_next_recurring_date}</td>
                      <td>{record.new_payment_date}</td>
                      <td>
                        <Button variant="danger" onClick={() => handleDeleteRecord(record, selectedStudentCashHistory)}>Delete</Button> {/* Delete button */}
                      </td>
                      <td>
                        {matchingStudent ? (
                          <div>
                            {matchingStudent.Classes_Enrolled_In && matchingStudent.Classes_Enrolled_In.length > 0 && (
                              <div>{matchingStudent.Classes_Enrolled_In.join(', ')}</div>
                            )}
                            {matchingStudent.Teams_Enrolled_In && matchingStudent.Teams_Enrolled_In.length > 0 && (
                              <div>{matchingStudent.Teams_Enrolled_In.join(', ')}</div>
                            )}
                          </div>
                        ) : (
                          <div>No classes or teams enrolled</div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>No cash history available for this student.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* Footer content */}
        </Modal.Footer>
      </Modal>

      <Modal show={showCashPaymentModal} onHide={handleCloseCashPaymentModal}>
        <Modal.Header closeButton>
          <Modal.Title>Set Payment for: {selectedStudentForCashPayment
            ? `${selectedStudentForCashPayment.First_Name} ${selectedStudentForCashPayment.Last_Name}`
            : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="startDate">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="endDate">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="amount">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCashPaymentModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCashPaymentSubmit}>
            Submit Cash Payment
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Class modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this class?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteClass}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Class modal */}
      <Modal show={showEditClassModal} onHide={handleCloseEditClassModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="newClassName">
            <Form.Label>New Class Name</Form.Label>
            <Form.Control
              type="text"
              value={newClassName}
              onChange={(e) => setNewClassName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="newInstructor">
            <Form.Label>New Instructor</Form.Label>
            <Form.Control
              as="select"
              value={newInstructor}
              onChange={(e) => setNewInstructor(e.target.value)}
            >
              {/* Populate the dropdown with instructors */}
              <option value=''>Select An Instructor</option>
              {instructors.map((instructor) => (
                <option key={instructor.ID} value={instructor.Username}>
                  {`${instructor.First_Name} ${instructor.Last_Name}`}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="backupInstructor">
            <Form.Label>Backup Instructor</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => setBackupInstructor(e.target.value)}
            >
              {/* Populate the dropdown with instructors */}
              <option value=''>Select A Backup Instructor</option>
              {instructors.map((instructor) => (
                <option key={instructor.ID} value={instructor.Username}>
                  {`${instructor.First_Name} ${instructor.Last_Name}`}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="newCategory">
            <Form.Label>New Category</Form.Label>
            <Form.Control
              as="select"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              {/* Populate the dropdown with manually defined categories */}
              {categories.map((categoryOption) => (
                <option key={categoryOption} value={categoryOption}>
                  {categoryOption}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditClassModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEditClassSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>


    </div>


  );
}

export default Classinside;