import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Navbarin from '../components/Navbarin';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './register.module.css';
import RegisterImage from '../assets/img/registergradient.jpg';
import terms from '../assets/img/terms.pdf';


function Register() {
    const [step, setStep] = useState(1);
    const [classOptions, setClassOptions] = useState([]);
    const [signedPdf, setSignedPdf] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('card');
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        email: '',
        phoneNumber: '',
        image: null,
        selectedClasses: [],
        selectedCategory: '',
        selectedTeams: [], // Added selected team field
        signedpdf: '',
        paymentMethod: 'card',
        gender: '',
        cust_id:null,
    });


    const formDataToSubmit = new FormData();
    const [parentFormData, setParentFormData] = useState({
        parentFirstName: '',
        parentLastName: '',
        parentPhoneNumber: '',
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        email: '',
        phoneNumber: '', // Added phone number error
        signedPdf: ''
    });

    const { userName, isLoggedIn, userClasses, lastName } = useAuth() || {};
    const navigate = useNavigate();
    const [healthConditions, setHealthConditions] = useState('');

    const [categoryClasses, setCategoryClasses] = useState([]); // Classes from the selected category
    const [teamOptions, setTeamOptions] = useState([]); // Added team options

    useEffect(() => {
        document.title = 'Register';
        // Fetch class categories
        axios.get('https://nachdijawani-melanie.com/api/classCategories/').then((response) => {
            setClassOptions(response.data.data);
        }).catch((error) => {
            console.error('Error fetching class options:', error);
        });
    }, []);

    if (!isLoggedIn) {
        navigate('/404');
        return null;
    }


    function containsSpecialCharacters(input) {
        // Regular expression to match any special characters including space
        var regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]/;
        return regex.test(input);
    }
    

    function validateEmail(email) {
        // Regular expression for basic email validation
        var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    const handleNext = () => {
        if (step === 1) {
            const newErrors = {};

            if (!formData.firstName.trim()) {
                newErrors.firstName = 'First name is required';
            }

            if (!formData.lastName.trim()) {
                newErrors.lastName = 'Last name is required';
            }

            if (containsSpecialCharacters(formData.firstName)) {
                newErrors.firstName = 'First name should not contain special characters';
            }
            
            if (containsSpecialCharacters(formData.lastName)) {
                newErrors.lastName = 'Last name should not contain special characters';
            }

            

            if (!formData.dob.trim() || !formData.dob.match(/^\d{4}-\d{2}-\d{2}$/)) {
                newErrors.dob = 'Date of birth format: YYYY-MM-DD';
            }

            if (!formData.email.trim()) {
                newErrors.email = 'Email is required';
            } else if (!validateEmail(formData.email.trim())) {
                newErrors.email = 'Invalid email address';
            }

            if (!formData.phoneNumber.trim() || !formData.phoneNumber.match(/^\d{10}$/)) {
                newErrors.phoneNumber = 'Phone number format: XXXXXXXXXX (10 digits)';
            }

            if (Object.keys(newErrors).length === 0) {
                setErrors({});
                setStep(step + 1);
            } else {
                setErrors(newErrors);
            }
        } else if (step === 2) {
            setStep(step + 1);
        }
        else if (step === 3) {
            if (!formData.selectedCategory) {
                // Ensure a category is selected
                return;
            }

            // Fetch classes by category
            axios.get(`https://nachdijawani-melanie.com/api/class/${formData.selectedCategory}/`).then((response) => {
                setCategoryClasses(response.data.data);
                setStep(step + 1);
            }).catch((error) => {
                console.error('Error fetching classes by category:', error);
            });

        }
        else if (step === 4) {
            axios.get('https://nachdijawani-melanie.com/api/teamOptions/').then((response) => {
                setTeamOptions(response.data.data);
                setStep(step + 1);
            }).catch((error) => {
                console.error('Error fetching team options:', error);
            });
        }
        else if (step === 5) {
            setStep(step + 1);
        }
        else if (step === 6) {
            setStep(step + 1);
        }
        else if (step === 7) {

            console.log('step 8')

        }
        

    };


    const handleBack = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        
      
        if (type === 'checkbox') {
          if (name === 'selectedTeams') {
            setFormData((prevFormData) => ({
              ...prevFormData,
              selectedTeams: checked
                ? [...prevFormData.selectedTeams, value]
                : prevFormData.selectedTeams.filter((team) => team !== value),
            }));
          } else {
            setFormData((prevFormData) => ({
              ...prevFormData,
              [name]: checked,
            }));
          }
        } else {
          // Automatically add dashes to the input value for the 'dob' field (YYYY-MM-DD format)
          let formattedValue = value;
          if (name === 'dob' && (formattedValue.length === 4 || formattedValue.length === 7)) {
            formattedValue += '-';
          }
      
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: formattedValue,
          }));
        } 
        if(name === 'custId'){

            setFormData((prevFormData) => ({
                ...prevFormData,
                cust_id: value,
              }));
           
        }
      
        // Special handling for the 'gender' field
        if (name === 'gender') {
          setFormData((prevFormData) => ({
            ...prevFormData,
            gender: value,
          }));
        }
        console.log(formData.cust_id)
      };


    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                image: file,
            });
        }
    };

    const handleClassChange = (e) => {
        const { name, checked } = e.target;
        let updatedSelectedClasses = [...formData.selectedClasses];

        if (checked && !updatedSelectedClasses.includes(name)) {
            updatedSelectedClasses = [...updatedSelectedClasses, name];
        } else {
            updatedSelectedClasses = updatedSelectedClasses.filter((classId) => classId !== name);
        }

        setFormData({
            ...formData,
            selectedClasses: updatedSelectedClasses,
        });
    };

    const handleTeamChange = (e) => {
        const { value } = e.target;

        setFormData({
            ...formData,
            selectedTeams: [value],
        });
    };

    const handleAgreeToTermsChange = () => {
        setAgreedToTerms(!agreedToTerms);
    };



    const handleSubmit = () => {
        // Prepare the data to send to the server
        document.getElementById("submitButton").disabled = true;
        formDataToSubmit.append('firstName', formData.firstName);
        formDataToSubmit.append('lastName', formData.lastName);
        formDataToSubmit.append('dob', formData.dob);
        formDataToSubmit.append('email', formData.email);
        formDataToSubmit.append('phoneNumber', formData.phoneNumber); // Added phone number field
        formDataToSubmit.append('image', formData.image);
        formDataToSubmit.append('selectedCategory', formData.selectedCategory);


        // Convert the selected classes to a JSON string and add it to the form data
        formDataToSubmit.append('selectedClasses', JSON.stringify(formData.selectedClasses));
        formDataToSubmit.append('selectedTeams', JSON.stringify(formData.selectedTeams)); // Added selected teams field

        formDataToSubmit.append('parentFirstName', parentFormData.parentFirstName);
        formDataToSubmit.append('parentLastName', parentFormData.parentLastName);
        formDataToSubmit.append('parentPhoneNumber', parentFormData.parentPhoneNumber);


        formDataToSubmit.append('healthConditions', healthConditions);


        formDataToSubmit.append('agreedToTerms', agreedToTerms);
        formDataToSubmit.append('gender',formData.gender);
        formDataToSubmit.append('cust_id',formData.cust_id);
       

        // Make a POST request to your Django view
        axios
            .post('https://nachdijawani-melanie.com/api/register/', formDataToSubmit)
            .then((response) => {
                // Handle a successful response

                handleCardPaymentRedirect();
                // navigate('/');
            })
            .catch((error) => {
                if (error.response && error.response.data.message === 'Student already exists.') {
                    alert('Student already exists in our system. Please contact the office.');
                } else {
                    console.error(error);
                }
            });
    };

    const generateRandomCustomerId = () => {
        // Replace this with your logic to generate a random customer ID
        return Math.floor(Math.random() * 1000000);
    };

    const handleCardPaymentRedirect = () => {
        if (selectedPaymentMethod === 'card') {
            const customerId = generateRandomCustomerId();
            const email = encodeURIComponent(formData.email);
            const categoryToSend = encodeURIComponent(formData.selectedCategory);
            const firstNameEncoded = encodeURIComponent(formData.firstName);
            const lastNameEncoded = encodeURIComponent(formData.lastName);
            const phoneNumberEncoded = encodeURIComponent(formData.phoneNumber);

           

            const redirectUrl = `https://nachdijawani-melanie.com/recurpay/${categoryToSend}/${customerId}/${email}/${firstNameEncoded}/${lastNameEncoded}/${phoneNumberEncoded}/`;

            if (formData.selectedCategory == 'Turban-Tying') {

                window.alert('Registration Sucess!');

            } else {
                window.location.href = redirectUrl;
            }
        }
    };
   




    return (
        <div className="container-fluid position-relative" id={styles.main}>
            <div className="row">
                <Navbarin />

                <div className={`${styles.formContainer} container-fluid container`}>
                    <div className={`${styles.formblur} container-fluid container`}>
                        <form>
                            <h2>Step {step}</h2>
                            {step === 1 && (
                                <div>
                                    <div className={styles.formField}>
                                        <label>First Name:</label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            className={errors.firstName && styles.error}
                                        />
                                        {errors.firstName && <div className={styles.errorText}>{errors.firstName}</div>}
                                    </div>
                                    <div className={styles.formField}>
                                        <label>Last Name:</label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            className={errors.lastName && styles.error}
                                        />
                                        {errors.lastName && <div className={styles.errorText}>{errors.lastName}</div>}
                                    </div>
                                    <div className={styles.formField}>
                                        <label>Customer ID:</label>
                                        <input
                                            type="number"
                                            name="custId"
                                            value={formData.cust_id}
                                            onChange={handleChange}
                                           
                                        />
                                       
                                    </div>
                                    <div className={styles.formField}>
                                        <label>Gender:</label>
                                        <select
                                            name="gender"
                                            value={formData.gender}
                                            onChange={handleChange}
                                            className={errors.gender && styles.error}
                                        >
                                            <option value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                          
                                        </select>
                                        {errors.gender && <div className={styles.errorText}>{errors.gender}</div>}
                                    </div>
                                    <div className={styles.formField}>
                                        <label>Date of Birth:</label>
                                        <input
                                            type="text"
                                            name="dob"
                                            value={formData.dob}
                                            onChange={handleChange}
                                            placeholder='2000-02-05'
                                            className={`${errors.dob ? styles.error : ''}`}
                                        />
                                         <p className={styles.dateformat}>YYYY-MM-DD</p>
                                        {errors.dob && <div className={styles.errorText}>{errors.dob}</div>}
                                    </div>
                                    <div className={styles.formField}>
                                        <label>Email:</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            className={errors.email && styles.error}
                                        />
                                        {errors.email && <div className={styles.errorText}>{errors.email}</div>}
                                    </div>
                                    <div className={styles.formField}>
                                        <label>Phone Number:</label>
                                        <input
                                            type="text"
                                            name="phoneNumber"
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                            className={errors.phoneNumber && styles.error}
                                        />
                                        {errors.phoneNumber && <div className={styles.errorText}>{errors.phoneNumber}</div>}
                                    </div>
                                    <div className={styles.formField}>
                                        <label>Image:</label>
                                        <input
                                            type="file"
                                            name="image"
                                            accept="image/*"
                                            onChange={handleImageUpload}
                                        />
                                    </div>
                                    <button type="button" className="btn btn-success" id={styles.formButton} onClick={handleNext}>
                                        Next
                                    </button>
                                </div>
                            )}
                            {step === 2 && (
                                <div>
                                    <h2>Please Skip this step if you are over 18 years old</h2>
                                    <div className={styles.formField}>
                                        <label>Parent/Guardian First Name:</label>
                                        <input
                                            type="text"
                                            name="parentFirstName"
                                            value={parentFormData.parentFirstName}
                                            onChange={(e) => setParentFormData({ ...parentFormData, parentFirstName: e.target.value })}
                                        />
                                    </div>
                                    <div className={styles.formField}>
                                        <label>Parent/Guardian Last Name:</label>
                                        <input
                                            type="text"
                                            name="parentLastName"
                                            value={parentFormData.parentLastName}
                                            onChange={(e) => setParentFormData({ ...parentFormData, parentLastName: e.target.value })}
                                        />
                                    </div>
                                    <div className={styles.formField}>
                                        <label>Parent/Guardian Phone Number:</label>
                                        <input
                                            type="text"
                                            name="parentPhoneNumber"
                                            value={parentFormData.parentPhoneNumber}
                                            onChange={(e) => setParentFormData({ ...parentFormData, parentPhoneNumber: e.target.value })}
                                        />
                                    </div>

                                    <button type="button" className="btn btn-success" id={styles.formButton} onClick={handleBack}>
                                        Back
                                    </button>
                                    <button type="button" className="btn btn-success" id={styles.formButton} onClick={handleNext}>
                                        Next
                                    </button>
                                </div>
                            )}
                            {step === 3 && (
                                <div>
                                    <h3>Select a Category:</h3>
                                    <select
                                        name="selectedCategory"
                                        value={formData.selectedCategory}
                                        onChange={handleChange}
                                        className={styles.selectField} // Add custom style class
                                    >
                                        <option value="">Select a category</option>
                                        <option value="Dhol">Dhol</option>
                                        <option value="Kids-Gidda">Kids Gidda</option>
                                        <option value="Ladies-Gidda">Ladies Gidda</option>
                                        <option value="Kids-Bhangra">Kids Bhangra</option>
                                        <option value="Adult-Bhangra">Adult Bhangra</option>
                                        <option value="Turban-Tying">Free Pagg Tying Class</option>
                                        <option value="Bhangra-5-10-boys">Bhangra (5-10 Boys)</option>
                                        <option value="Bhangra-5-10-girls">Bhangra (5-10 Girls)</option>
                                        <option value="Bhangra-5-10-coed">Bhangra (5-10 Coed)</option>

                                        <option value="Bhangra-10-14-boys"> Bhangra (10-14 Boys)</option>
                                        <option value="Bhangra-10-14-girls">Bhangra (10-14 Girls)</option>
                                        <option value="Bhangra-10-14-coed">Bhangra (10-14 Coed)</option>

                                        <option value="Bhangra-14+boys">Bhangra (14+ Boys)</option>
                                        <option value="Bhangra 14+girls">Bhangra (14+ Girls)</option>
                                        <option value="Bhangra-14+coed">Bhangra (14+ Coed)</option>
                                    </select>

                                    <button type="button" className="btn btn-success" id={styles.formButton} onClick={handleBack}>
                                        Back
                                    </button>
                                    <button type="button" className="btn btn-success" id={styles.formButton} onClick={handleNext}>
                                        Next
                                    </button>
                                </div>
                            )}
                            {step === 4 && (
                                <div>
                                    <h3>Select Classes:</h3>
                                    <div className={styles.checkboxContainer}>
                                        {categoryClasses.map((classItem) => (
                                            <div key={classItem.ID} className={styles.checkbox}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name={classItem.Class_Name_Batch}
                                                        checked={formData.selectedClasses.includes(classItem.Class_Name_Batch)}
                                                        onChange={handleClassChange}
                                                    />
                                                    {classItem.Class_Name_Batch}
                                                </label>
                                            </div>
                                        ))}
                                    </div>

                                    <button type="button" className="btn btn-success" id={styles.formButton} onClick={handleBack}>
                                        Back
                                    </button>
                                    <button type="button" className="btn btn-success" id={styles.formButton} onClick={handleNext}>
                                        Next
                                    </button>
                                </div>
                            )}
                            {step === 5 && (
                                <div>
                                    <h3>Select Team:</h3>
                                    <div className={styles.formField}>
                                        <label>Choose a Team:</label>
                                        <select
                                            name="selectedTeams"
                                            value={formData.selectedTeams[0] || ''}
                                            onChange={handleTeamChange}
                                            className={styles.selectField} // Add custom style class
                                        >
                                            <option value="">Select a team</option>
                                            {teamOptions.map((teamName) => (
                                                <option key={teamName} value={teamName}>
                                                    {teamName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <button type="button" className="btn btn-success" id={styles.formButton} onClick={handleBack}>
                                        Back
                                    </button>
                                    <button type="button" className="btn btn-success" id={styles.formButton} onClick={handleNext}>
                                        Next
                                    </button>
                                </div>
                            )}
                            {step === 6 && (
                                <div>
                                    <h3>Describe Any Health Conditions</h3>
                                    <div className={styles.formField}>

                                        <textarea
                                            name="healthConditions"
                                            value={healthConditions}
                                            onChange={(e) => setHealthConditions(e.target.value)}
                                            rows="4"
                                        />
                                    </div>

                                    <button type="button" className="btn btn-success" id={styles.formButton} onClick={handleBack}>
                                        Back
                                    </button>
                                    <button type="button" className="btn btn-success" id={styles.formButton} onClick={handleNext}>
                                        Next
                                    </button>
                                </div>
                            )}
                            {step === 7 && (
                                <div>
                                    <div className={styles.termsAndConditionsContainer}>
                                        <div className={styles.formField}>
                                        <h3 className={styles.sectionTitle}>Terms and Conditions</h3>
                                            <p className={styles.sectionText}>
                                                1. It is your responsibility to disclose any medical conditions or health issues to management upon registration
                                            </p>
                                            <p className={styles.sectionText}>
                                                2. In order to sign up for a membership, you must agree to obey all class and facility rules and take full responsibility for yourself/your child(ren)s behavior in addition to damage that may be caused by yourself/your child(ren) to the facility used by Nachdi Jawani
                                            </p>
                                            <p className={styles.sectionText}>
                                                3. By signing up for a membership, you acknowledge that participation in dance classes and activities could involve possible personal injury; despite precautions, accidents may occur
                                            </p>
                                            <p className={styles.sectionText}>
                                            <h3 className={styles.highlight}>  By signing up for a membership, you assume the following responsibilities: </h3><br />
                                                ● I/We assume all risks related to using any and all spaces by and programs, activities,
                                                rehearsals or shows run by Nachdi Jawani <br />
                                                ● I/We agree to release and hold harmless Nachdi Jawani, including its teachers,
                                                dancers, staff members, volunteers and facilities from any cause of action, claims or
                                                demands now and in the future <br />
                                                ● I/We will not hold Nachdi Jawani liable for personal injury or personal property damage
                                                that may occur before, during or after classes <br />
                                                ● I/We agree to allow Nachdi Jawani to photograph/video students and families of Nachdi
                                                Jawani and share on the Nachdi Jawani website, social media pages, brochures, or other promotional material - no identifying information is ever published without permission <br />
                                            </p>
                                            <p className={styles.sectionText}>
                                                 <h3 className={styles.highlight}>Communication:</h3> Any changes regarding schedule, class cancellations, events, etc. <br />
                                                ● By email and NJ Student Account  <br />
                                                *The academy maintains the right to cancel or reschedule classes - There are no make-up classes  <br />
                                                NOTE: It is your responsibility to check emails & student box for weekly updates  <br />
                                            </p>
                                            <p className={styles.sectionText}>
                                                 <h3 className={styles.highlight}> Annual Academy Closure </h3> <br />
                                                ● During all long weekends, Inter-Academy competitions and Malton Nagar Kirtan  <br />
                                                ● This does not affect any monthly class fees  <br />
                                            </p>
                                            <p className={styles.sectionText}>
                                                 <h3 className={styles.highlight}> Membership: </h3> <br />
                                                ● A non-refundable one time registration fee of $20 per person must be paid upon registration <br />
                                                ● This registration fee applies all new students as well as returning students ; following previous cancellation <br />
                                                ● Fees are subject to change <br />
                                                ● Payment will be automatically withdrawn from your account on 1st of every month   <br />
                                                ● Any NSF payment will result in a $30 fee <br />
                                                ● 4 week notice in person along with a signed cancellation waiver is required for   <br />
                                                membership cancellation in order to stop payment from your account <br />
                                                ● ALL payments are non-refundable and cannot be reversed <br />
                                                ● The studio shall have no liability to individuals to refund fees after they have been
                                                collected and remitted to third parties in accordance with stated purpose for the collection of the fee <br />
                                            </p>
                                            <p className={styles.sectionText}>
                                                <h3 className={styles.highlight}> Fees: </h3> <br />
                                                Cash payment options are available in 6 month increments ; you must prepay for 6 months of classes  <br />
                                                ● Cash payments must be paid within the first week of January and the first week of July  <br />
                                                ● Any late payments will result in a $30 late fee  <br />
                                                Membership Fees   <br />
                                                ● One and a half hour - Once per week: $80+hst per month   <br />
                                                ● Once hour class - Once per week: $70+hst per month   <br />
                                                Dress Service Fee:   <br />
                                                ● To be charged once your child is selected to be on a team - $250.00 for 2 years   <br />
                                                ● You must remain on the team for a continuous 2 years; you must register and make   <br />
                                                payment for this service again if membership was previously canceled   <br />
                                                ● Payment is non-refundable   <br />
                                            </p>
                                            <p className={styles.sectionText}>
                                                <h3 className={styles.highlight}> Attendance: </h3><br />
                                                ● Weekly class attendance is mandatory <br />
                                                ● Valid reason for absence is required for missing classes <br />
                                                ● Teams: All vacations must approved by management and 50% of the monthly fee will be
                                                charged <br />
                                                ● Regular Classes: $10 per month is applied for keep membership valid <br />
                                                ● Upon completion of your vacation, please call the academy to confirm your weekly
                                                schedules and get any updates to timings/days <br />
                                                ● NO refunds will be given for missed classes <br />
                                            </p>
                                            <p className={styles.sectionText}>
                                                 <h3 className={styles.highlight}> Rules and Regulations: </h3><br />
                                                ● Be on time; arrive 5 mins prior to the start of class  <br />
                                                ● Videography is not permitted  <br />
                                                ● Parents must not interfere with ongoing classes; see front desk with for concerns
                                                or questions  <br />
                                                ● All shoes to be put on shoe rack  <br />
                                                ● NO plastic water bottles - please ensure you bring a reusable water bottle  <br />
                                                ● Must show respect to instructors and peers at all time; inappropriate behavior will not
                                                tolerated and may result in termination of your membership  <br />
                                                ● Do not bring money or valuables to the studio; we are not responsible for lost or stolen
                                                items  <br />
                                                ● Teams only: Weekly video upload is mandatory  <br />
                                                ● Teams only: A $20 fee will be charged per competition for all Inter-Academy
                                                competitions   <br />
                                                ● Teams only: All team members must have a valid dress contract   <br />

                                            </p>
                                            <p className={styles.sectionText}>
                                                I acknowledge and agree to all membership rules and regulations outlined in this agreement. I have thoroughly read this agreement and fully understand the contents, meaning and impacts of this agreement. I understand that I am free to express any specific questions regarding this agreement prior to signing and I agree that my failure to do so will be interpreted as a free knowledgeable acceptance of the terms of this agreements.

                                            </p>

                                        </div>
                                    </div>

                                    <div className={styles.checkboxContainer}>
                                        <input
                                            type="checkbox"
                                            name="agreedToTerms"
                                            checked={agreedToTerms}
                                            onChange={handleAgreeToTermsChange}
                                        />
                                        <label htmlFor="agreedToTerms" className={styles.sectionText}>I agree to the terms and conditions</label>
                                    </div>

                                    <div className={styles.buttonContainer}>
                                        <button type="button" className="btn btn-success" onClick={handleBack}>
                                            Back
                                        </button>
                                        <button type="button" className="btn btn-success" onClick={handleSubmit} id='submitButton'  disabled={!agreedToTerms}>
                                        Submit
                                     </button>
                                    </div>
                                </div>
                            )}

                           
                        </form>
                        <img src={RegisterImage} alt="Registration" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Register;


