import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import styles from '../pages/Explore.module.css';

const VideoCard = () => {
  return (
    <div className="container">
      <div className="row">
        {/* Video Card 1 */}
        <div className="col-md-4">
          <div className="card" id={styles.custom}>
            <div className="card-body">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  id={styles.videocard}
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/Jhllug-5ecA"
                  title="Video 1"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        {/* Video Card 2 */}
        <div className="col-md-4">
          <div className="card" id={styles.custom}>
            <div className="card-body">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  id={styles.videocard}
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/5Y42B0YPpBM"
                  title="Video 2"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        {/* Video Card 3 */}
        <div className="col-md-4">
          <div className="card" id={styles.custom}>
            <div className="card-body">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  id={styles.videocard}
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/Hgp7GuZ5oLo"
                  title="Video 3"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
