import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbarin from '../components/Navbarin';
import { useNavigate } from 'react-router-dom';
import styles from './VolunteerAnnouncements.module.css';
import { useAuth } from '../components/AuthContext';

function VolunteerAnnouncements() {
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();
    const userID = localStorage.getItem("userID");
    const userType = localStorage.getItem("userType");
  
    useEffect(() => {
      document.title = 'Volunteer Announcements';
  
      const fetchAnnouncements = async () => {
        try {
          let response;
  
          if (userType === 'volunteer') {
            // Fetch announcements for the volunteer with the specified userID
            response = await axios.get(`https://nachdijawani-melanie.com/api/volunteerAnnouncements/`);
          } else {
            // Fetch all announcements for admins and instructors
            response = await axios.get('https://nachdijawani-melanie.com/api/volunteerAnnouncements/');
          }
  
          console.log('All Announcements:', response.data);
  
          // Use a Set to keep track of unique announcement IDs
          const uniqueAnnouncementIDs = new Set();
  
          // Filter out duplicate announcements based on unique feature id
          const uniqueAnnouncements = response.data.filter((announcement) => {
            if (userType === 'volunteer') {
              // If userType is volunteer, only keep announcements matching their volunteer_id
              const isMatchingVolunteer = Number(announcement.volunteer_id) === Number(userID);
              if (isMatchingVolunteer && !uniqueAnnouncementIDs.has(announcement.id)) {
                uniqueAnnouncementIDs.add(announcement.id);
                return true;
              }
              return false;
            }
            // For admins and instructors, keep all announcements
            return true;
          });
  
          setAnnouncements(uniqueAnnouncements);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching volunteer announcements', error);
          setLoading(false);
        }
      };
  
      fetchAnnouncements();
    }, [userID, userType, isLoggedIn]);
  
    if (!isLoggedIn) {
      navigate('/404');
      return null;
    }
  
    return (
      <div className="container-fluid position-relative">
        <Navbarin />
        <div className={`container ${styles.mainContainer}`}>
          <h2 className={styles.pageHeading}>Volunteer Announcements</h2>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className={styles.announcements}>
              {announcements.length > 0 ? (
                announcements.map((announcement) => (
                  <div key={announcement.id} className={styles.announcementPost}>
                    <div className={styles.announcementPostContent}>
                      <h4>{announcement.title}</h4>
                      <p>{announcement.content}</p>
                      <p>{announcement.instructor_name}</p>
                    </div>
                    <div className={styles.announcementPostMeta}>
                      <p>{new Date(announcement.announcement_date_time).toLocaleString()}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p key="no-announcements">No announcements available for volunteers.</p>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
  
  export default VolunteerAnnouncements;