import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PaymentBookingBhangraRedirect = () => {
  const { email, performercount } = useParams();

  // Extract base amount from performercount
  const getBaseAmount = () => {
    const match = performercount.match(/(\d+)-people-(\d+)/);
    if (match) {
      return parseFloat(match[2]);
    }
    return 0; // Default value if no match
  };

  const baseAmount = getBaseAmount();

  // Calculate tax amount (13%)
  const taxRate = 0.13;
  const taxAmount = baseAmount * taxRate;

  // Update amount with tax
  const amountWithTax = (baseAmount + taxAmount).toFixed(2);

  useEffect(() => {
    // Create a JSON string with the required parameters
    const preloadResponse = `{
      "ps_store_id": "N4D5Z71176",
      "hpp_key": "hpT2MLX9ZKRN",
      "charge_total": "${amountWithTax}" ,
      "rvar_email": "${email}",
      "email":"${email}"
    }`;

    const data = {
      paymentUrl: 'https://www3.moneris.com/HPPDP/index.php', // Use the production URL
      formContent: JSON.parse(preloadResponse),
    };

    performRedirect(data);
  }, [amountWithTax]);

  const performRedirect = (data) => {
    const form = document.createElement('form');
    form.id = 'PaymentForm';
    form.method = 'post'; // Set the form method to POST
    form.action = data.paymentUrl;

    // Add each parameter as a hidden input field
    for (const key in data.formContent) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = data.formContent[key];
      form.appendChild(input);
    }

    document.body.appendChild(form);

    try {
      form.submit();
    } catch (error) {
      // Log the error if there is any
      console.error('Error during form submission:', error);
    }
  };

  return (
    <div>
      Redirecting to Moneris hosted page for email: {email}, performercount: {performercount}...
    </div>
  );
};

export default PaymentBookingBhangraRedirect;
