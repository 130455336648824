import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import styles from './Form.module.css';

function StudentForm() {
  const [formData, setFormData] = useState({
    First_Name: '',
    Last_Name: '',
    DOB: '',
    Email: '',
    phoneNumber: '',
    type: 'student',
  });

  const [errors, setErrors] = useState({
    First_Name: '',
    Last_Name: '',
    DOB: '',
    Email: '',
    phoneNumber: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    // Basic validation logic (you may need to enhance this)
    if (value && typeof value === 'string') {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: value.trim() ? '' : `${name} is required` }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: `${name} is required` }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitting form');

    // Validate the form before submitting
    if (validateForm()) {
      console.log('Form validation successful');

      // Perform your form submission logic here (e.g., send data to the server)
      axios.post('https://nachdijawani-melanie.com/api/external/', formData)
        .then((response) => {
          // Handle successful registration
          console.log('Registration successful:', response.data);
        })
        .catch((error) => {
          // Handle registration error
          console.error('Registration error:', error);

          // Log the response status and data (if available)
          console.log('Error response status:', error.response?.status);
          console.log('Error response data:', error.response?.data);
        });
    } else {
      console.log('Form validation failed');
    }
  };

  const validateForm = () => {
    const newErrors = {};
    // Basic validation logic (you may need to enhance this)
    Object.entries(formData).forEach(([name, value]) => {
      if (value === null || value === undefined || (typeof value === 'string' && value.trim() === '')) {
        newErrors[name] = `${name} is required`;
      }
    });

    console.log('Validation errors:', newErrors); // Add this line

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="container-fluid position-relative">
      <div className="row">
        <form onSubmit={handleSubmit}>
          <div>
            <div className={`form-field ${styles.formField}`}>
              <label>First Name:</label>
              <input
                type="text"
                name="First_Name"
                value={formData.First_Name}
                onChange={handleChange}
                className={`form-control ${errors.First_Name && styles.error}`}
              />
              {errors.First_Name && <div className={styles.errorText}>{errors.First_Name}</div>}
            </div>
            <div className={`form-field ${styles.formField}`}>
              <label>Last Name:</label>
              <input
                type="text"
                name="Last_Name"
                value={formData.Last_Name}
                onChange={handleChange}
                className={`form-control ${errors.Last_Name && styles.error}`}
              />
              {errors.Last_Name && <div className={styles.errorText}>{errors.Last_Name}</div>}
            </div>
            <div className={`form-field ${styles.formField}`}>
              <label>Date of Birth:</label>
              <input
                type="text"
                name="DOB"
                value={formData.DOB}
                onChange={handleChange}
                placeholder="YYYY-MM-DD"
                className={`form-control ${errors.DOB && styles.error}`}
              />
              {errors.DOB && <div className={styles.errorText}>{errors.DOB}</div>}
            </div>
            <div className={`form-field ${styles.formField}`}>
              <label>Email:</label>
              <input
                type="email"
                name="Email"
                value={formData.Email}
                onChange={handleChange}
                className={`form-control ${errors.Email && styles.error}`}
              />
              {errors.Email && <div className={styles.errorText}>{errors.Email}</div>}
            </div>
            <div className={`form-field ${styles.formField}`}>
              <label>Phone Number:</label>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className={`form-control ${errors.phoneNumber && styles.error}`}
              />
              {errors.phoneNumber && <div className={styles.errorText}>{errors.phoneNumber}</div>}
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default StudentForm;
