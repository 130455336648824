// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Navbarin from '../components/Navbarin';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import styles from './logtable.module.css';
import { saveAs } from 'file-saver';

const formatDate = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };

    return new Date(dateString).toLocaleString('en-US', options);
};

function LogTable() {
    const [logData, setLogData] = useState([]);
    const [filteredLogData, setFilteredLogData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [exportModalOpen, setExportModalOpen] = useState(false);
    const [addManualLogModalOpen, setAddManualLogModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [selectedInstructor, setSelectedInstructor] = useState('');
    const [selectedClassTeam, setSelectedClassTeam] = useState('');
    const [logType, setLogType] = useState('');
    const [hourlyPayment, setHourlyPayment] = useState(0);
    const [exportFromDate, setExportFromDate] = useState('');
    const [exportToDate, setExportToDate] = useState('');
    const [markAsPaid, setMarkAsPaid] = useState(false);
    const [instructorList, setInstructorList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [addInstructorModalOpen, setAddInstructorModalOpen] = useState(false);
    const [newInstructor, setNewInstructor] = useState({
        firstName: '',
        lastName: '',
        username: '',
        password: '',
    });


    useEffect(() => {
        document.title = 'Log'; // Set document title

        const fetchData = async () => {
            try {
                const logResponse = await axios.get('https://nachdijawani-melanie.com/api/log/');
                setLogData(logResponse.data);

                const instructorResponse = await axios.get('https://nachdijawani-melanie.com/api/instructor-names/');
                setInstructorList(instructorResponse.data);

                const classResponse = await axios.get('https://nachdijawani-melanie.com/api/Classes/?username=Sukhpal&lastname=Bal');
                setClassList(classResponse.data.weekend_classes);

                const teamResponse = await axios.get('https://nachdijawani-melanie.com/api/Teams/?username=Sukhpal&lastname=Bal');
                const weekendTeams = teamResponse.data.weekend_teams;
                const weekdayTeams = teamResponse.data.weekday_teams;
                setTeamList([...weekendTeams, ...weekdayTeams]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Fetch log data, instructor list, class list, and team list on initial render
    }, []);

    useEffect(() => {
        const filteredResults = logData.filter((log) => {
            const isInstructorMatch =
                !selectedInstructor || log.instructor_name.toLowerCase().includes(selectedInstructor.toLowerCase());
            const isMonthMatch =
                !selectedMonth || new Date(log.created_at).getMonth() + 1 === parseInt(selectedMonth);
            const isYearMatch =
                !selectedYear || new Date(log.created_at).getFullYear() === parseInt(selectedYear);
            const isSearchMatch =
                !searchQuery ||
                log.instructor_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                log.class_name.toLowerCase().includes(searchQuery.toLowerCase());
            return isInstructorMatch && isMonthMatch && isYearMatch && isSearchMatch;
        });

        setFilteredLogData(filteredResults);
    }, [logData, searchQuery, selectedInstructor, selectedMonth, selectedYear]);

    const handleExportToPdf = () => {
        setExportModalOpen(true);
    };

    const handleSubmitExport = () => {
        const exportData = {
            fromDate: exportFromDate,
            toDate: exportToDate,
            email,
            instructor: selectedInstructor,
            markAsPaid,
            hourlyPayment,
        };

        axios
            .post('https://nachdijawani-melanie.com/api/export/', exportData, {
                responseType: 'blob', // Set responseType to 'blob' to receive binary data
            })
            .then((response) => {
                // Save the received blob as a file using file-saver
                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                saveAs(pdfBlob, 'pay_stub.pdf'); // Save the blob as 'pay_stub.pdf'
                console.log('Export successful:', response.data);
            })
            .catch((error) => {
                console.error('Error exporting data:', error);
            });

        setExportModalOpen(false);
    };

    const handleAddManualLog = () => {
        setAddManualLogModalOpen(true);
    };

    const handleSubmitManualLog = () => {
        const totalMinutes = hours * 60 + minutes;
        const manualLogData = {
            instructor_name: selectedInstructor,
            class_name: selectedClassTeam,
            type: logType,
            duration_hours: Math.floor(totalMinutes / 60),
            duration_minutes: totalMinutes % 60,
        };

        axios
        .post('https://nachdijawani-melanie.com/api/manual-log/', manualLogData)
        .then(async (response) => {
            const logResponse = await axios.get('https://nachdijawani-melanie.com/api/log/');
            setLogData(logResponse.data);
            setSelectedInstructor("");
            setHours(0);
            setMinutes(0);
            setSelectedClassTeam("");
            setLogType("");
            setAddManualLogModalOpen(false);
        })
        .catch((error) => {
            console.error('Error adding manual log:', error);
            setAddManualLogModalOpen(false);
        });
    };
    
        const handleAddInstructor = () => {
            setAddInstructorModalOpen(true);
        };
        
        const handleSubmitAddInstructor = () => {
            // Prepare data for adding a new instructor
            const newInstructorData = {
                First_Name: newInstructor.firstName,
                Last_Name: newInstructor.lastName,
                Username: newInstructor.username,
                Password: newInstructor.password,
            };
        
            // Send a POST request to the API endpoint to add the new instructor
            axios
                .post('https://nachdijawani-melanie.com/api/add-instructor/', newInstructorData)
                .then((response) => {
                    // Handle success: Update the instructor list and close the modal
                    console.log('New instructor added successfully:', response.data);
                    setInstructorList([...instructorList, response.data]); // Assuming the response contains the added instructor data
                    setNewInstructor({
                        firstName: '',
                        lastName: '',
                        username: '',
                        password: '',
                    });
                    setAddInstructorModalOpen(false);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 400 && error.response.data && error.response.data.error === 'Username already exists') {
                        // Show error message indicating that the username already exists
                        alert('Username already exists. Please choose a different username.');
                    } else {
                        // Handle other errors
                        console.error('Error:', error);
                        // Show a generic error message
                        alert('An error occurred. Please try again later.');
                    }
                });

    };

    return (
        <div className={`container-fluid position-relative ${styles.main}`}>
            <Navbarin />
            <div className={styles.LogList}>
                <h2 className={styles.heading}>Class Log</h2>

                {/* Search Bar */}
                <div className={`mb-3 ${styles.searchBar}`}>
                    <input
                        type="text"
                        placeholder="Search by instructor or class name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="form-control"
                    />
                </div>

                {/* Filter Bar (Year, Month, Export) */}
                <div className={`d-flex justify-content-between align-items-center ${styles.filterBar}`}>
                    <div className="d-flex">
                        <div className={`mr-2 ${styles.monthFilter}`}>
                            <label htmlFor="monthFilter">Filter by Month:</label>
                            <select
                                id="monthFilter"
                                className="form-select"
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(e.target.value)}
                            >
                                <option value="">All Months</option>
                                {[...Array(12).keys()].map((month) => (
                                    <option key={month + 1} value={month + 1}>
                                        {new Date(0, month).toLocaleString('en-US', { month: 'long' })}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={`mr-2 ${styles.yearFilter}`}>
                            <label htmlFor="yearFilter">Filter by Year:</label>
                            <select
                                id="yearFilter"
                                className="form-select"
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.target.value)}
                            >
                                <option value="">All Years</option>
                                {[...Array(2070 - 2023 + 1).keys()].map((year) => (
                                    <option key={year} value={year + 2023}>
                                        {year + 2023}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div>
                        <button onClick={handleExportToPdf} className="btn btn-primary mr-2">
                            Export to PDF
                        </button>
                        <button onClick={handleAddManualLog} className="btn btn-success">
                            Add Manual Log
                        </button>
                        <button onClick={handleAddInstructor} className="btn btn-info mr-2">
                        Add New Instructor
                        </button>
                    </div>
                </div>

                {/* Log Table */}
                <table className={`table table-bordered table-responsive table-light ${styles.table}`}>
                    <thead>
                        <tr>
                            <th>Instructor</th>
                            <th>Class/Team Name</th>
                            <th>Duration (Hours:Minutes)</th>
                            <th>Log Date</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredLogData.map((log) => (
                            <tr key={log.id}>
                                <td>{log.instructor_name}</td>
                                <td>{log.class_name}</td>
                                <td>{`${log.duration_hours} hours and ${log.duration_minutes} minutes`}</td>
                                <td>{formatDate(log.created_at)}</td>
                                <td>{log.type}</td>
                                <td style={{ color: log.paid ? 'green' : 'inherit' }}>
                                    {log.paid ? 'Paid' : ''}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Export PDF Modal */}
                <Modal show={exportModalOpen} onHide={() => setExportModalOpen(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Export to PDF</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label htmlFor="fromDate">From Date:</label>
                        <input
                            type="date"
                            id="fromDate"
                            value={exportFromDate}
                            onChange={(e) => setExportFromDate(e.target.value)}
                            className="form-control mb-3"
                        />

                        <label htmlFor="toDate">To Date:</label>
                        <input
                            type="date"
                            id="toDate"
                            value={exportToDate}
                            onChange={(e) => setExportToDate(e.target.value)}
                            className="form-control mb-3"
                        />

                        <label htmlFor="selectInstructor">Select Instructor:</label>
                        <select
                            id="selectInstructor"
                            value={selectedInstructor}
                            onChange={(e) => setSelectedInstructor(e.target.value)}
                            className="form-select mb-3"
                        >
                            <option value="">Select an Instructor</option>
                            {[...new Set(logData.map((log) => log.instructor_name))].map((instructor) => (
                                <option key={instructor} value={instructor}>
                                    {instructor}
                                </option>
                            ))}
                        </select>

                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control mb-3"
                        />

                        <label htmlFor="hourlyPayment">Hourly Payment Amount:</label>
                        <input
                            type="number"
                            id="hourlyPayment"
                            value={hourlyPayment}
                            onChange={(e) => setHourlyPayment(parseFloat(e.target.value))}
                            step="0.01"
                            min="0"
                            className="form-control mb-3"
                        />

                        <div className="form-check mb-3">
                            <input
                                type="checkbox"
                                id="markAsPaid"
                                checked={markAsPaid}
                                onChange={(e) => setMarkAsPaid(e.target.checked)}
                                className="form-check-input"
                            />
                            <label htmlFor="markAsPaid" className="form-check-label">
                                Mark as Paid
                            </label>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setExportModalOpen(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSubmitExport}>
                            Export
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Add Manual Log Modal */}
                <Modal show={addManualLogModalOpen} onHide={() => setAddManualLogModalOpen(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Manual Log</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <label htmlFor="selectInstructor">Select Instructor:</label>
                            <select
                                id="selectInstructor"
                                value={selectedInstructor}
                                onChange={(e) => setSelectedInstructor(e.target.value)}
                                className="form-select"
                            >
                                <option value="">Select an Instructor</option>
                                {instructorList.map((instructor) => (
                                    <option key={`${instructor.First_Name}-${instructor.Last_Name}`} value={`${instructor.First_Name} ${instructor.Last_Name}`}>
                                        {`${instructor.First_Name} ${instructor.Last_Name}`}
                                    </option>
                                ))}
                            </select>

                        </div>
                        <div className="mb-3">
                            <label htmlFor="selectClassTeam">Select Class/Team:</label>
                            <select
                                id="selectClassTeam"
                                value={selectedClassTeam}
                                onChange={(e) => setSelectedClassTeam(e.target.value)}
                                className="form-select"
                            >
                                <option value="">Select a Class/Team</option>
                                {[...classList, ...teamList].map((item) => (
                                    <option key={item.Class_Name_Batch || item.Team_Name_Batch} value={item.Class_Name_Batch || item.Team_Name_Batch}>
                                        {item.Class_Name_Batch || item.Team_Name_Batch}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="logType">Log Type:</label>
                            <select
                                id="logType"
                                value={logType}
                                onChange={(e) => setLogType(e.target.value)}
                                className="form-select"
                            >
                                <option value="">Select Type</option>
                                <option value="regular">Regular</option>
                                <option value="catchup">Catchup</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="hours">Duration (Hours):</label>
                            <input
                                type="number"
                                id="hours"
                                value={hours}
                                onChange={(e) => setHours(parseInt(e.target.value))}
                                className="form-control"
                                min="0"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="minutes">Duration (Minutes):</label>
                            <input
                                type="number"
                                id="minutes"
                                value={minutes}
                                onChange={(e) => setMinutes(parseInt(e.target.value))}
                                className="form-control"
                                min="0"
                                max="59"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setAddManualLogModalOpen(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSubmitManualLog}>
                            Add Log
                        </Button>
                    </Modal.Footer>
                </Modal>

                 {/* Add Instructor Modal */}
                 <Modal show={addInstructorModalOpen} onHide={() => setAddInstructorModalOpen(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add New Instructor</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-3">
                                <label htmlFor="firstName">First Name:</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    value={newInstructor.firstName}
                                    onChange={(e) => setNewInstructor({ ...newInstructor, firstName: e.target.value })}
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="lastName">Last Name:</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    value={newInstructor.lastName}
                                    onChange={(e) => setNewInstructor({ ...newInstructor, lastName: e.target.value })}
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="username">Username:</label>
                                <input
                                    type="text"
                                    id="username"
                                    value={newInstructor.username}
                                    onChange={(e) => setNewInstructor({ ...newInstructor, username: e.target.value })}
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password">Password:</label>
                                <input
                                    type="password"
                                    id="password"
                                    value={newInstructor.password}
                                    onChange={(e) => setNewInstructor({ ...newInstructor, password: e.target.value })}
                                    className="form-control"
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setAddInstructorModalOpen(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSubmitAddInstructor}>
                                Add Instructor
                            </Button>
                        </Modal.Footer>
                    </Modal>

            </div>
        </div>
    );
}

export default LogTable;
