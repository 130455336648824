import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';

const GiddaForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    phoneNumber: '',
    altContactFullName: '',
    altContactPhoneNumber: '',
    type: 'Gidda',
    occasion: '',
    dateOfEvent: '',
    performanceTime: '',
    banquetHallName: '',
    numberOfPeople: '',
    banquetHallAddress: '',
    sourceOfReference: '',
    termsAndConditionsName: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Assuming your Django endpoint is at '/api/submit-event'
      const response = await axios.post(
        'https://nachdijawani-melanie.com/api/custom_receive_form_data/',
        formData
      );
    
      // Extract necessary data from the form data
      const { email, numberOfPeople } = formData;
      const encodedEmail = encodeURIComponent(email);
      const encodedNumberOfPeople = encodeURIComponent(numberOfPeople);
    
      // Send email and number of people as parameters to the new page
      window.location.href = `https://nachdijawani-melanie.com/performancepay/${encodedEmail}/${encodedNumberOfPeople}`;
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  

  return (
    <Container className="mt-4">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Form onSubmit={handleSubmit}>

            <Form.Group controlId="email">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="fullName">
              <Form.Label>Full Name:</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="phoneNumber">
              <Form.Label>Phone Number:</Form.Label>
              <Form.Control
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="altContactFullName">
              <Form.Label>Alternative Contact Full Name:</Form.Label>
              <Form.Control
                type="text"
                name="altContactFullName"
                value={formData.altContactFullName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="altContactPhoneNumber">
              <Form.Label>Alternative Contact Phone Number:</Form.Label>
              <Form.Control
                type="tel"
                name="altContactPhoneNumber"
                value={formData.altContactPhoneNumber}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="occasion">
              <Form.Label>Occasion:</Form.Label>
              <Form.Control
                type="text"
                name="occasion"
                value={formData.occasion}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="dateOfEvent">
              <Form.Label>Date of Event:</Form.Label>
              <Form.Control
                type="date"
                name="dateOfEvent"
                value={formData.dateOfEvent}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="performanceTime">
              <Form.Label>Performance Time:</Form.Label>
              <Form.Control
                as="select"
                name="performanceTime"
                value={formData.performanceTime}
                onChange={handleChange}
                required
              >
                 <option value="">Select Option</option>
                <option value="7:00-7:30 pm">7:00-7:30 pm</option>
                <option value="7:30-8:00 pm">7:30-8:00 pm</option>
                <option value="8:00-8:30 pm">8:00-8:30 pm</option>
                <option value="8:30-9:00 pm">8:30-9:00 pm</option>
                <option value="9:00-9:30 pm">9:00-9:30 pm</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="banquetHallName">
              <Form.Label>Name of Banquet Hall:</Form.Label>
              <Form.Control
                type="text"
                name="banquetHallName"
                value={formData.banquetHallName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="numberOfPeople">
              <Form.Label>Number of People Needed:</Form.Label>
              <Form.Control
                as="select"
                name="numberOfPeople"
                value={formData.numberOfPeople}
                onChange={handleChange}
                required
              >
                <option value="">Select Option</option>
                <option value="4-people-600">4 people ($600)</option>
                <option value="6-people-800">6 people ($800)</option>
                <option value="8-people-1000">8 people ($1000)</option>
               
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="banquetHallAddress">
              <Form.Label>Address of Banquet Hall:</Form.Label>
              <Form.Control
                as="textarea"
                name="banquetHallAddress"
                value={formData.banquetHallAddress}
                onChange={handleChange}
                rows="4"
                required
              />
            </Form.Group>

            <Form.Group controlId="sourceOfReference">
              <Form.Label>Source of Reference (Optional):</Form.Label>
              <Form.Control
                type="text"
                name="sourceOfReference"
                value={formData.sourceOfReference}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="termsAndConditionsText">
              <Form.Label>Terms and Conditions:</Form.Label>
              <Form.Control
                as="textarea"
                value={`Terms and Conditions Agreement:

• You must pay the full performance amount to confirm the booking.
• You must choose a half-hour window for the performance.
• If the team doesn't get to perform in their window, they can leave or you must pay an additional $100 late fee to keep the performance.
• If you wish to make changes to the performance, please inform us in advance. We cannot ensure your changes, but we will try our best.
• The deposit is non-refundable if you cancel the performance.
• It is the responsibility of the Event Organizers to collect any tip thrown on stage and hand over to the performers.

By signing below, you hereby acknowledge that you have read and understood the terms and conditions of Nachdi Jawani Dance Studio.`}
                rows="8"
                disabled
              />
            </Form.Group>

            <Form.Group controlId="termsAndConditionsName">
              <Form.Label>
                I have read and agree to the terms and conditions. Enter your name:
              </Form.Label>
              <Form.Control
                type="text"
                name="termsAndConditionsName"
                value={formData.termsAndConditionsName}
                onChange={handleChange}
                required
              />
            </Form.Group>

           
            <Button variant="primary" type="submit">
              Submit
            </Button>

          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default GiddaForm;
