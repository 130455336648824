import React , { useState, useEffect } from 'react';


const BookingDecline = () => {
    useEffect(() => {
        document.title = 'Payment Declined';
    
    })
  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top" style={{ backgroundColor: 'transparent' }}>
        <div className="container-fluid">
          <h3 className="navbar-brand heading">Nachdi Jawani</h3>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link body-text" href="tel:+16479602900" style={{ color: '#D3D3D3' }}>Call Us</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://nachdijawani-melanie.com">
                  <button type="button" className="btn btn-light body-text">Go Back To Home</button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="container-main">
        <h1 className="heading" id="heading">Your Booking Payment was Declined!</h1>
        <p className="body-text" id="sub">Please contact our office or try again!</p>
      </div>

      <div className="gradient-glow" style={{ position: 'fixed', top: '85%', right: '-400px', transform: 'translateY(-50%)', width: '400px', height: '400px', borderRadius: '50%', boxShadow: '0 0 100px 100px rgba(255, 82, 82, 0.5), 0 0 200px 200px rgba(0, 184, 148, 0.5), 0 0 300px 300px rgba(255, 195, 18, 0.5)', pointerEvents: 'none', zIndex: '-9999' }}></div>
      <div className="gradient-glow-left" style={{ position: 'fixed', top: '85%', right: '1920px', transform: 'translateY(-50%)', width: '500px', height: '500px', borderRadius: '50%', boxShadow: '0 0 100px 100px rgba(255, 82, 82, 0.5), 0 0 200px 200px rgba(0, 184, 148, 0.5), 0 0 300px 300px rgba(255, 195, 18, 0.5)', pointerEvents: 'none', zIndex: '-9999' }}></div>
    </div>
  );
}

export default BookingDecline;
