import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbarin from '../components/Navbarin';
import styles from './AllStudents.module.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';

function formatDate(dateString) {
  if (!dateString) {
    return 'Not Available'; // Return 'null' if dateString is null
  }

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-US', options);
}

function ViewCancellationRequests() {
  const [students, setStudents] = useState([]);
  const [originalStudents, setOriginalStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const auth = useAuth() || {};
  const { isLoggedIn } = auth;

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Cancellation Requests';
    return () => {
      document.title = 'Original Title';
    };
  }, []);

  const storedUserType = localStorage.getItem('userType');

  useEffect(() => {
    if (!isLoggedIn || storedUserType !== 'admin') {
      navigate('/404');
      return null;
    }

    axios
      .get('https://nachdijawani-melanie.com/api/get-cancellation-requests/')
      .then((response) => {
        console.log('Response data:', response.data);
        if (response.data && Array.isArray(response.data.cancellation_requests)) {
          setStudents(response.data.cancellation_requests);
          setOriginalStudents(response.data.cancellation_requests);
        } else {
          console.error('Unexpected data format:', response.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching cancellation requests:', error);
        setIsLoading(false);
      });
  }, [isLoggedIn, navigate]);

  const filterStudents = () => {
    let filteredStudents = [...originalStudents];
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      const searchTermsArray = searchTermLower.split(' ');
      filteredStudents = filteredStudents.filter((student) => {
        return (
          searchTermsArray.every((term) =>
            student.first_name.toLowerCase().includes(term) ||
            student.last_name.toLowerCase().includes(term)
          )
        );
      });
    }
    // No sorting by name, already sorted by timestamp
    setStudents(filteredStudents);
  };

  useEffect(() => {
    filterStudents();
  }, [searchTerm, originalStudents]);

  // Sort students by timestamp
  const sortedStudents = [...students].sort((a, b) => {
    const dateA = new Date(a.timestamp);
    const dateB = new Date(b.timestamp);
    return dateB - dateA;
  });

  const filteredStudentCount = sortedStudents.length;

  return (
    <div className={`container-fluid position-relative ${styles.main}`}>
      <div className="row">
        <Navbarin />
        <div className={styles.studentList}>
          <h1 className={styles.heading}>Cancellation Requests</h1>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <React.Fragment>
              <div className={styles.searchBar}>
                <input
                  type="text"
                  placeholder="Search by name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className={styles.filteredCount}>
                {filteredStudentCount === 0 ? (
                  'No cancellation requests found'
                ) : (
                  `Showing ${filteredStudentCount} cancellation requests`
                )}
              </div>
              {filteredStudentCount > 0 && (
                <table
                  className={`table table-bordered table-light table-responsive ${styles.table}`}
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Team/Class Name</th>
                      <th>Terms Agreed</th>
                      <th>Timestamp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedStudents.map((student, index) => (
                      <tr key={index}>
                        <td>
                          <Link
                            to={`/student-details/${student.ID}`}
                            className={styles.Link}
                          >
                            {student.first_name} {student.last_name}
                          </Link>
                        </td>
                        <td>
                          <a href={`mailto:${student.email}`}>{student.email}</a>
                        </td>
                        <td>{student.selected_option}</td>
                        <td>{student.terms_agreed ? 'Yes' : 'No'}</td>
                        <td>{formatDate(student.timestamp)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {filteredStudentCount === 0 && <div>No cancellation requests found</div>}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewCancellationRequests;
