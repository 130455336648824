import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import styles from './register.module.css';
import RegisterImage from '../assets/img/registergradient.jpg';

function Cancel() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [Email, setEmail] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const formDataToSubmit = new FormData();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setFirstName(params.first_name || '');
    setLastName(params.last_name || '');
    setEmail(params.email || '');
  }, []);

  const handleSubmit = () => {
    // Include the necessary data for the first name and last name
    formDataToSubmit.append('first_name', firstName);
    formDataToSubmit.append('last_name', lastName);
    formDataToSubmit.append('email',Email);


    // Make a POST request to your Django view
    axios
      .post('https://nachdijawani-melanie.com/api/cancel/', formDataToSubmit)
      .then((response) => {
        // Handle a successful response
        window.alert('Cancellation Successful');
      })
      .catch((error) => {
        // Handle errors here
        console.error(error);
      });
  };

  return (
    <div className="container-fluid position-relative" id={styles.main}>
      <div className="row">
        <div className={`${styles.formContainer} container-fluid container`}>
          <div className={`${styles.formblur} container-fluid container`}>
            <form>
              <h2>Cancellation</h2>

              {/* Display Terms and Conditions as Plain Text */}
              <div className={styles.termsAndConditionsContainer}>
                <div className={styles.formField}>
                  <h3 className={styles.sectionTitle}>Cancellation Terms and Conditions</h3>
                  <p className={styles.sectionText}>
                  I acknowledge and fully understand that my membership will remain in effect for a 4-week period upon submission of this form. I acknowledge and fully understand that I am responsible for all membership fees to be paid during this 4-week period until my membership is void. I acknowledge and fully understand that I will need to re-register and pay all fees associated with re-registering should I decide to join Nachdi Jawani again in the future.
                  </p>
                </div>
              </div>

              {/* Checkbox for agreeing to terms */}
              <div className={styles.checkboxContainer}>
                <label>
                  <input
                    type="checkbox"
                    name="agreeTerms"
                    checked={agreeTerms}
                    onChange={() => setAgreeTerms(!agreeTerms)}
                  />
                  {' '}
                  I agree to the Terms and Conditions
                </label>
              </div>

              {/* Submit Button */}
              <div className={styles.buttonContainercancel}>
              <button
                type="button"
                className="btn btn-success"
                id={styles.formButton}
                onClick={handleSubmit}
                disabled={!agreeTerms}
              >
                Submit
              </button>
              </div>
            </form>
            <img src={RegisterImage} alt="Registration" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cancel;
