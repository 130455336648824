import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import styles from './ClassCard.module.css';
import { Link } from 'react-router-dom';

const ClassCard = ({ classData }) => {
    const getRandomLightColor = () => {
      const letters = '89ABCDEF'; // Lighter colors excluding 'ABCDEF' to keep the text readable
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 8)]; // Choose from '89ABCDEF'
      }
      return color;
    };
 

  // Assign a random background color to each card
  const randomBackgroundColor = getRandomLightColor();

  return (
   
   <Card className={styles.classCard} style={{ backgroundColor: randomBackgroundColor }}>
       <Link to={`/class/${classData.Class_Name_Batch}`}>
      {classData.image && (
        <div className={styles.imageContainer}>
          <Card.Img
            className={styles.classImage}
            variant="top"
            src={classData.image}
          />
        </div>
      )}
      <Card.Body>
        <Card.Title><b>{classData.Class_Name_Batch}</b></Card.Title>
        <Card.Text>
          <strong>Category: {classData.Category}</strong>
          <br />
          <strong>Days: {classData.Days + " "}</strong>
          <br />
          <strong>
            Time: {classData.Start_Time} - {classData.End_Time}
          </strong>
        </Card.Text>
      </Card.Body>
      </Link>
    </Card>
  );
};

export default ClassCard;
