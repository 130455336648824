import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Navbarin from '../components/Navbarin';
import axios from 'axios';
import styles from './attendance.module.css';
import { useAuth } from '../components/AuthContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';

function Attendance() {
  const { className } = useParams();
  const [classData, setClassData] = useState(null);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const canViewTable =
    localStorage.getItem('userType') === 'Instructor' ||
    localStorage.getItem('userType') === 'admin';

  const { userName, isLoggedIn, userClasses, lastName } = useAuth() || {};
  const currentDate = new Date();

  useEffect(() => {
    document.title = 'Attendance';

    axios
      .get(`https://nachdijawani-melanie.com/api/classes/${className}/`)
      .then((response) => {
        setClassData(response.data);

        axios
          .get(
            `https://nachdijawani-melanie.com/api/studentsbyclass/${response.data.Class_Name_Batch}/`
          )
          .then((studentsResponse) => {
            setStudents(studentsResponse.data);
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching students', error);
            setLoading(false);
          });

        axios
          .get(
            `https://nachdijawani-melanie.com/api/attendance/${response.data.ID}/`
          )
          .then((attendanceResponse) => {
            // Assuming the API returns data in the format { studentID: {Present: true/false}, ... }
            setAttendanceData(attendanceResponse.data);
          })
          .catch((error) => {
            console.error('Error fetching attendance data', error);
          });
      })
      .catch((error) => {
        console.error('Error fetching class data', error);
        setLoading(false);
      });
  }, [className]);

  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate('/404');
  }

 
  const sendEmailToAbsentees = () => {
    const selectedAbsentees = students.filter(
      (student) => selectedStudents[student.ID]
    );

    const emailData = selectedAbsentees.map((student) => ({
      email: student.Email,
      firstName: student.First_Name,
      lastName: student.Last_Name,
      className: className
    }));

    axios
      .post('https://nachdijawani-melanie.com/api/sendEmailToAbsentees/', {
        emailData: emailData,
      })
      .then((response) => {
        window.alert('Emails Sent Successfully!');
      })
      .catch((error) => {
        console.error('Error sending email to absentees', error);
      });
  };

  const reloadAttendanceData = () => {
    axios
      .get(`https://nachdijawani-melanie.com/api/attendance/${classData.ID}/`)
      .then((attendanceResponse) => {
        // Assuming the API returns data in the format { studentID: {Present: true/false}, ... }
        setAttendanceData(attendanceResponse.data);
      })
      .catch((error) => {
        console.error('Error fetching attendance data', error);
      });
  };

  const handleRevertAttendance = (studentID) => {
    axios
      .post(
        `https://nachdijawani-melanie.com/api/deleteAttendance/${classData.ID}/${studentID}/`
      )
      .then((response) => {
        reloadAttendanceData();
      })
      .catch((error) => {
        console.error('Error reverting attendance', error);
      });
  };

  const handleCheckboxChange = (studentID) => {
    setSelectedStudents({
      ...selectedStudents,
      [studentID]: !selectedStudents[studentID],
    });
  };

  const handleSelectAll = () => {
    const allSelected = !selectAll;
    const newSelectedStudents = {};

    students.forEach((student) => {
      newSelectedStudents[student.ID] = allSelected;
    });

    setSelectedStudents(newSelectedStudents);
    setSelectAll(allSelected);
  };

  return (
    <div className={`container-fluid position-relative`}>
      <div className="row">
        <Navbarin />
        <div className={styles.container}>
          <div className={styles.navCard}>
            <ul>
              <li>
                <h4>
                  <a href={`/class/${className}`}>Home</a>
                </h4>
              </li>
              {canViewTable && (
                <li>
                  <h4>
                    <Link to={`/attendance/${className}`}>Attendance</Link>
                  </h4>
                </li>
              )}

              <li>
                <h4>
                  <a href={`/announcements/${className}`}>Announcements</a>
                </h4>
              </li>
              <li>
                <h4>
                  <a href={`/video/${className}`}>Videos</a>
                </h4>
              </li>
            </ul>
          </div>
          <div className="container" id={styles.maincont}>
            <h1>
              Attendance
            </h1>

            <button
              onClick={sendEmailToAbsentees}
              className={styles.announcebtn}
            >
              Send Email to Absentees
            </button>

            <div className={styles.studentList}>
              {loading ? (
                <p>Loading students...</p>
              ) : students && students.length > 0 ? (
                <table className="table table-bordered table-light" id={styles.table}>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>Name</th>
                      <th>Image</th>
                      <th>Attendance Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.map((student) => {
                      const studentAttendance = attendanceData.find(
                        (item) => item.Student_ID === student.ID
                      );

                      return (
                        <tr key={student.ID}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedStudents[student.ID] || false}
                              onChange={() => handleCheckboxChange(student.ID)}
                            />
                          </td>
                          <td>{`${student.First_Name} ${student.Last_Name}`}</td>
                          <td>
                            <img
                              src={student.Image_URL}
                              alt={`${student.First_Name} ${student.Last_Name}`}
                              className={styles.avatarImage}
                            />
                          </td>
                          <td>
                            {studentAttendance ? (
                              <div>
                                <p className={`text-${studentAttendance.Present ? 'success' : 'danger'}`}>
                                  {studentAttendance.Present ? 'Present' : 'Absent'}
                                </p>
                                <Button
                                  variant="danger"
                                  onClick={() => handleRevertAttendance(student.ID)}
                                >
                                  Revert
                                </Button>
                              </div>
                            ) : (
                              <p>Not Marked Yet</p>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p>No students found.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attendance;
