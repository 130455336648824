import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Button from 'react-bootstrap/Button';
import styles from './Adminhome.module.css';
import Navbarin from '../components/Navbarin';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';

function AdminHome() {
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [announcementTitle, setAnnouncementTitle] = useState('');
  const [announcementContent, setAnnouncementContent] = useState('');
  const [selectedClass, setSelectedClass] = useState(null);
  const [cancelRequestedClass, setCancelRequestedClass] = useState(null);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelContent, setCancelContent] = useState('');
  const [adminNotes, setAdminNotes] = useState('');
  const [editedNotes, setEditedNotes] = useState('');
  const [editorValue, setEditorValue] = useState('');
  const [showPayModal, setShowPayModal] = useState(false);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);


  const [classes, setClasses] = useState(() => {
    const storedClasses = localStorage.getItem('userClasses');
    return storedClasses ? JSON.parse(storedClasses) : [];
  });

  const [teams, setTeams] = useState(() => {
    const storedTeams = localStorage.getItem('userTeams');
    return storedTeams ? JSON.parse(storedTeams) : [];
  });

  const fetchInstructorNotes = () => {
    axios
      .get('https://nachdijawani-melanie.com/api/notes/')
      .then((response) => {
        setAdminNotes(response.data.notes || '');
        setEditedNotes(response.data.notes || '');
        setEditorValue(response.data.notes || '');
      })
      .catch((error) => {
        console.error('Error fetching admin notes:', error);
      });
  };

  const handleTextareaBlur = () => {
    axios.post('https://nachdijawani-melanie.com/api/notes/', { notes: editorValue }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        fetchInstructorNotes();
      })
      .catch(error => {
        console.error('Error saving admin notes:', error);

        if (error.response) {
          console.error('Server responded with:', error.response.data);
          console.error('Status code:', error.response.status);
        } else if (error.request) {
          console.error('No response received. Request:', error.request);
        } else {
          console.error('Error setting up the request:', error.message);
        }
      });
  };

  const handleShowModal = (cls) => {
    setSelectedClass(cls);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedClass(null);
    setAnnouncementContent('');
    setAnnouncementTitle('');
  };

  const handleAnnouncementSubmit = () => {
    if (selectedClass) {
      const payload = {
        Title: announcementTitle,
        Content: announcementContent,
        class_name: selectedClass.Class_Name_Batch,
        class_id: selectedClass.ID,
        team_name: selectedClass.Team_Name_Batch || null,
        team_id: selectedClass.Team_ID || null,
        instructor_name: userName,
        last_name: lastName,
        type: 'announcement',
        student_id: null,
      };

      axios
        .post('https://nachdijawani-melanie.com/api/announcement/', payload)
        .then((response) => {
          if (response.data.message === 'sent successfully') {
            console.log('Announcement sent successfully:', response.data);
            handleCloseModal();
          } else {
            console.error('Error sending announcement:', response.data);
          }
        })
        .catch((error) => {
          console.error('Error sending announcement:', error);
        });
    } else {
      console.error('selectedClass is null');
    }
  };

  const handleShowCancelModal = (cls) => {
    setCancelRequestedClass(cls.Class_Name_Batch || cls.Team_Name_Batch);
    setSelectedClass(cls);
    setShowCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setCancelRequestedClass(null);
    setShowCancelModal(false);
    setCancelReason('');
    setCancelContent('');
  };

  const handleCancelSubmit = () => {
    if (selectedClass) {
      const payload = {
        Title: cancelReason,
        Content: cancelContent,
        class_name: cancelRequestedClass,
        class_id: selectedClass.ID,
        team_name: selectedClass.Team_Name_Batch || null,
        team_id: selectedClass.Team_ID || null,
        instructor_name: userName,
        last_name: lastName,
        type: 'cancellation',
        student_id: null,
      };

      axios
        .post('https://nachdijawani-melanie.com/api/announcement/', payload)
        .then((response) => {
          if (response.data.message === 'sent successfully') {
            console.log('Cancel request sent successfully:', response.data);
            handleCloseCancelModal();
          } else {
            console.error('Error sending cancel request:', response.data);
          }
        })
        .catch((error) => {
          console.error('Error sending cancel request:', error);
        });
    } else {
      console.error('selectedClass is null');
    }
  };

  const auth = useAuth() || {};
  const { userName, isLoggedIn, lastName } = auth;

  const navigate = useNavigate();
  const currentDate = new Date();

  useEffect(() => {
    fetchInstructorNotes();
    document.title = 'Admin - Home';

    return () => {
      document.title = 'Original Title';
    };
  }, []);

  const storedUserType = localStorage.getItem('userType');

  if (!isLoggedIn || storedUserType !== 'admin') {
    navigate('/404');
    return null;
  };

  const handleNotesChange = (value) => {
    setEditedNotes(value);
  };

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayOfWeek = currentDate.getDay();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  
  const handleUpload = () => {
    if (!file) {
      setUploadError('Please select a file.');
      return;
    }
  
    const formData = new FormData();
    formData.append('txt_file', file);
  
    axios.post('https://nachdijawani-melanie.com/api/process-txt/', formData)
      .then(response => {
        setUploading(false);
        setUploadError(null);
        setShowPayModal(false);
      })
      .catch(error => {
        console.error('Error uploading file:', error);
        setUploadError('Error uploading file. Please try again.');
        setUploading(false);
      });
  };



  return (
    <div className="container-fluid position-relative" id={styles.main}>
      <div className="row">
        <Navbarin />
        <div className={styles.welcomeMessage}>
          {userName ? (
            <p className={styles.welcometext}>
              Welcome, <span className={styles.name}>{userName}</span>!
            </p>
          ) : null}
        </div>

        <div className={styles.buttonContainer}>
          <Link to="/register">
            <Button className={styles.customButton}>Register a student</Button>
          </Link>
          <Link to="/notifications">
            <Button className={styles.customButton}>Instructor Notifications</Button>
          </Link>
          <Link to="/students">
            <Button className={styles.customButton}>View All Students</Button>
          </Link>
          <Link to="/empty-students">
            <Button className={styles.customButton}>View Empty Students</Button>
          </Link>
          <Link to="/log">
            <Button className={styles.customButton}>Instructor Log</Button>
          </Link>
          <Link to="/comms">
            <Button className={styles.customButton}>Studnt Comms</Button>
          </Link>
          <Link to="/performancebookings">
            <Button className={styles.customButton}>Bhangra/Gidda Booking</Button>
          </Link>
          <Link to="/dholbookings">
            <Button className={styles.customButton}>Dhol Bookings</Button>
          </Link>
          <Link to="/cancelledStudents">
            <Button className={styles.customButton}>Cancelled Students</Button>
          </Link> 
          <Link to="/cancel-requests">
            <Button className={styles.customButton}>Cancel Requests</Button>
          </Link> 
          <Link to="/holdStudents">
            <Button className={styles.customButton}>Hold Students</Button>
          </Link>
          <Button className={styles.customButton} onClick={() => setShowPayModal(true)}>Upload Text File</Button>
        </div>

        <div className={styles.squareDivContainer}>
          <div className={styles.squareDiv1}>
            <h4 id={styles.schedulerfont}>Schedule for {formattedDate}, {dayNames[dayOfWeek]}</h4>
            {(classes.length > 0 || teams.length > 0) ? (
              <ul className={styles.list}>
                {classes.map((cls) => (
                  <li key={cls.ID} className={styles['list-item']}>
                    <p>{cls.Class_Name_Batch}</p>
                    <p>{`${cls.Start_Time} - ${cls.End_Time}`}</p>
                    <button onClick={() => handleShowModal(cls)} id={styles.buttonmain}>Make Announcement</button>
                    <button onClick={() => handleShowCancelModal(cls)} id={styles.buttonmain}>Request Cancel</button>
                  </li>
                ))}
                {teams.map((team) => (
                  <li key={team.ID} className={styles['list-item']}>
                    <p>{team.Team_Name_Batch}</p>
                    <p>{`${team.Start_Time} - ${team.End_Time}`}</p>
                    <button onClick={() => handleShowModal(team)} id={styles.buttonmain}>Make Announcement</button>
                    <button onClick={() => handleShowCancelModal(team)} id={styles.buttonmain}>Request Cancel</button>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No classes or teams available</p>
            )}
          </div>
          <div className={styles.squareDiv2}>
          <h4 id={styles.schedulerfontnote}>Notes</h4>
            <textarea
              value={editorValue}
              onChange={(e) => setEditorValue(e.target.value)}
              onBlur={handleTextareaBlur}
              style={{ height: '500px', width: '100%', borderRadius: '10px', border: 'none' }}
              placeholder='Type your Notes Here'
            />
          </div>
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Make Announcement for : {selectedClass && selectedClass.Class_Name_Batch}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="announcementTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={announcementTitle}
                onChange={(e) => setAnnouncementTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="announcementContent">
              <Form.Label>Content</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={announcementContent}
                onChange={(e) => setAnnouncementContent(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAnnouncementSubmit}>
              Submit Announcement
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showCancelModal} onHide={handleCloseCancelModal}>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Announcement for : {cancelRequestedClass}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="cancelReason">
              <Form.Label>Reason for Cancellation</Form.Label>
              <Form.Control
                type="text"
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="cancelContent">
              <Form.Label>Additional Information</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={cancelContent}
                onChange={(e) => setCancelContent(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCancelModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleCancelSubmit}>
              Submit Cancel Request
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal className="fade" style={{ display: showPayModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" show={showPayModal} onHide={() => setShowPayModal(false)}>
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Upload Text File</h5>
        <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowPayModal(false)}></button>
      </div>
      <div className="modal-body">
        <form>
          <div className="mb-3">
            <label htmlFor="txtFile" className="form-label">Choose file</label>
            <input type="file" className="form-control" id="txtFile" onChange={handleFileChange} />
          </div>
        </form>
      </div>
      <div className="modal-footer">
        {uploadError && <p style={{ color: 'red' }}>{uploadError}</p>}
        <button type="button" className="btn btn-secondary" onClick={() => setShowPayModal(false)}>Close</button>
        <button type="button" className="btn btn-primary" onClick={handleUpload} disabled={uploading}>
          {uploading ? 'Uploading...' : 'Upload'}
        </button>
      </div>
    </div>
  </div>
</Modal>

      </div>
    </div>
  );
}

export default AdminHome;
