import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbarin from '../components/Navbarin';
import styles from './EmptyStudents.module.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';

function formatDate(dateString) {
  if (!dateString) {
    return '';
  }

  const [year, month, day] = dateString.split('-');
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  return `${parseInt(day, 10)} ${monthNames[parseInt(month, 10) - 1]} ${year}`;
}

function formatDateTime(dateTimeString) {
  if (!dateTimeString || dateTimeString === '1970-01-01T00:00:00Z') {
    return '';
  }

  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
  return new Date(dateTimeString).toLocaleDateString('en-US', options);
}

function ViewEmptyStudents() {
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { isLoggedIn } = useAuth() || {};
  const [editCommentId, setEditCommentId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Students';

    // Cleanup function
    return () => {
      document.title = 'Original Title';
    };
  }, []);

  useEffect(() => {
    // Fetch students data based on search term
    const fetchData = async () => {
      try {
        const response = await axios.get('https://nachdijawani-melanie.com/api/students/');
        const allStudents = response.data.students || [];

        // Filter students not assigned to any class or team
        const filteredStudents = allStudents.filter(student =>
          (!student.Classes_Enrolled_In || student.Classes_Enrolled_In.length === 0) &&
          (!student.Teams_Enrolled_In || student.Teams_Enrolled_In.length === 0) &&
          student.status !== 'cancel'
        );

        // Apply search term filter
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const searchedStudents = filteredStudents.filter(student =>
          student.First_Name.toLowerCase().includes(lowerCaseSearchTerm) ||
          student.Last_Name.toLowerCase().includes(lowerCaseSearchTerm)
        );

        // Sort searchedStudents by Category and registration_date
        searchedStudents.sort((a, b) => {
          const categoryA = a.Category || '';
          const categoryB = b.Category || '';
          if (categoryA < categoryB) return -1;
          if (categoryA > categoryB) return 1;
          const dateA = new Date(a.registration_date || '');
          const dateB = new Date(b.registration_date || '');
          return dateA - dateB;
        });

        setStudents(searchedStudents);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchData();
  }, [searchTerm]);

  const handleDelete = async (studentId) => {
    try {
      await axios.post(`https://nachdijawani-melanie.com/api/delete-student/`, { studentId });
      try {
        const response = await axios.get('https://nachdijawani-melanie.com/api/students/');
        const allStudents = response.data.students || [];

        // Filter students not assigned to any class or team
        const filteredStudents = allStudents.filter(student =>
          (!student.Classes_Enrolled_In || student.Classes_Enrolled_In.length === 0) &&
          (!student.Teams_Enrolled_In || student.Teams_Enrolled_In.length === 0) &&
          student.status !== 'cancel'
        );

        // Apply search term filter
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const searchedStudents = filteredStudents.filter(student =>
          student.First_Name.toLowerCase().includes(lowerCaseSearchTerm) ||
          student.Last_Name.toLowerCase().includes(lowerCaseSearchTerm)
        );

        // Sort searchedStudents by Category and registration_date
        searchedStudents.sort((a, b) => {
          const categoryA = a.Category || '';
          const categoryB = b.Category || '';
          if (categoryA < categoryB) return -1;
          if (categoryA > categoryB) return 1;
          const dateA = new Date(a.registration_date || '');
          const dateB = new Date(b.registration_date || '');
          return dateA - dateB;
        });

        setStudents(searchedStudents);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    } catch (error) {
      console.error('Error deleting student:', error);
    }
  };

  const handleCommentEdit = (studentId) => {
    setEditCommentId(studentId); // Set the student ID whose comment is being edited
  };

  const handleCommentSave = async (studentId, updatedComment) => {
    try {
      await axios.post(`https://nachdijawani-melanie.com/api/update-student-comment/${studentId}/`, { comments: updatedComment });
      setEditCommentId(null); 
      try {
        const response = await axios.get('https://nachdijawani-melanie.com/api/students/');
        const allStudents = response.data.students || [];

        // Filter students not assigned to any class or team
        const filteredStudents = allStudents.filter(student =>
          (!student.Classes_Enrolled_In || student.Classes_Enrolled_In.length === 0) &&
          (!student.Teams_Enrolled_In || student.Teams_Enrolled_In.length === 0) &&
          student.status !== 'cancel'
        );

        // Apply search term filter
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const searchedStudents = filteredStudents.filter(student =>
          student.First_Name.toLowerCase().includes(lowerCaseSearchTerm) ||
          student.Last_Name.toLowerCase().includes(lowerCaseSearchTerm)
        );

        // Sort searchedStudents by Category and registration_date
        searchedStudents.sort((a, b) => {
          const categoryA = a.Category || '';
          const categoryB = b.Category || '';
          if (categoryA < categoryB) return -1;
          if (categoryA > categoryB) return 1;
          const dateA = new Date(a.registration_date || '');
          const dateB = new Date(b.registration_date || '');
          return dateA - dateB;
        });

        setStudents(searchedStudents);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    } catch (error) {
      console.error('Error updating comment:', error);
    }
  };

  const handleCommentChange = (event, studentId) => {
    const updatedComment = event.target.value;
    const updatedStudents = students.map(student =>
      student.ID === studentId ? { ...student, comments: updatedComment } : student
    );
    setStudents(updatedStudents);
  };

  const handleCancelEdit = () => {
    setEditCommentId(null); // Reset editCommentId on cancel
  };

  if (!isLoggedIn) {
    navigate('/404');
    return null; // Render nothing if not logged in
  }

  return (
    <div className={`container-fluid position-relative ${styles.main}`}>
      <div className="row">
        <Navbarin />
        <div className={styles.studentList}>
          <h1 className={styles.heading}>View Empty Students</h1>
          <div className={styles.searchBar}>
            <input
              type="text"
              placeholder="Search by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <table className={`table table-bordered table ${styles.table}`}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Image</th>
                <th>Date of Birth</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Category</th>
                <th>Health Conditions</th>
                <th>Parent Phone</th>
                <th>Parent First Name</th>
                <th>Parent Last Name</th>
                <th>Terms</th>
                <th>Registration Date Time</th>
                <th>Payment Onetime</th>
                <th>Comments</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {students.map(student => (
                <tr key={student.ID}>
                  <td>
                    <Link to={`/student-details/${encodeURIComponent(`${student.First_Name} ${student.Last_Name}`)}/${encodeURIComponent(student.Email)}`}>
                      {student.First_Name} {student.Last_Name}
                    </Link>
                  </td>
                  <td>
                    <img src={student.Image_URL} alt={`${student.First_Name} ${student.Last_Name}`} className={styles.avatarImage} />
                  </td>
                  <td>{formatDate(student.DOB)}</td>
                  <td>{student.Email}</td>
                  <td>{student.phoneNumber}</td>
                  <td>{student.Category}</td>
                  <td>{student.HealthConditions}</td>
                  <td>{student.parentPhoneNumber}</td>
                  <td>{student.parentFirstName}</td>
                  <td>{student.parentLastName}</td>
                  <td>{student.terms}</td>
                  <td>{formatDateTime(student.registration_date)}</td>
                  <td>{student.payment_onetime}</td>
                  <td>
                    {editCommentId === student.ID ? (
                      <input
                        type="text"
                        value={student.comments || ''}
                        onChange={(e) => handleCommentChange(e, student.ID)}
                        autoFocus
                      />
                    ) : (
                      student.comments || '-'
                    )}
                  </td>
                  <td>
                    {editCommentId === student.ID ? (
                      <>
                        <button className="btn btn-success" onClick={() => handleCommentSave(student.ID, student.comments)}>
                          Save
                        </button>
                        <button className="btn btn-danger" onClick={handleCancelEdit}>
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button className="btn btn-primary" onClick={() => handleCommentEdit(student.ID)}>
                        Edit
                      </button>
                    )}
                    <button className="btn btn-danger" onClick={() => handleDelete(student.ID)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ViewEmptyStudents;
