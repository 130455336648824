import React, { createContext, useContext, useState, useEffect } from 'react';

const BarcodeScannerContext = createContext();

export const BarcodeScannerProvider = ({ children }) => {
  const [scannedData, setScannedData] = useState('');

  const handleBarcodeScan = (scannedValue) => {
    if (scannedValue !== '') {
      setScannedData(scannedValue);
      // You can perform any additional logic here
      console.log('Scanned data:', scannedValue);
    }
  };

  useEffect(() => {
    // Replace this with the actual event listener for your QR code scanner
    const handleScannerEvent = (event) => {
        const scannedValue = event.detail.value;
        handleBarcodeScan(scannedValue);
      };

    // Attach the event listener to the QR code scanner
    document.addEventListener('yourScannerEvent', handleScannerEvent);

    // Cleanup logic if needed
    return () => {
      // Remove the event listener during cleanup
      document.removeEventListener('yourScannerEvent', handleScannerEvent);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <BarcodeScannerContext.Provider value={{ scannedData, handleBarcodeScan }}>
      {children}
    </BarcodeScannerContext.Provider>
  );
};

export const useBarcodeScanner = () => {
  const context = useContext(BarcodeScannerContext);
  if (!context) {
    throw new Error('useBarcodeScanner must be used within a BarcodeScannerProvider');
  }
  return context;
};
