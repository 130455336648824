import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import styles from './Adminhome.module.css';
import Navbarin from '../components/Navbarin';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import quoteimage from '../assets/img/quote.png';
import axios from 'axios';

const formatDateTime = (dateTimeString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  return new Date(dateTimeString).toLocaleDateString('en-US', options);
};

function UserHome() {
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');
  const [messageModalShow, setMessageModalShow] = useState(false);
  const [message, setMessage] = useState('');
  const [profile, setProfile] = useState(null);
  const [announcements, setAnnouncements] = useState([]);
  const email = `${encodeURIComponent(localStorage.getItem('email'))}`;
  const fullName = `${encodeURIComponent(localStorage.getItem('userName'))}%20${encodeURIComponent(localStorage.getItem('lastName'))}`;
  const [viewAnnouncementsModalShow, setViewAnnouncementsModalShow] = useState(false);

  // Function to open/close the announcements modal
  const handleViewAnnouncementsModal = () => {
    setViewAnnouncementsModalShow(!viewAnnouncementsModalShow);
  };

  const handleMarkAbsent = (entity, type) => {
    setSelectedEntity({ entity, type }); // Add 'type' to selectedEntity
    setMessageModalShow(true);
  };

  const handleModalClose = () => {
    setMessageModalShow(false);
  };

  const handleMarkAbsentSubmit = () => {
    // Simulate marking absent by sending a request to the server
    const payload = {
      studentname: localStorage.getItem('userName') + ' ' + localStorage.getItem('lastName'),
      entityId: selectedEntity.entity.ID, // Access 'entity' within selectedEntity
      datetime: new Date(),
      present: 'absent',
      entityType: selectedEntity.type, // Access 'type' directly
      message: message,
    };

    axios
      .post('https://nachdijawani-melanie.com/api/mark-absent/', payload) // Replace with your API endpoint
      .then((response) => {
        if (response.data.message === 'Marked absent successfully') {
          window.alert('Marked absent successfully');
          setMessage('');
          setMessageModalShow(false);
        } else {
          window.alert('Error');
        }
      })
      .catch((error) => {
        console.error('Error marking absent:', error);
      });
  };

  const { userName, isLoggedIn, lastName } = useAuth() || {};
  const navigate = useNavigate();
  const currentDate = new Date();

  const [classes, setClasses] = useState(() => {
    const storedClasses = localStorage.getItem('userClasses');
    return storedClasses ? JSON.parse(storedClasses) : [];
  });

  const [teams, setTeams] = useState(() => {
    const storedTeams = localStorage.getItem('userTeams');
    return storedTeams ? JSON.parse(storedTeams) : [];
  });

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayOfWeek = currentDate.getDay();

  useEffect(() => {
    document.title = 'Student - Home';

    axios.get(`https://nachdijawani-melanie.com/api/student-details/${fullName}/${email}/`)
      .then(response => {
        setProfile(response.data);
      })
      .catch(error => {
        console.error('Error fetching student profile', error);
      });
    const studentid = localStorage.getItem('userID');
    const studentIdAsInt = parseInt(studentid, 10);

    axios.get(`https://nachdijawani-melanie.com/api/announcements-by-student/${studentIdAsInt}/`)
      .then(response => {
        setAnnouncements(response.data.announcements);
      })
      .catch(error => {
        console.error('Error fetching announcements', error);
      });

    return () => {
      document.title = 'Original Title';
    };
  }, []);

  const storedUserType = localStorage.getItem('userType');

  if (!isLoggedIn || storedUserType !== 'student') {
    navigate('/404');
    return null;
  }



  return (
    <div className="container-fluid position-relative" id={styles.main}>
      <div className="row">
        <Navbarin />
        <div className={styles.welcomeMessage}>
          {userName ? (
            <p className={styles.welcometext}>
              Welcome, <span className={styles.name}>{userName}</span>!
            </p>
          ) : null}
        </div>
        

        <div className={styles.squareDivContainer}>
          <div className={styles.squareDiv1}>
            <h4>Schedule for {formattedDate}, {dayNames[dayOfWeek]}</h4>
            {(classes.length > 0 || teams.length > 0) ? (
              <ul className={styles.list}>
                {classes.map((cls) => (
                  <li key={cls.ID} className={styles['list-item']}>
                    <p>{cls.Class_Name_Batch}</p>
                    <p>{`${cls.Start_Time} - ${cls.End_Time}`}</p>
                    <button onClick={() => handleMarkAbsent(cls, 'class')}>Mark Absent</button>
                  </li>
                ))}
                {teams.map((team) => (
                  <li key={team.ID} className={styles['list-item']}>
                    <p>{team.Team_Name_Batch}</p>
                    <p>{`${team.Start_Time} - ${team.End_Time}`}</p>
                    <button onClick={() => handleMarkAbsent(team, 'team')}>Mark Absent</button>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No classes or teams available</p>
            )}
          </div>
          <div className={styles.squareDiv2}>
            <div id={styles.profile}>
              {profile ? (
                <>
                  {profile.Image_URL && <img src={profile.Image_URL} alt="Student" id={styles.profileimage} />}
                  <p>{`Name: ${profile.First_Name} ${profile.Last_Name}`}</p>
                  <p>{`Date of Birth: ${profile.DOB}`}</p>
                  <p>{`Email: ${profile.Email}`}</p>
                  <p>{`Phone: ${profile.phoneNumber}`}</p>
                </>
              ) : null}
            </div>

           <Button variant="primary" id={styles.Buttonannouncement} onClick={handleViewAnnouncementsModal}>
              View Announcements
            </Button>

            {successMessage && <p className="text-success">{successMessage}</p>}
            {error && <p className="text-danger">{error}</p>}
          </div>



          <Modal show={messageModalShow} onHide={handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Write a message to the instructor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleMarkAbsentSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
           {/* Modal for displaying announcements */}
           <Modal show={viewAnnouncementsModalShow} onHide={handleViewAnnouncementsModal}>
              <Modal.Header closeButton>
                <Modal.Title>Announcements</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className={styles.announcementContainer}>
                  {announcements.length > 0 && announcements.map((announcement) => (
                    <div key={announcement.ID} className={styles.announcementPost}>
                      <div className={styles.announcementPostContent}>
                        <h4>{announcement.Title}</h4>
                        <p>{announcement.Content}</p>
                      </div>
                      <div className={styles.announcementPostMeta}>
                        <p>{formatDateTime(announcement.Announcement_Date_Time)}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleViewAnnouncementsModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
        </div>
      </div>
    </div>
  );
}


export default UserHome;
