import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbarin from '../components/Navbarin';
import styles from './Notifications.module.css';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const { userName, isLoggedIn, userClasses, lastName } = useAuth() || {};
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Notifications';

    return () => {
      document.title = 'Original Title';
    };
  }, []);

  const markAsRead = (notificationId) => {
    axios
      .patch(`https:///nachdijawani-melanie.com/api/notifications/${notificationId}/mark-as-read/`, {
        username: userName,
      })
      .then((response) => {

        axios
        .get('https:///nachdijawani-melanie.com/api/notifications/')
        .then((response) => {
          const notifications = response.data;

      // Separate read and unread notifications
      const readNotifications = notifications.filter((notification) => notification.Status === 'Read');
      const unreadNotifications = notifications.filter((notification) => notification.Status === 'Unread');

      // Sort unread notifications by date in descending order (most recent first)
      unreadNotifications.sort((a, b) => new Date(b.Date_Time) - new Date(a.Date_Time));

      // Sort read notifications by date in descending order (most recent first)
      readNotifications.sort((a, b) => new Date(b.Date_Time) - new Date(a.Date_Time));

      // Concatenate unread and read notifications to prioritize unread notifications
      setNotifications([...unreadNotifications, ...readNotifications]);
        })
        .catch((error) => {
          console.error('Error fetching notifications:', error);
        });
      })
        .catch((error) => {
          console.error('Error marking notification as read:', error);
        });   
  };
  

  // Function to format the date and time
  const formatDateTime = (dateTimeString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateTimeString).toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    // Make an API request to fetch notifications from your Django backend
    axios
      .get('https:///nachdijawani-melanie.com/api/notifications/')
      .then((response) => {
        const notifications = response.data;

        // Separate read and unread notifications
        const readNotifications = notifications.filter((notification) => notification.Status === 'Read');
        const unreadNotifications = notifications.filter((notification) => notification.Status === 'Unread');
  
        // Sort unread notifications by date in descending order (most recent first)
        unreadNotifications.sort((a, b) => new Date(b.Date_Time) - new Date(a.Date_Time));
  
        // Sort read notifications by date in descending order (most recent first)
        readNotifications.sort((a, b) => new Date(b.Date_Time) - new Date(a.Date_Time));
  
        // Concatenate unread and read notifications to prioritize unread notifications
        setNotifications([...unreadNotifications, ...readNotifications]);
      })
      .catch((error) => {
        console.error('Error fetching notifications:', error);
      });
  }, []);
  
  if (!isLoggedIn) {
    navigate('/404');
    return null;
  }

  return (
    <div className="container-fluid position-relative">
      <div className="row">
        <Navbarin />
        <div className={styles.tablecontainer}>
          <h1 className={styles.heading}>Instructor Notifications</h1>
          <table className={`table table-bordered table-light table-responsive ${styles.table}`}>
            <thead>
              <tr>
                <th>Title</th>
                <th>Content</th>
                <th>Type</th>
                <th>Date/Time</th>
                <th>Status</th>
                <th>Read By</th>
              </tr>
            </thead>
            <tbody>
              {notifications.map((notification) => (
                <tr key={notification.ID}>
                  <td>{notification.Title}</td>
                  <td>{notification.Content}</td>
                  <td>{notification.Type}</td>
                  <td>{formatDateTime(notification.Date_Time)}</td>
                  <td>
                    {notification.Status === 'Unread' || ( notification.ReadByUsers != null  && !notification.ReadByUsers.includes(userName) )|| notification.ReadByUsers === null ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => markAsRead(notification.ID)}
                      >
                        Mark as Read
                      </button>
                    ) : (
                      <span>Read</span>
                    )}
                  </td>
                  <td>{notification.ReadByUsers}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Notifications;