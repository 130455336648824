import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbarin from '../components/Navbarin';
import styles from './AllStudents.module.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';

function formatDate(dateString) {
  if (!dateString) {
    return '';
  }

  const [year, month, day] = dateString.split('-');
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  return `${parseInt(day, 10)} ${monthNames[parseInt(month, 10) - 1]} ${year}`;
}

function formatDateTime(dateTimeString) {
  if (!dateTimeString || dateTimeString === '1970-01-01T00:00:00Z') {
    return '';
  }

  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
  return new Date(dateTimeString).toLocaleDateString('en-US', options);
}

function ViewAllStudents() {
  const [students, setStudents] = useState([]);
  const [originalStudents, setOriginalStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const { isLoggedIn } = useAuth() || {};
  const [classOptions, setClassOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Students';

    return () => {
      document.title = 'Original Title';
    };
  }, []);

  useEffect(() => {
    // Make an API request to fetch all students
    Promise.all([
      axios.get('https://nachdijawani-melanie.com/api/class-names/'),
      axios.get('https://nachdijawani-melanie.com/api/team-names/'),
      axios.get('https://nachdijawani-melanie.com/api/students/')
    ]).then(([classesResponse, teamsResponse, studentsResponse]) => {
      setClassOptions(classesResponse.data);
      setTeamOptions(teamsResponse.data);
      setStudents(studentsResponse.data.students);
      setOriginalStudents(studentsResponse.data.students);
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/404');
    }
  }, [isLoggedIn, navigate]);

  const filterStudents = () => {
    let filteredStudents = [...originalStudents];
  
    // Apply class filter
    if (selectedClass && selectedClass !== "All Classes") {
        filteredStudents = filteredStudents.filter(
            (student) =>
                student.Classes_Enrolled_In &&
                student.Classes_Enrolled_In.includes(selectedClass) &&
                (!student.Teams_Enrolled_In || student.Teams_Enrolled_In.length === 0) &&
                student.Status !== "Hold" &&
                student.Status !== "cancel"
        );
    }
  
    if (selectedClass === "All Classes") {
        filteredStudents = filteredStudents.filter(
            (student) =>
                (!student.Teams_Enrolled_In || student.Teams_Enrolled_In.length === 0) &&
                student.Status !== "Hold" &&
                student.Status !== "cancel"
        );
    }
  
    // Apply team filter
    if (selectedTeam && selectedTeam !== "All Teams") {
        filteredStudents = filteredStudents.filter(
            (student) =>
                student.Teams_Enrolled_In &&
                student.Teams_Enrolled_In.includes(selectedTeam) &&
                (!student.Classes_Enrolled_In || student.Classes_Enrolled_In.length === 0) &&
                student.Status !== "Hold" &&
                student.Status !== "cancel"
        );
    }
  
    if (selectedTeam === "All Teams") {
        filteredStudents = filteredStudents.filter(
            (student) =>
                (!student.Classes_Enrolled_In || student.Classes_Enrolled_In.length === 0) &&
                student.Status !== "Hold" &&
                student.Status !== "cancel"
        );
    }

    filteredStudents = filteredStudents.filter(
        (student) =>
            (student.Classes_Enrolled_In && student.Classes_Enrolled_In.length > 0) ||
            (student.Teams_Enrolled_In && student.Teams_Enrolled_In.length > 0)
    );
  
    // Apply search term filter
    if (searchTerm) {
        const searchTermLower = searchTerm.toLowerCase();
  
        filteredStudents = filteredStudents.filter((student) => {
            return (
                student.First_Name.toLowerCase().includes(searchTermLower) ||
                student.Last_Name.toLowerCase().includes(searchTermLower) ||
                (student.phoneNumber && student.phoneNumber.toString().includes(searchTermLower)) ||
                (student.cust_id !== null && student.cust_id !== undefined && student.cust_id.toString().includes(searchTermLower))
            );
        });
    }
  
    // Sort students by First_Name in ascending order
    filteredStudents.sort((a, b) => a.First_Name.localeCompare(b.First_Name));
  
    setStudents(filteredStudents);
};

  

  useEffect(() => {
    filterStudents();
  }, [searchTerm, selectedClass, selectedTeam, originalStudents]);

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value);
  };

  const filteredStudentCount = students.length;

  const handleDelete = async (studentId) => {
    try {
      // Send a POST request to delete the student
      await axios.post(`https://nachdijawani-melanie.com/api/delete-student/`, { studentId });
    
      // Fetch the updated list of students from the API
      const response = await axios.get('https://nachdijawani-melanie.com/api/students/');
      setOriginalStudents(response.data.students);
      setStudents(response.data.students);
      filterStudents();
    } catch (error) {
      console.error('Error deleting student:', error);
    }
  };
  

  return (
    <div className={`container-fluid position-relative ${styles.main}`}>
      <div className="row">
        <Navbarin />
        <div className={styles.studentList}>
          <h1 className={styles.heading}>View All Students</h1>
          <div className={styles.filters}>
            <div className={styles.filter}>
              <label htmlFor="classFilter">Filter by Class:</label>
              <select id="classFilter" onChange={handleClassChange} value={selectedClass}>
                <option value="">Filter By Classes</option>
                {classOptions.map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                ))}
              </select>
            </div>
            <div className={styles.filter}>
              <label htmlFor="teamFilter">Filter by Team:</label>
              <select id="teamFilter" onChange={handleTeamChange} value={selectedTeam}>
                <option value="">Filter By Teams</option>
                {teamOptions.map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.searchBar}>
            <input
              type="text"
              placeholder="Search by name, phone number or customer id"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className={styles.filteredCount}>
            {filteredStudentCount === 0
              ? 'No students found'
              : `Showing ${filteredStudentCount} students`}
          </div>
          <table className={`table table-bordered table-light table-responsive ${styles.table}`}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Image</th>
                <th>Customer ID</th>
                <th>Date of Birth</th>
                <th>Gender</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Classes Enrolled In</th>
                <th>Teams Enrolled In</th>
                <th>Health Conditions</th>
                <th>Parent Phone</th>
                <th>Parent First Name</th>
                <th>Parent Last Name</th>
                <th>Terms</th>
                <th>Date Time of Registration</th>
                <th>Payment One Time</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.ID}>
                  <td>
                  <Link to={`/student-details/${encodeURIComponent(`${student.First_Name} ${student.Last_Name}`)}/${encodeURIComponent(student.Email)}`} className={styles.Link}>
                      {student.First_Name} {student.Last_Name}
                    </Link>
                  </td>
                  <td>
                    <img src={student.Image_URL} alt={`${student.First_Name} ${student.Last_Name}`} className={styles.avatarImage} />
                  </td>
                  <td>{student.cust_id}</td>
                  <td>{formatDate(student.DOB)}</td>
                  <td>{student.gender}</td>
                  <td><a href={`mailto:${student.Email}`}>{student.Email}</a></td>
                  <td><a href={`tel:${student.phoneNumber}`}>{student.phoneNumber}</a></td>
                  <td>
                    {student.Classes_Enrolled_In ? student.Classes_Enrolled_In.join(', ') : ''}
                  </td>
                  <td>
                    {student.Teams_Enrolled_In ? student.Teams_Enrolled_In.join(', ') : ''}
                  </td>
                  <td>{student.HealthConditions}</td>
                  <td>{student.parentPhoneNumber}</td>
                  <td>{student.parentFirstName}</td>
                  <td>{student.parentLastName}</td>
                  <td>{student.terms}</td>
                  <td>{formatDateTime(student.registration_date)}</td>
                  <td>{student.payment_onetime}</td>
                  <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(student.ID)}
                >
                  Delete
                </button>
                </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ViewAllStudents;
