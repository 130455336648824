// Import necessary dependencies and styles
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbarin from '../components/Navbarin';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import styles from './dress.module.css';
import { Link } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

function DressList() {
  // State variables
  const [studentsData, setStudentsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [lastDressPaymentDate, setLastDressPaymentDate] = useState('');
  const [rateAmount, setRateAmount] = useState('');
  const [nextDressPaymentDate, setNextDressPaymentDate] = useState('');
  const { isLoggedIn } = useAuth() || {};
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dressHistory, setDressHistory] = useState([]);
  const [isViewHistoryModalOpen, setIsViewHistoryModalOpen] = useState(false);

  // Add a state to track whether the "Add Dress" modal should be open
  const [isAddDressModalOpen, setIsAddDressModalOpen] = useState(false);

  // Effect for setting document title and fetching data
  useEffect(() => {
    document.title = 'Dresses';

    // Fetch data from the API
    axios.get('https://nachdijawani-melanie.com/api/dress-list/')
      .then((response) => {
        const formattedData = response.data.map(student => ({
          ...student,
          dress_info: {
            ...student.dress_info,
            last_dress_payment_date: student.dress_info?.Last_Dress_Payment_Date
              ? new Date(student.dress_info.Last_Dress_Payment_Date).toLocaleDateString()
              : null,
            Next_Dress_Payment_Date: student.dress_info?.Next_Dress_Payment_Date
              ? new Date(student.dress_info.Next_Dress_Payment_Date).toLocaleDateString()
              : null,
          },
        }));

        setStudentsData(formattedData);

        formattedData.forEach(student => {
          if (
            student.dress_info &&
            isToday(new Date(student.dress_info.Next_Dress_Payment_Date))
          ) {
            // Send a request to the backend API for the student
            axios.post('https://nachdijawani-melanie.com/api/send-dress-payment-due-notification/', { studentId: student.student_id })
              .then((notificationResponse) => {
                console.log('Notification sent successfully', notificationResponse);
              })
              .catch((notificationError) => {
                console.error('Error sending notification', notificationError);
              });
          }
        });
      })
      .catch((error) => {
        console.error('Error fetching dress data', error);
      });

    return () => {
      document.title = 'Original Title';
    };
  }, []);

  // Update the state for a specific student
  const handleStudentDataChange = (studentId, fieldName, value) => {
    setStudentsData((prevStudentsData) => {
      const updatedStudentsData = [...prevStudentsData];
      const studentIndex = updatedStudentsData.findIndex((student) => student.student_id === studentId);

      if (studentIndex !== -1) {
        updatedStudentsData[studentIndex] = {
          ...updatedStudentsData[studentIndex],
          dress_info: {
            ...updatedStudentsData[studentIndex].dress_info,
            [fieldName]: value,
          },
        };
      }

      return updatedStudentsData;
    });
  };

  // Change these comments:
  const openAddDressModal = (student) => {
    setSelectedStudent(student);
    setLastDressPaymentDate('');
    setRateAmount('');
    setNextDressPaymentDate('');

    // Fetch dress history and set it in state
    axios.get(`https://nachdijawani-melanie.com/api/dress/${student.student_id}/`)
      .then((response) => {
        setDressHistory(response.data);
      })
      .catch((error) => {
        console.error('Error fetching dress history', error);
      });

    // Set the state to open the "Add Dress" modal
    setIsAddDressModalOpen(true);
    setIsViewHistoryModalOpen(false);
  };




  const hideAddDressModal = () => {
    // Clear the selected student and close the "Add Dress" modal
    setSelectedStudent(null);
    setLastDressPaymentDate('');
    setRateAmount('');
    setNextDressPaymentDate('');
    setIsAddDressModalOpen(false);
  };

  const hideViewHistoryModal = () => {
    setIsViewHistoryModalOpen(false);
  };

  const hideModal = () => {
    setSelectedStudent(null);
    setLastDressPaymentDate('');
    setRateAmount('');
    setNextDressPaymentDate('');
    setIsModalOpen(false); // Set isModalOpen to false to hide the modal
  };

  // Function to check if a date is today
  const isToday = (someDate) => {
    const today = new Date();
    const currentDate = new Date(someDate);

    // Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison
    today.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    return currentDate.getTime() === today.getTime();
  };

  // Function to handle the submission of dress data
  const handleAddDressSubmit = () => {
    document.getElementById('dresssubbtn').disabled = true;
    if (selectedStudent) {
      const { student_id, dress_info } = selectedStudent;

      const data = {
        studentId: student_id,
        lastDressPaymentDate,
        rateAmount,
        nextDressPaymentDate,
      };

      axios.post('https://nachdijawani-melanie.com/api/updateDress/', data)
        .then((response) => {
          const nextDressPaymentDate = response.data.Next_Dress_Payment_Date;
          const today = new Date().toISOString().split('T')[0];
          let formattedData;
          window.alert('saved successfully')

          if (nextDressPaymentDate !== undefined) {
            // Capture necessary information before updating the state
            const updatedStudent = {
              ...selectedStudent,
              dress_info: {
                ...selectedStudent.dress_info,
                last_dress_payment_date: lastDressPaymentDate,
                rate_amount: rateAmount,
                Next_Dress_Payment_Date: nextDressPaymentDate,
              },
            };

            // Refresh the data after successful submission
            setStudentsData((prevStudentsData) =>
              prevStudentsData.map((student) =>
                student.student_id === student_id ? updatedStudent : student
              )
            );

            setSelectedStudent(null);
            setLastDressPaymentDate('');
            setRateAmount('');
            setNextDressPaymentDate('');

            // Hide the modal using Bootstrap functions
            setIsModalOpen(false);

            setStudentsData(formattedData);

            axios.get('https://nachdijawani-melanie.com/api/dress-list/')
              .then((response) => {
                formattedData = response.data.map(student => ({
                  ...student,
                  dress_info: {
                    ...student.dress_info,
                    last_dress_payment_date: student.dress_info?.Last_Dress_Payment_Date
                      ? new Date(student.dress_info.Last_Dress_Payment_Date).toLocaleDateString()
                      : null,
                    Next_Dress_Payment_Date: student.dress_info?.Next_Dress_Payment_Date
                      ? new Date(student.dress_info.Next_Dress_Payment_Date).toLocaleDateString()
                      : null,
                  }
                }));
              })
              .then(() => {
                // Use the formatted data to update the state
                setStudentsData(formattedData);

                if (nextDressPaymentDate === today) {
                  // Send a request to the backend API for the student
                  axios.post('https://nachdijawani-melanie.com/api/send-dress-payment-due-notification/', { studentId: student_id })
                    .then((notificationResponse) => {
                      console.log('Notification sent successfully', notificationResponse);
                    })
                    .catch((notificationError) => {
                      console.error('Error sending notification', notificationError);
                    });
                }
              })
              .catch((error) => {
                console.error('Error fetching dress data', error);
              });
          } else {
            console.error("Invalid or missing Next_Dress_Payment_Date in API response:", response.data);
          }
        })
        .catch((error) => {
          console.error('Error adding dress data', error);
        });
    }
  };

  if (!isLoggedIn) {
    navigate('/404');
    return null;
  }

  // Filter students based on search term
  const filteredStudents = studentsData
  .filter((student) =>
    student.student_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    student.student_email.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .sort((a, b) => {
    const nameA = a.student_name.toLowerCase();
    const nameB = b.student_name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const openViewHistoryModal = (studentId) => {
    // Fetch dress history and set it in state
    axios.get(`https://nachdijawani-melanie.com/api/dress/${studentId}/`)
      .then((response) => {
        setDressHistory(response.data);
        // Set the state to open the "View Dress History" modal
        setIsViewHistoryModalOpen(true);
        setIsAddDressModalOpen(false);
      })
      .catch((error) => {
        console.error('Error fetching dress history', error);
      });
  };


  // Assuming you have a Django API endpoint for deleting dress history records
  const deleteDressHistoryRecord = (recordId) => {
    // Replace 'YOUR_API_ENDPOINT' with your actual Django API endpoint URL
    axios.post(`https://nachdijawani-melanie.com/api/dress-delete/${recordId}/`)
      .then(response => {
        // Handle success
        console.log("Record deleted successfully:", response.data);

      })
      .catch(error => {
        // Handle error deleting record
        console.error("Error deleting record:", error);
        throw error; // Rethrow error or handle as needed
      });
  };

  const handleDelete = (recordId) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteDressHistoryRecord(recordId)
      hideViewHistoryModal()
        .then(() => {
          // Fetch updated dress list data and send notifications
          axios.get('https://nachdijawani-melanie.com/api/dress-list/')
            .then((response) => {
              const formattedData = response.data.map(student => ({
                ...student,
                dress_info: {
                  ...student.dress_info,
                  last_dress_payment_date: student.dress_info?.Last_Dress_Payment_Date
                    ? new Date(student.dress_info.Last_Dress_Payment_Date).toLocaleDateString()
                    : null,
                  Next_Dress_Payment_Date: student.dress_info?.Next_Dress_Payment_Date
                    ? new Date(student.dress_info.Next_Dress_Payment_Date).toLocaleDateString()
                    : null,
                },
              }));
              setStudentsData(formattedData);

              formattedData.forEach(student => {
                if (
                  student.dress_info &&
                  isToday(new Date(student.dress_info.Next_Dress_Payment_Date))
                ) {
                  // Send a request to the backend API for the student
                  axios.post('https://nachdijawani-melanie.com/api/send-dress-payment-due-notification/', { studentId: student.student_id })
                    .then((notificationResponse) => {
                      console.log('Notification sent successfully', notificationResponse);
                    })
                    .catch((notificationError) => {
                      console.error('Error sending notification', notificationError);
                    });
                }
              });
            })
            .catch(error => {
              // Handle error fetching updated data
              console.error("Error fetching updated dress list data:", error);
            });
        })
        .catch(error => {
          // Handle error if needed
          // For example, show an error message to the user
          alert("Failed to delete record. Please try again later.");
        });
    }
  };

  const handleClearData = (studentId) => {
    if (window.confirm("Are you sure you want to clear the data for this student?")) {
      // Send a request to your Django backend to clear the data
      axios.post('https://nachdijawani-melanie.com/api/delete-dress-record/', { studentId })
        .then((response) => {
          // Handle success
          console.log("Data cleared successfully:", response.data);
          // Optionally, you can update the state or perform any other actions after clearing the data
        })
        .catch((error) => {
          // Handle error
          console.error("Error clearing data:", error);
          // Optionally, you can display an error message to the user
        });
    }
  };
  


  // JSX for rendering the component
  return (
    <div className={`container-fluid position-relative ${styles.main}`}>
      <div className="row">
        <Navbarin />
        <div className={styles.studentList}>
          <h1 className={styles.heading}>Dresses</h1>
          <div className={styles.searchBar}>
            <input
              type="text"
              placeholder="Search by name or email"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <table className={`table table-bordered table-dark ${styles.table}`}>
            <thead>
              <tr>
                <th>Student ID</th>
                <th>Student Name</th>
                <th>Image URL</th>
                <th>Email</th>
                <th>Last Dress Payment Date</th>
                <th>Next Dress Payment Date</th>
                <th>Rate Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredStudents.map((student, index) => (
                <tr
                  key={student.student_id}
                  className={index % 2 === 0 ? 'table-primary' : 'table-secondary'}
                >
                  <td>{student.student_id}</td>
                  <td>
                    <Link to={`/student-details/${encodeURIComponent(student.student_name)}/${encodeURIComponent(student.Email)}`}>
                      {student.student_name}
                    </Link>
                  </td>
                  <td>
                    <img src={student.image_url} alt={student.student_name} className={styles.avatarImage} />
                  </td>
                  <td>
                    {student.student_email}
                  </td>
                  <td>
                    {student.dress_info ? (
                      student.dress_info.last_dress_payment_date
                        ? new Date(student.dress_info.last_dress_payment_date).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })
                        : 'N/A'
                    ) : (
                      // Display input field for adding dress data
                      'N/A'
                    )}
                  </td>
                  <td
                    className={
                      student.dress_info &&
                        isToday(new Date(student.dress_info.Next_Dress_Payment_Date))
                        ? styles.todayBackground
                        : ''
                    }
                  >
                    {student.dress_info ? (
                      // Display existing dress data
                      student.dress_info.Next_Dress_Payment_Date
                        ? new Date(student.dress_info.Next_Dress_Payment_Date).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })
                        : 'N/A'
                    ) : (
                      // Display input field for adding dress data
                      'N/A'
                    )}
                  </td>
                  <td>
                    {student.dress_info ? (
                      // Display existing dress data
                      student.dress_info.Rate_Amount || 'N/A'
                    ) : (
                      // Display input field for adding dress data
                      'N/A'
                    )}
                  </td>
                  <td id={styles.buttonss}>
                    {student.dress_info ? (
                      // Display button for updating dress data
                      <>
                        <Button
                          type="button"
                          className="btn btn-primary"
                          id={styles.btn1}
                          onClick={() => openAddDressModal(student)}
                        >
                          Update Dress
                        </Button>
                        <Button
                          type="button"
                          className="btn btn-secondary"
                          id={styles.btn2}
                          onClick={() => openViewHistoryModal(student.student_id)}
                        >
                          View Dress History
                        </Button>
                        <Button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => handleClearData(student.student_id)}
                        >
                          Clear Data
                        </Button>
                      </>
                    ) : (
                      // Display button for adding dress data
                      <Button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => openAddDressModal(student)}
                      >
                        Add Dress
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for adding or updating dress data */}
      {isAddDressModalOpen && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Dress</h5>
                <button type="button" className="close" onClick={hideAddDressModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {/* Fields for adding or updating dress data */}
                <div className="form-group">
                  <label htmlFor="lastDressPaymentDate">Previous Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="lastDressPaymentDate"
                    value={lastDressPaymentDate}
                    onChange={(e) => setLastDressPaymentDate(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="nextDressPaymentDate">Next Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="nextDressPaymentDate"
                    value={nextDressPaymentDate}
                    onChange={(e) => setNextDressPaymentDate(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="rateAmount">Amount</label>
                  <input
                    type="text"
                    className="form-control"
                    id="rateAmount"
                    value={rateAmount}
                    onChange={(e) => setRateAmount(e.target.value)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={hideAddDressModal}>
                  Close
                </button>
                <button type="button" id='dresssubbtn' className="btn btn-primary" onClick={handleAddDressSubmit}>
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal for viewing dress history */}
      {isViewHistoryModalOpen && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Dress History</h5>
                <button type="button" className="close" onClick={hideViewHistoryModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {dressHistory.length > 0 ? (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Amount</th>
                        <th>Previous Payment Date</th>
                        <th>Next Payment Date</th>
                        <th>Delete Records</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dressHistory.map((historyItem) => (
                        <tr key={historyItem.ID}>
                          <td>{historyItem.Rate_Amount}</td>
                          <td>{historyItem.Last_Dress_Payment_Date ? new Date(historyItem.Last_Dress_Payment_Date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : 'N/A'}</td>
                          <td>{historyItem.Next_Dress_Payment_Date ? new Date(historyItem.Next_Dress_Payment_Date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : 'N/A'}</td>
                          <td>
                            <button className="btn btn-danger" onClick={() => handleDelete(historyItem.ID)}>Delete</button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No dress history records found for this student.</p>
                )}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={hideViewHistoryModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}

export default DressList;
