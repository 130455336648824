import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbarin from '../components/Navbarin';
import styles from './AllStudents.module.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-US', options);
}


function ViewHoldStudents() {
  const [students, setStudents] = useState([]);
  const [originalStudents, setOriginalStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const { userName, isLoggedIn, userClasses, lastName } = useAuth() || {};
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Hold Students';

    return () => {
      document.title = 'Original Title';
    };
  }, []);

  useEffect(() => {
    // Make an API request to fetch all students
    axios.get('https://nachdijawani-melanie.com/api/get_students_with_hold_status/')
      .then((response) => {
        // Extract students from the response
        const students = response.data.students;       
  
        // Iterate over each student and send a POST request to update their status
        students.forEach((student) => {
          const { First_Name, Last_Name } = student;
          // Make a POST request to update student status
          axios.post('https://nachdijawani-melanie.com/api/check-and-update-student-status/', {
            first_name: First_Name,
            last_name: Last_Name
          })
          .then((response) => {
            console.log('Student status updated:', response.data.message);
          })
          .catch((error) => {
            console.error('Error updating student status:', error);
          });
        });
  
        // Update state with the fetched students
        setStudents(students);
        setOriginalStudents(students);
      })
      .catch((error) => {
        console.error('Error fetching students:', error);
      });
  }, []);

  
  if (!isLoggedIn) {
    navigate('/404');
  }

  const filterStudents = () => {
    let filteredStudents = [...originalStudents];

    // Apply class filter
    if (selectedClass && selectedClass !== "All Classes") {
      filteredStudents = filteredStudents.filter(
        (student) =>
          student.Classes_Enrolled_In.includes(selectedClass) &&
          (student.Teams_Enrolled_In === null || student.Teams_Enrolled_In.length === 0)
      );
    }

    if (selectedClass === "All Classes") {
      filteredStudents = filteredStudents.filter(
        (student) =>
          (student.Teams_Enrolled_In === null || student.Teams_Enrolled_In.length === 0)
      );
    }

    // Apply team filter
    if (selectedTeam && selectedTeam !== "All Teams") {
      filteredStudents = filteredStudents.filter(
        (student) =>
          student.Teams_Enrolled_In.includes(selectedTeam) &&
          (student.Classes_Enrolled_In === null || student.Classes_Enrolled_In.length === 0)
      );
    }

    if (selectedTeam === "All Teams") {
      filteredStudents = filteredStudents.filter(
        (student) =>
          (student.Classes_Enrolled_In === null || student.Classes_Enrolled_In.length === 0)
      );
    }

    // Apply search term filter
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      const searchTermsArray = searchTermLower.split(' ');

      filteredStudents = filteredStudents.filter((student) => {
        return searchTermsArray.every((term) =>
          student.First_Name.toLowerCase().includes(term) ||
          student.Last_Name.toLowerCase().includes(term)
        );
      });
    }

    filteredStudents.sort((a, b) => a.First_Name.localeCompare(b.First_Name));

    setStudents(filteredStudents);
  };


  useEffect(() => {
    filterStudents();
  }, [searchTerm, selectedClass, selectedTeam, originalStudents]);

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value);
  };

  const filteredStudentCount = students.length;

  return (
    <div className={`container-fluid position-relative ${styles.main}`}>
      <div className="row">
        <Navbarin />
        <div className={styles.studentList}>
          <h1 className={styles.heading}>Students on Hold</h1>
          <div className={styles.filters}>
            <div className={styles.filter}>
              <label htmlFor="classFilter">Filter by Class:</label>
              <select id="classFilter" onChange={handleClassChange} value={selectedClass}>
                <option value="">Filter By Classes</option>
                <option value="All Classes">All Classes</option>
                <option value="Adult Bhangra">Adult Bhangra</option>
                <option value="Kids Bhangra">Kids Bhangra</option>
                <option value="Mens Bhangra">Mens Bhangra</option>
                <option value="Women Gidha">Women Gidha</option>
                <option value="Kids 11:30 Sunday">Kids 11:30 Sunday</option>
                <option value="Kids Bhangra Sunday">Kids Bhangra Sunday</option>
                <option value="Women Bhangra">Women Bhangra</option>
                <option value="Kids 5:30 Sunday">Kids 5:30 Sunday</option>
              </select>
            </div>
            <div className={styles.filter}>
              <label htmlFor="teamFilter">Filter by Team:</label>
              <select id="teamFilter" onChange={handleTeamChange} value={selectedTeam}>
                <option value="">Filter By Teams</option>
                <option value="All Teams">All Teams</option>
                <option value="Nachde Shokeen">Nachde Shokeen</option>
                <option value="NJ Raniya">NJ Raniya</option>
                <option value="Shan Virse di">Shan Virse di</option>
                <option value="RDT">RDT</option>
                <option value="Shokeen Majajna">Shokeen Majajna</option>
                <option value="Virsa">Virsa</option>
                <option value="Panjabi Virsa">Panjabi Virsa</option>
                <option value="Shaan Tringna di">Shaan Tringna di</option>
                <option value="Shaan e Punjab">Shaan e Punjab</option>
                <option value="Nachde Gabroos">Nachde Gabroos</option>
                <option value="Nachde Taare">Nachde Taare</option>
                <option value="Nachda Punjab">Nachda Punjab</option>
                <option value="Shankaar">Shankaar</option>


              </select>
            </div>
          </div>
          <div className={styles.searchBar}>
            <input
              type="text"
              placeholder="Search by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className={styles.filteredCount}>
            {filteredStudentCount === 0
              ? 'No students found'
              : `Showing ${filteredStudentCount} students`}
          </div>
          <table className={`table table-bordered table-light table-responsive ${styles.table}`}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Image</th>
                <th>Date of Birth</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Classes Enrolled In</th>
                <th>Teams Enrolled In</th>
                <th>Start Date</th>
                <th>End Date</th>

              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.id}>
                  <Link to={`/student-details/${encodeURIComponent(`${student.First_Name} ${student.Last_Name}`)}/${encodeURIComponent(student.Email)}`} className={styles.Link}>
                    <td>{student.First_Name} {student.Last_Name}</td>
                  </Link>
                  <td>
                    <img src={student.Image_URL} alt={`${student.First_Name} ${student.Last_Name}`} className={styles.avatarImage} />
                  </td>
                  <td>{formatDate(student.DOB)}</td>
                  <td><a href={`mailto:${student.Email}`}>{student.Email}</a></td>
                  <td><a href={`tel:${student.phoneNumber}`}>{student.phoneNumber}</a></td>
                  <td>
                    {student.Classes_Enrolled_In.join(', ')}
                  </td>
                  <td>
                    {student.Teams_Enrolled_In.join(', ')}
                  </td>
                <td>{student.hold_start_date}</td>
                <td>{student.hold_end_date}</td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ViewHoldStudents;
