import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import './global.css';
import Explore from './pages/Explore';
import Login from './pages/Login';
import AdminHome from './pages/adminhome';
import NotFound404 from './pages/NotFound404';
import { AuthProvider } from './components/AuthContext'; // Import your AuthProvider
import InstructorHome from './pages/instructorhome';
import Notifications from './pages/Notifications';
import ViewAllStudents from './pages/allstudents';
import Register from './pages/register';
import Classes from './pages/classes';
import Classinside from './pages/Classinside';  
import Teamsinside from './pages/teamsinside';
import Announcements from './pages/announcements';
import Attendance from './pages/attendance';
import { BarcodeScannerProvider } from './components/barcodescaner';
import Teams from './pages/teams';
import TeamAnnouncements from './pages/teamannouncements';
import TeamAttendance from './pages/attendanceteams';
import DressList from './pages/dress';
import StudentDetailsPage from './pages/StudentDetails';
import UserHome from './pages/userhome';
import ViewEmptyStudents from './pages/emptystudents';
import Schedule from './pages/schedule';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import VolunteersTable from './pages/Volunteers';
import LogTable from './pages/LogTable';
import VideoPage from './pages/videos';
import VideoTeamsPage from './pages/team-video';
import VolunteerAnnouncements from './pages/volunteerannouncements';
import RegisterExternal from './pages/registerExternal';
import Excel from './pages/Excel';
import CommunicationsPage from './pages/Communications';
import PaymentRedirect from './components/PaymentRedirect';
import PaymentRecurRedirect from './components/PaymentRecurRedirect';
import DholBookingsPage from './pages/dholbooking';
import PaymentBookingRedirect from './components/PaymentBookingRedirect';
import PaymentBookingBhangraRedirect from './components/PaymentBhangraBooking';
import PerformanceBookingsPage from './pages/performancebookings';
import Cancel from './pages/cancel';
import ViewCancelStudents from './pages/cancelledstudents';
import Products from './pages/Products';
import DeclinedPaymentPage from './components/PaymentDecline';
import ViewHoldStudents from './pages/holdstudents';
import PaymentHoldRecur from './components/PaymentHoldRecur';
import EventForm from './components/BookingPerformance';
import GiddaForm from './components/GiddaBooking';
import DholForm from './components/DholForm';
import Test from './pages/test';
import BookingDecline from './components/PaymentDeclined';
import CancellationRequestForm from './pages/CancellationForm';
import ViewCancellationRequests from './pages/cancelled-requests-show';
import CancellationSuccessPage from './components/successcancel';
import ForgotPassword from './pages/forgotpswd';
import ResetPassword from './pages/Resetpassword';

function App() {
  return (
    <Router>
      <DndProvider backend={HTML5Backend}>
      <BarcodeScannerProvider>
        <AuthProvider> 
          <div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/login" element={<Login />} />
              <Route path="/admin" element={<AdminHome />} />
              <Route path="/instructor" element={<InstructorHome />} />
              <Route path="/user" element={<UserHome />} />
              <Route path="/404" element={<NotFound404 />} />
              <Route path='/notifications' element={<Notifications />} />
              <Route path='/empty-students' element={<ViewEmptyStudents />} />
              <Route path='/students' element={<ViewAllStudents />} />
              <Route path='/register' element={<Register />} />
              <Route path='/classes' element={<Classes />} />
              <Route path="/class/:className" element={<Classinside />} />
              <Route path="/team/:teamName" element={<Teamsinside />} />
              <Route path="/announcements/:className" element={<Announcements />} />
              <Route path="/team-announcements/:teamName" element={<TeamAnnouncements />} />
              <Route path="/attendance/:className" element={<Attendance />} />
              <Route path="/team-attendance/:teamName" element={<TeamAttendance />} />
              <Route path='/teams' element={<Teams />} />
              <Route path='/dresses' element={<DressList />} />
              <Route path="/student-details/:studentName/:email" element ={<StudentDetailsPage />} />
              <Route path='/schedule' element={<Schedule />} />
              <Route path='/volunteers' element={<VolunteersTable />} />
              <Route path='/log' element={<LogTable />} />
              <Route path="/video/:className" element={<VideoPage />} />
              <Route path="/team-video/:teamName" element={<VideoTeamsPage />} />
              <Route path='/volunteerAnnouncements' element={<VolunteerAnnouncements />} />
              <Route path='/externalstudent' element={<RegisterExternal/>} />
              <Route path='/excel' element={<Excel/>} />
              <Route path='/comms' element={<CommunicationsPage/>} />
              <Route path='/onetimepay/:customerId/:email/:firstName/:lastName/:category' element={<PaymentRedirect></PaymentRedirect>}></Route>
              <Route path='/recurpay/:category/:customerId/:email/:firstName/:lastName/:phoneNumber/' element={<PaymentRecurRedirect />} />
              <Route path='/holdpay/:category/:customerId/:email/:firstName/:lastName/:phoneNumber/' element={<PaymentHoldRecur />} />
              <Route path='/dholbookings' element={<DholBookingsPage></DholBookingsPage>}></Route>
              <Route path='/bookingpay/:email/:dholPackage' element={<PaymentBookingRedirect />} />
              <Route path='/performancepay/:email/:performercount' element={<PaymentBookingBhangraRedirect/>} />
              <Route path='/performancebookings' element={<PerformanceBookingsPage></PerformanceBookingsPage>}></Route>
              <Route path='/cancel' element={<Cancel/>} />
              <Route path='/cancelledStudents' element={<ViewCancelStudents/>} />
              <Route path='/holdStudents' element={<ViewHoldStudents/>} />
              <Route path='/merch' element={<Products/>} />
              {/* <Route path='/paymentsuccess' element={<SuccessfulPaymentPage></SuccessfulPaymentPage>}></Route> */}
              <Route path='/paymentdecline' element={<BookingDecline></BookingDecline>}></Route>
              <Route path='/paymentdecline' element={<DeclinedPaymentPage></DeclinedPaymentPage>}></Route>
              <Route path='/bhangraform' element={< EventForm/>}></Route>
              <Route path='/giddaform' element={< GiddaForm/>}></Route>
              <Route path='/dholform' element={< DholForm/>}></Route>
              {/* <Route path='/Test' element={<Test/>}></Route> */}
              <Route path='/cancellation-request' element={< CancellationRequestForm/>}></Route>
              <Route path='/cancel-requests' element={< ViewCancellationRequests/>}></Route>
              <Route path='/cancellation-success' element={< CancellationSuccessPage/>}></Route>
              <Route path='/forgot-password' element={< ForgotPassword/>}></Route>
              <Route path="/reset-password/:uidb64/:token" element={ <ResetPassword />} />
            </Routes>
          </div>
        </AuthProvider>
      </BarcodeScannerProvider> 
      </DndProvider>
    </Router>
  );
}

export default App;
