import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userClasses, setUserClasses] = useState([]);
  const [lastName, setLastName] = useState('');
  const [userTeams, setUserTeams] = useState([]);
  const [userID, setUserID] = useState('');
  const [email, setEmail] = useState('');

  // Load authentication state, user data, classes, teams, and last name from localStorage on component mount
  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    const storedUserName = localStorage.getItem('userName');
    const storedLastName = localStorage.getItem('lastName');
    const storedUserClasses = JSON.parse(localStorage.getItem('userClasses')) || [];
    const storedUserTeams = JSON.parse(localStorage.getItem('userTeams')) || [];
    const storedUserID = localStorage.getItem('userID') || '';
    const email = localStorage.getItem('Email') || '';

    if (storedIsLoggedIn) {
      setIsLoggedIn(JSON.parse(storedIsLoggedIn));
    }
    if (storedUserName) {
      setUserName(storedUserName);
    }
    if (storedLastName) {
      setLastName(storedLastName);
    }
    if (storedUserClasses) {
      setUserClasses(storedUserClasses);
    }
    if (storedUserTeams) {
      setUserTeams(storedUserTeams);
    }
    if (storedUserID) {
      setUserID(storedUserID);
    }
    if(email){
      setEmail(email)
    }
  }, []);

  const login = (userName, lastName, classes, teams, userID,Email) => {
    setIsLoggedIn(true);
    setUserName(userName);
    setLastName(lastName);
    setUserClasses(classes || []);
    setUserTeams(teams || []);
    setUserID(userID);
    setEmail(Email);

    // Use the setState callback to ensure you get the updated state values
    localStorage.setItem('isLoggedIn', JSON.stringify(true));
    localStorage.setItem('userName', userName);
    localStorage.setItem('lastName', lastName);
    localStorage.setItem('userClasses', JSON.stringify(classes || []));
    localStorage.setItem('userTeams', JSON.stringify(teams || []));
    localStorage.setItem('userID', userID);
    localStorage.setItem('email',Email);
  };

  const logout = () => {
    setIsLoggedIn(false);
    setUserName('');
    setLastName('');
    setUserClasses([]);
    setUserTeams([]);
    setUserID('');
    setEmail('');

    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userType');
    localStorage.removeItem('userName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('userClasses');
    localStorage.removeItem('userTeams');
    localStorage.removeItem('userID');
    localStorage.removeItem('email')
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, userName, setUserName, userClasses, setUserClasses, lastName, setLastName, userTeams, setUserTeams, userID, setUserID }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
