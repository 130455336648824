import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import styles from './instructorhome.module.css';
import Navbarin from '../components/Navbarin';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';

function InstructorHome() {
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [announcementTitle, setAnnouncementTitle] = useState('');
  const [announcementContent, setAnnouncementContent] = useState('');
  const [selectedClass, setSelectedClass] = useState(null);
  const [cancelRequestedClass, setCancelRequestedClass] = useState(null);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelContent, setCancelContent] = useState('');
  const [showLogTypeModal, setShowLogTypeModal] = useState(false);
  const [logData, setLogData] = useState({
    selectedClass: null,
    durationHours: 0,
    durationMinutes: 0,
    type: 'Regular', // Default type
  });
  const [classesAll, setClassesAll] = useState({
    weekday_classes: [],
    weekend_classes: [],
  });

  const [teamsAll, setTeamsAll] = useState({
    weekday_teams: [],
    weekend_teams: [],
  });

  const [checkStatusResponse, setCheckStatusResponse] = useState(null);

  const handleShowModal = (cls) => {
    setSelectedClass(cls);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedClass(null);
    setAnnouncementContent('');
    setAnnouncementTitle('');
  };

  const handleAnnouncementSubmit = () => {
    if (selectedClass) {
      const payload = {
        Title: announcementTitle,
        Content: announcementContent,
        class_name: selectedClass.Class_Name_Batch,
        class_id: selectedClass.ID,
        team_name: selectedClass.Team_Name_Batch || null,
        team_id: selectedClass.Team_ID || null,
        instructor_name: userName,
        last_name: lastName,
        type: 'announcement',
        student_id: null, // Add the student_id if needed
      };

      axios
        .post('https://nachdijawani-melanie.com/api/announcement/', payload)
        .then((response) => {
          if (response.data.message === 'sent successfully') {
            console.log('Announcement sent successfully:', response.data);
            handleCloseModal();
          } else {
            console.error('Error sending announcement:', response.data);
          }
        })
        .catch((error) => {
          console.error('Error sending announcement:', error);
        });
    } else {
      console.error('selectedClass is null');
    }
  };

  const handleShowCancelModal = (cls) => {
    setCancelRequestedClass(cls.Class_Name_Batch || cls.Team_Name_Batch);
    setSelectedClass(cls);
    setShowCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setCancelRequestedClass(null);
    setShowCancelModal(false);
    setCancelReason('');
    setCancelContent('');
  };

  const handleLogClass = (selectedItem, logType) => {
    const { Class_Name_Batch, Team_Name_Batch, Start_Time, End_Time } = selectedItem;

    // Parse start and end times
    const [startHour, startMinute] = Start_Time.split(':').map(Number);
    const [endHour, endMinute] = End_Time.split(':').map(Number);

    // Calculate total minutes from the start time to end time
    const startTimeMinutes = startHour * 60 + startMinute;
    const endTimeMinutes = endHour * 60 + endMinute;

    // Calculate duration in minutes
    let durationMinutes = endTimeMinutes - startTimeMinutes;

    // Handle cases where the end time is on the next day
    if (durationMinutes < 0) {
      durationMinutes += 24 * 60; // Add 24 hours in minutes
    }

    // Calculate duration in hours and minutes
    const durationHours = Math.floor(durationMinutes / 60);
    durationMinutes = durationMinutes % 60;

    // Open the modal for type selection
    setShowLogTypeModal(true);

    // Set log data based on class or team type
    const logData = {
      selectedClass: logType === 'class' ? selectedItem : null,
      selectedTeam: logType === 'team' ? selectedItem : null,
      durationHours,
      durationMinutes,
      type: 'regular', // Default type
    };

    setLogData(logData);
  };



  const handleLogTypeSubmit = () => {
    // Check student status before logging
    const attendanceCheckURL = logData.selectedClass
      ? `https://nachdijawani-melanie.com/api/check_attendance/class/${logData.selectedClass?.ID}/`
      : `https://nachdijawani-melanie.com/api/check_attendance/team/${logData.selectedTeam?.ID}/`;

    axios
      .get(attendanceCheckURL)
      .then((response) => {
        const allStudentsPresent = response.data.allStudentsPresent;
        if (allStudentsPresent) {
          // Proceed with logging
          const logPayload = {
            class_name: logData.selectedClass ? logData.selectedClass.Class_Name_Batch : logData.selectedTeam.Team_Name_Batch,
            instructor_name: `${userName} ${lastName}`,
            duration_hours: logData.durationHours,
            duration_minutes: logData.durationMinutes,
            type: logData.type,
          };

          axios.post('https://nachdijawani-melanie.com/api/log_class/', logPayload)
            .then((logResponse) => {
              if (logResponse.data.message === 'Log data received successfully') {
                console.log('Class logged successfully:', logResponse.data);
                window.alert('Logged successfully');
              } else {
                console.error('Error logging class:', logResponse.data);
                window.alert('Error logging class');
              }
            })
            .catch((logError) => {
              if (logError.response && logError.response.data && logError.response.data.error === 'An entry for the same class/team on the current date already exists') {
                console.error('Entry already exists for the same class/team on the current date');
                window.alert('An entry for the same class/team on the current date already exists');
              } else {
              console.error('Error logging class:', logError);
              window.alert('Error logging class');
              }
            });

          // Reset check status response
          setCheckStatusResponse(null);
        } else {
          // Display alert for incomplete attendance
          setCheckStatusResponse(false);
          window.alert('Attendance for this class or team needs to be marked for all students');
        }
      })
      .catch((error) => {
        console.error('Error checking student status:', error);
        window.alert('Error checking student status');
        setCheckStatusResponse(false);
      });

    // Close the type selection modal
    setShowLogTypeModal(false);
  };


  const handleCancelSubmit = () => {
    if (selectedClass) {
      const payload = {
        Title: cancelReason,
        Content: cancelContent,
        class_name: cancelRequestedClass,
        class_id: selectedClass.ID,
        team_name: selectedClass.Team_Name_Batch || null,
        team_id: selectedClass.Team_ID || null,
        instructor_name: userName,
        last_name: lastName,
        type: 'cancellation',
        student_id: null, // Add the student_id if needed
      };

      axios
        .post('https://nachdijawani-melanie.com/api/announcement/', payload)
        .then((response) => {
          if (response.data.message === 'sent successfully') {
            console.log('Cancel request sent successfully:', response.data);
            handleCloseCancelModal();
          } else {
            console.error('Error sending cancel request:', response.data);
          }
        })
        .catch((error) => {
          console.error('Error sending cancel request:', error);
        });
    } else {
      console.error('selectedClass is null');
    }
  };

  const auth = useAuth() || {};
  const { userName, isLoggedIn, userClasses, lastName, userTeams } = auth;

  const navigate = useNavigate();
  const currentDate = new Date();

  const [classes, setClasses] = useState(() => {
    const storedClasses = localStorage.getItem('userClasses');
    return storedClasses ? JSON.parse(storedClasses) : [];
  });

  const [teams, setTeams] = useState(() => {
    const storedTeams = localStorage.getItem('userTeams');
    return storedTeams ? JSON.parse(storedTeams) : [];
  });

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayOfWeek = currentDate.getDay();

  useEffect(() => {
    const eventsPromise = axios.get('https://nachdijawani-melanie.com/api/Classes/?username=Sukhpal&lastname=Bal');
    const teamsPromise = axios.get('https://nachdijawani-melanie.com/api/Teams/?username=Sukhpal&lastname=Bal');

    Promise.all([eventsPromise, teamsPromise])
      .then(([eventsResponse, teamsResponse]) => {
       
        // Extract data from responses and update state
        setClassesAll({
          weekday_classes: eventsResponse.data.weekday_classes || [],
          weekend_classes: eventsResponse.data.weekend_classes || [],
        });

        setTeamsAll({
          weekday_teams: teamsResponse.data.weekday_teams || [],
          weekend_teams: teamsResponse.data.weekend_teams || [],
        });
      })
      .catch((error) => {
        console.error('Error fetching classes and teams:', error);
      });

    document.title = 'Instructor - Home';

    return () => {
      document.title = 'Original Title';
    };
  }, []);

  const storedUserType = localStorage.getItem('userType');

  if (!isLoggedIn || storedUserType !== 'Instructor') {
    navigate('/404');
    return null;
  }

  return (

    <div className="container-fluid position-relative" id={styles.main}>
      <div className="row">
        <Navbarin />
        <div className={styles.welcomeMessage}>
          {userName ? (
            <p className={styles.welcometext}>
              Welcome, <span className={styles.name}>{userName}</span>!
            </p>
          ) : null}
        </div>

        <div className={styles.buttonContainer}>

          <Link to="/notifications">
            <Button className={styles.customButton}>View Notifications</Button>
          </Link>
          <Link to="/comms">
            <Button className={styles.customButton}>Studnt Comms</Button>
          </Link>

        </div>

        <div className={styles.squareDivContainer}>
          <div className={styles.squareDiv1}>
            <h4>Schedule for {formattedDate}, {dayNames[dayOfWeek]}</h4>
            {(classes.length > 0 || teams.length > 0) ? (
              <ul className={styles.list}>
                {classes.map((cls) => (
                  <li key={cls.ID} className={styles['list-item']}>
                    <p>{cls.Class_Name_Batch}</p>
                    <p>{`${cls.Start_Time} - ${cls.End_Time}`}</p>
                    <button onClick={() => handleShowModal(cls)} id={styles.buttonmain}>Make Announcement</button>
                    <button onClick={() => handleShowCancelModal(cls)} id={styles.buttonmain}>Request Cancel</button>
                  </li>
                ))}
                {teams.map((team) => (
                  <li key={team.ID} className={styles['list-item']}>
                    <p>{team.Team_Name_Batch}</p>
                    <p>{`${team.Start_Time} - ${team.End_Time}`}</p>
                    <button onClick={() => handleShowModal(team)} id={styles.buttonmain}>Make Announcement</button>
                    <button onClick={() => handleShowCancelModal(team)} id={styles.buttonmain}>Request Cancel</button>

                  </li>
                ))}
              </ul>
            ) : (
              <p>No classes or teams available</p>
            )}
          </div>


          <div className={styles.squareDiv2}>
            <div className={styles.scrollContainer}>
              {(classesAll.weekday_classes.length > 0 || classesAll.weekend_classes.length > 0 ||
                teamsAll.weekday_teams.length > 0 || teamsAll.weekend_teams.length > 0) ? (
                <ul className={styles.list}>
                  {/* Map and render weekday classes */}
                  {classesAll.weekday_classes.map((cls) => (
                    <li key={cls.ID} className={styles['list-log']}>
                      <p>{cls.Class_Name_Batch}</p>
                      <button onClick={() => handleLogClass(cls, 'class')} className='btn btn-secondary' id={styles.buttonlog}>Log</button>
                    </li>
                  ))}

                  {/* Map and render weekend classes */}
                  {classesAll.weekend_classes.map((cls) => (
                    <li key={cls.ID} className={styles['list-log']}>
                      <p>{cls.Class_Name_Batch}</p>
                      <button onClick={() => handleLogClass(cls, 'class')} className='btn btn-secondary' id={styles.buttonlog}>Log</button>
                    </li>
                  ))}

                  {/* Map and render weekday teams */}
                  {teamsAll.weekday_teams.map((team) => (
                    <li key={team.ID} className={styles['list-log']}>
                      <p>{team.Team_Name_Batch}</p>
                      <button onClick={() => handleLogClass(team, 'team')} className='btn btn-secondary' id={styles.buttonlog}>Log</button>
                    </li>
                  ))}

                  {/* Map and render weekend teams */}
                  {teamsAll.weekend_teams.map((team) => (
                    <li key={team.ID} className={styles['list-log']}>
                      <p>{team.Team_Name_Batch}</p>
                      <button onClick={() => handleLogClass(team, 'team')} className='btn btn-secondary' id={styles.buttonlog}>Log</button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No classes or teams available</p>
              )}
            </div>
          </div>

        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Make Announcement for : {selectedClass && selectedClass.Class_Name_Batch}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="announcementTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={announcementTitle}
                onChange={(e) => setAnnouncementTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="announcementContent">
              <Form.Label>Content</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={announcementContent}
                onChange={(e) => setAnnouncementContent(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAnnouncementSubmit}>
              Submit Announcement
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showCancelModal} onHide={handleCloseCancelModal}>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Announcement for : {cancelRequestedClass}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="cancelReason">
              <Form.Label>Reason for Cancellation</Form.Label>
              <Form.Control
                type="text"
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="cancelContent">
              <Form.Label>Additional Information</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={cancelContent}
                onChange={(e) => setCancelContent(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCancelModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleCancelSubmit}>
              Submit Cancel Request
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showLogTypeModal} onHide={() => setShowLogTypeModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Select Log Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="logType">
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                value={logData.type}
                onChange={(e) => setLogData({ ...logData, type: e.target.value })}
              >
                <option value="regular">Regular Class</option>
                <option value="catchup">Catch-up</option>
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowLogTypeModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleLogTypeSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div>
  );
}

export default InstructorHome;
