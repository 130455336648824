import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import styles from './login.module.css'; // Reusing styles from login.module.css
import { Link } from 'react-router-dom';
import axios from 'axios';
import Login2Image from '../assets/img/studio.svg';

function ForgotPassword() {
  useEffect(() => {
    document.title = 'Forgot Password';

    // Optionally, you can reset the title when the component unmounts
    return () => {
      document.title = 'Original Title';
    };
  }, []);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Perform validation
      if (!validateName(firstName) || !validateName(lastName)) {
        alert('First name and last name should not contain spaces or special characters.');
        setLoading(false);
        return;
      }

      // Send email request to Django API
      const response = await axios.post('https://nachdijawani-melanie.com/api/forgot-password/', { firstName, lastName, email });
      // Display success message to the user
      alert('Password reset email sent successfully!');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert('Error sending password reset email. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Function to validate first and last name
  const validateName = (name) => {
    const regex = /^[^\s]+$/;
    return regex.test(name);
  };

  return (
    <div className="container-fluid position-relative">
      <div className="row">
        <div className={`col-6 col-md-2 position-absolute top-0 end-0 p-3 ${styles.zButton}`}>
          <Link to="/">
            <Button className={`w-100 ${styles.topButton}`}>Home</Button>
          </Link>
        </div>

        <div className={styles.imgcontainer}>
          {/* Use an appropriate image for the forgot password page */}
          <img src={Login2Image} alt="Forgot Password" />
        </div>
        <div className={styles.LoginForm}>
          <h1>Forgot Password</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="firstName" className="form-label">First Name</label>
              <input 
                type="text" 
                className="form-control" 
                id="firstName" 
                required 
                value={firstName} 
                onChange={(e) => setFirstName(e.target.value)} 
              />
            </div>
            <div className="mb-3">
              <label htmlFor="lastName" className="form-label">Last Name</label>
              <input 
                type="text" 
                className="form-control" 
                id="lastName" 
                required 
                value={lastName} 
                onChange={(e) => setLastName(e.target.value)} 
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email address</label>
              <input 
                type="email" 
                className="form-control" 
                id="email" 
                aria-describedby="emailHelp" 
                required 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
              />
            </div>
            <button type="submit" className={`btn btn-primary ${styles.formButton}`} disabled={loading}>
              {loading ? 'Sending...' : 'Submit'}
            </button>
          </form>
          <p id="designed">Designed and Developed By <a href='https://codedelic.com'>Codedelic</a></p>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
