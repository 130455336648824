import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './NotFound404.module.css'; // Import the CSS file

function NotFound404() {
    useEffect(() => {
        document.title = '404';
    
        // Optionally, you can reset the title when the component unmounts
        return () => {
          document.title = 'Original Title';
        };
      }, []);
      const clearLocalStorage = () => {
        localStorage.clear();
      };
    
      // Call the function to clear local storage
      clearLocalStorage();
    
  return (
    <div className={styles.container}>
      <h2>404 - Page Not Found</h2>
      <p>You must login to view this page</p>
      <Link to="/login">
        <button className={styles.btn404}>Click here to login</button>
      </Link>
    </div>
  );
}

export default NotFound404;
