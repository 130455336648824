import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import Navbarin from '../components/Navbarin';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import './Schedule.css';

// Define Event component
const Event = ({ event, onDragStart }) => {
  const getEventStyles = () => {
    const startMoment = moment(event.Start_Time, 'HH:mm:ss');
    const endMoment = moment(event.End_Time, 'HH:mm:ss');
    const durationInMinutes = endMoment.diff(startMoment, 'minutes');
    const intervalsSpanned = durationInMinutes / 30;
    

    return {
      gridColumn: `span 1`,
      gridRow: `span ${intervalsSpanned}`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '5%',
      borderRadius: '10px',
      margin: '4px',
      backgroundColor: '#FFDFD3', // Fixed color for the cards
      minHeight: '80px',
      cursor: 'move',
      color: '#fff', // White text color
      fontSize: '14px', // Increased font size
      fontWeight: 'bold', // Bold font weight
    };
  };

  return (
    <div
      draggable
      onDragStart={(e) => onDragStart(e, event)}
      style={getEventStyles()}
    >
      <p style={{ marginBottom: '4px' }}>{`${event.Days[0]}, ${event.Room}`}</p>
      <p>{moment(event.Start_Time, 'HH:mm:ss').format('hh:mm A')} - {moment(event.End_Time, 'HH:mm:ss').format('hh:mm A')}</p>
      <p style={{ marginBottom: '8px', fontWeight: 'bold' }}>{event.Class_Name_Batch}</p>
    </div>
  );
};

// Define Team component
const Team = ({ team, onDragStart }) => {
  const getTeamStyles = () => {
    const startMoment = moment(team.Start_Time, 'HH:mm:ss');
    const endMoment = moment(team.End_Time, 'HH:mm:ss');
    const durationInMinutes = endMoment.diff(startMoment, 'minutes');
    const intervalsSpanned = durationInMinutes / 30;

    return {
      gridColumn: `span 1`,
      gridRow: `span ${intervalsSpanned}`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '5%',
      borderRadius: '10px',
      margin: '4px',
      backgroundColor: '#C7DCA7', // Fixed color for the teams
      minHeight: '80px',
      cursor: 'move',
      color: '#fff', // White text color
      fontSize: '14px', // Increased font size
      fontWeight: 'bold', // Bold font weight
    };
  };

  return (
    <div
      draggable
      onDragStart={(e) => onDragStart(e, team)}
      style={getTeamStyles()}
    >
      <p style={{ marginBottom: '4px' }}>{`${team.Days[0]}, ${team.Room}`}</p>
      <p>{moment(team.Start_Time, 'HH:mm:ss').format('hh:mm A')} - {moment(team.End_Time, 'HH:mm:ss').format('hh:mm A')}</p>
      <p style={{ marginBottom: '8px', fontWeight: 'bold' }}>{team.Team_Name_Batch}</p>
    </div>
  );
};

// Define ScheduleTable component
const ScheduleTable = () => {
  const [events, setEvents] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTable, setSelectedTable] = useState('weekdays');
  const { isLoggedIn } = useAuth() || {};
  const navigate = useNavigate();

  useEffect(() => {
   
      document.title = 'Schedule';
  
    // Fetch data from the API for both events and teams
    const eventsPromise = axios.get('https://nachdijawani-melanie.com/api/Classes/?username=Sukhpal&lastname=Bal');
    const teamsPromise = axios.get('https://nachdijawani-melanie.com/api/Teams/?username=Sukhpal&lastname=Bal');

    Promise.all([eventsPromise, teamsPromise])
      .then(([eventsResponse, teamsResponse]) => {
        setEvents(eventsResponse.data.weekday_classes.concat(eventsResponse.data.weekend_classes));
        setTeams(teamsResponse.data.weekend_teams);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const onDragStart = (e, item) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(item));
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, day, startTime, endTime, room) => {
    e.preventDefault();
    const draggedItem = JSON.parse(e.dataTransfer.getData('text/plain'));
  
    if ('Class_Name_Batch' in draggedItem) {
      if (isWeekday(day)) {
        handleWeekdayClassDrop(draggedItem, day, startTime, endTime, room);
      } else {
        handleWeekendClassDrop(draggedItem, day, startTime, endTime, room);
      }
    } else if ('Team_Name_Batch' in draggedItem) {
      handleTeamDrop(draggedItem, day, startTime, endTime, room);
    }
  };
  
  const handleWeekdayClassDrop = (draggedItem, day, startTime, endTime, room) => {
    const updatedItems = [...events];
  
    // Remove the original item from the old position for weekday classes
    const draggedItemId = draggedItem.ID;
    const oldDay = draggedItem.Days && draggedItem.Days.length > 0 ? draggedItem.Days[0] : null;
    const oldRoom = draggedItem.Room;
  
    // Remove the original item from the old position for weekday classes
    if (oldDay && oldRoom) {
      updatedItems.splice(
        updatedItems.findIndex(
          (item) =>
            item.ID === draggedItemId &&
            item.Days[0] === oldDay &&
            item.Start_Time === draggedItem.Start_Time &&
            item.End_Time === draggedItem.End_Time &&
            item.Room === oldRoom
        ),
        1
      );
    }
  
    // Calculate the time difference between the original start time and the new start time
    const timeDiff = moment(startTime, 'hh:mm A').diff(moment(draggedItem.Start_Time, 'HH:mm:ss'), 'minutes');
  
    // Calculate the original duration of the class
    const originalDuration = moment(draggedItem.End_Time, 'HH:mm:ss').diff(
      moment(draggedItem.Start_Time, 'HH:mm:ss'),
      'minutes'
    );
  
    // Update the dragged item with the new information
    const newStartTime = moment(draggedItem.Start_Time, 'HH:mm:ss').add(timeDiff, 'minutes').format('HH:mm:ss');
    const newEndTime = moment(newStartTime, 'HH:mm:ss').add(originalDuration, 'minutes').format('HH:mm:ss');
  
    const updatedDraggedItem = {
      ...draggedItem,
      Room: room,
      Days: [day],
      Start_Time: newStartTime,
      End_Time: newEndTime,
    };
    
    axios.put(`https://nachdijawani-melanie.com/api/classesSchedule/${draggedItem.Class_Name_Batch}/`, {
      Class_Name_Batch: draggedItem.Class_Name_Batch,
      Room: room,
      Days: [day],
      Start_Time: newStartTime,
      End_Time: newEndTime,
    })
      .then(response => {
        // Handle successful update if needed
      })
      .catch(error => {
        console.error('Error updating data:', error);
      });
  
    updatedItems.push(updatedDraggedItem);
    setEvents(updatedItems);


  };
  
  const handleWeekendClassDrop = (draggedItem, day, startTime, endTime, room) => {
    const updatedItems = [...events];
  
    // Remove the original item from the old position for weekend classes
    const draggedItemId = draggedItem.ID;
    const oldDay = draggedItem.Days && draggedItem.Days.length > 0 ? draggedItem.Days[0] : null;
    const oldRoom = draggedItem.Room;
  
    // Remove the original item from the old position for weekend classes
    if (oldDay && oldRoom) {
      updatedItems.splice(
        updatedItems.findIndex(
          (item) =>
            item.ID === draggedItemId &&
            item.Days[0] === oldDay &&
            item.Start_Time === draggedItem.Start_Time &&
            item.End_Time === draggedItem.End_Time &&
            item.Room === oldRoom
        ),
        1
      );
    }
  
    // Update the dragged item with the new information
    const durationInMinutes = moment(draggedItem.End_Time, 'HH:mm:ss').diff(
      moment(draggedItem.Start_Time, 'HH:mm:ss'),
      'minutes'
    );
    const newEndTime = moment(startTime, 'hh:mm A').add(durationInMinutes, 'minutes').format('HH:mm:ss');
  
    const updatedDraggedItem = {
      ...draggedItem,
      Room: room,
      Days: [day],
      Start_Time: startTime,
      End_Time: newEndTime,
    };

    axios.put(`https://nachdijawani-melanie.com/api/classesSchedule/${draggedItem.Class_Name_Batch}/`, {
      Class_Name_Batch: draggedItem.Class_Name_Batch,
      Room: room,
      Days: [day],
      Start_Time: startTime,
      End_Time: newEndTime,
    })
      .then(response => {
        // Handle successful update if needed
      })
      .catch(error => {
        console.error('Error updating data:', error);
      });
  
  
    updatedItems.push(updatedDraggedItem);
    setEvents(updatedItems);
  };
  
  const handleTeamDrop = (draggedItem, day, startTime, endTime, room) => {
    const updatedItems = [...teams];
  
    // Remove the original item from the old position for teams
    if (draggedItem.Days && draggedItem.Days.length > 0) {
      const oldDay = draggedItem.Days[0];
      const oldRoom = draggedItem.Room;
      updatedItems.splice(
        updatedItems.findIndex(
          (item) =>
            item.Days[0] === oldDay &&
            item.Start_Time === draggedItem.Start_Time &&
            item.End_Time === draggedItem.End_Time &&
            item.Room === oldRoom
        ),
        1
      );
    }
  
    // Calculate the time difference between the original start time and the new start time
    const timeDiff = moment(startTime, 'hh:mm A').diff(moment(draggedItem.Start_Time, 'HH:mm:ss'), 'minutes');
  
    // Calculate the original duration of the team
    const originalDuration = moment(draggedItem.End_Time, 'HH:mm:ss').diff(
      moment(draggedItem.Start_Time, 'HH:mm:ss'),
      'minutes'
    );
  
    // Update the dragged team with the new information
    const newStartTime = moment(draggedItem.Start_Time, 'HH:mm:ss').add(timeDiff, 'minutes').format('HH:mm:ss');
    const newEndTime = moment(newStartTime, 'HH:mm:ss').add(originalDuration, 'minutes').format('HH:mm:ss');
  
    const updatedDraggedItem = {
      ...draggedItem,
      Room: room,
      Days: [day],
      Start_Time: newStartTime,
      End_Time: newEndTime,
    };
  
    axios.put(`https://nachdijawani-melanie.com/api/teamsSchedule/${draggedItem.Team_Name_Batch}/`, {
      Team_Name_Batch: draggedItem.Team_Name_Batch,
      Room: room,
      Days: [day],
      Start_Time: newStartTime,
      End_Time: newEndTime,
    })
      .then(response => {
        // Handle successful update if needed
      })
      .catch(error => {
        console.error('Error updating data:', error);
      });
  
    updatedItems.push(updatedDraggedItem);
    setTeams(updatedItems);
  };
  
  const isWeekday = (day) => {
    return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(day);
  };
  

  const renderTableHeader = () => {
    const headerDays = selectedTable === 'weekdays' ? ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'] : ['Saturday', 'Sunday'];
    const rooms = ['Room1', 'Room2']; // Add more rooms as needed

    return (
      <tr>
        <th style={{ minWidth: '120px', width: '200px', border: '1px solid #000', padding: '8px' }}>Time Intervals</th>
        {headerDays.map((day) => (
          rooms.map((room) => (
            <th key={`${day}-${room}`} style={{ border: '1px solid #000', padding: '8px' }}>{`${day}, ${room}`}</th>
          ))
        ))}
      </tr>
    );
  };
 

  const renderTableBody = () => {
    const headerDays = selectedTable === 'weekdays' ? ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'] : ['Saturday', 'Sunday'];
    const rooms = ['Room1', 'Room2']; // Add more rooms as needed

    return [
      '09:00 AM - 09:30 AM', '09:30 AM - 10:00 AM', '10:00 AM - 10:30 AM', '10:30 AM - 11:00 AM', '11:00 AM - 11:30 AM', '11:30 AM - 12:00 PM', '12:00 PM - 12:30 PM','12:30 PM - 01:00 PM', '01:00 PM - 01:30 PM', '01:30 PM - 02:00 PM', '02:00 PM - 02:30 PM', '02:30 PM - 03:00 PM', '03:00 PM - 03:30 PM', '03:30 PM - 04:00 PM', '04:00 PM - 04:30 PM', '04:30 PM - 05:00 PM', '05:00 PM - 05:30 PM', '05:30 PM - 06:00 PM', '06:00 PM - 06:30 PM', '06:30 PM - 07:00 PM', '08:00 PM - 08:30 PM'
    ].map((interval, index) => (
      <tr key={interval}>
        <td style={{ border: '1px solid #000', padding: '8px' }}>{interval}</td>
        {headerDays.map((day) => (
          rooms.map((room) => (
          <td
  key={`${day}-${room}`}
  style={{ border: '1px solid #000', padding: '8px', position: 'relative' }}
  onDragOver={(e) => onDragOver(e)}
  onDrop={(e) => onDrop(e, day, interval.split(' - ')[0], interval.split(' - ')[1], room)}
>

              {events
                .filter(
                  (item) =>
                    'Days' in item &&
                    item.Days.includes(day) &&
                    item.Room === room &&
                    moment(item.Start_Time, 'HH:mm:ss').isSameOrBefore(moment(interval.split(' - ')[0], 'hh:mm A')) &&
                    moment(item.End_Time, 'HH:mm:ss').isSameOrAfter(moment(interval.split(' - ')[1], 'hh:mm A'))
                )
                .map((item, index, array) => (
                  <Event
                    key={item.ID + index}
                    event={item}
                    onDragStart={onDragStart}
                  />
                ))}
             {teams
  .filter(
    (item) =>
      'Days' in item &&
      item.Days.includes(day) &&
      item.Room === room &&
      moment(item.Start_Time, 'HH:mm:ss').isSameOrBefore(moment(interval.split(' - ')[0], 'hh:mm A')) &&
      moment(item.End_Time, 'HH:mm:ss').isSameOrAfter(moment(interval.split(' - ')[1], 'hh:mm A'))
  )
  .map((item, index, array) => (
    <Team
      key={item.ID + index}
      team={item}
      onDragStart={onDragStart}
    />
  ))}
            </td>
          ))
        ))}
      </tr>
    ));
  };

  const renderTeams = () => {
    const headerDays = selectedTable === 'weekdays' ? ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'] : ['Saturday', 'Sunday'];
    const rooms = ['Room1', 'Room2']; // Add more rooms as needed
   

    return teams.map((team) => (
      <Team
        key={team.ID}
        team={team}
        onDragStart={onDragStart}
      />
    ));
  };


  return (
    <div className="container-fluid position-relative" id='main'>
    <div className="row">
      <Navbarin />
    <div style={{ overflow: 'scroll' }} className='container container-fluid' id='maindivv' >
      <h2 id='schedulehead'>Schedule</h2>
      <div style={{ marginBottom: '10px' }} id='buttons'>
        <button className='btn btn-primary' onClick={() => setSelectedTable('weekdays')}>Weekdays</button>
        <button className='btn btn-primary' onClick={() => setSelectedTable('weekends')}>Weekends</button>
      </div>
      <table className='table table-responsive' style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          {renderTableHeader()}
        </thead>  
        <tbody>
          {renderTableBody()}
        </tbody>
      </table>
     
    </div>
  </div>
  </div>
  );
};

export default ScheduleTable;