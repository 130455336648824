import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbarin from '../components/Navbarin';
import styles from './DholBookings.module.css';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';


function formatDate(dateString) {
  if (!dateString) return ''; // Handle empty or undefined dateString

  try {
    // Create a new Date object based on the input dateString
    const date = new Date(dateString);

    // Check if the created date object is valid
    if (isNaN(date.getTime())) {
      console.error('Invalid date input:', dateString);
      return ''; // Return empty string for invalid date
    }

    // Get user's time zone offset in minutes
    const userOffsetMinutes = date.getTimezoneOffset();

    // Convert UTC offset to hours (for display purposes)
    const userOffsetHours = userOffsetMinutes / 60;

    // Adjust date based on user's time zone offset
    const localDate = new Date(date.getTime() + (userOffsetMinutes * 60 * 1000));

    // Format the date for display using toLocaleDateString
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return localDate.toLocaleDateString('en-US', options);
  } catch (error) {
    console.error('Error formatting date:', error);
    return ''; // Return empty string if date formatting fails
  }
}



function getBorderStyle(status) {
  let borderColor;
  let textColor;

  switch (status) {
    case "Complete":
      borderColor = "#2e8540";
      textColor = "#2e8540";
      break;
    case "Cancelled":
      borderColor = "#e843cd";
      textColor = "#e843cd";
      break;
    default:
      borderColor = "transparent";
      textColor = "black";
  }

  return {
    border: `1px solid ${borderColor}`,
    color: textColor, // Set text color
    padding: '5px',
    borderRadius: '5px'
  };
}

function getBorderStylepayment(payment_status) {
  let borderColor;
  let textColor;

  switch (payment_status) {
    case "yes":
      borderColor = "#2e8540";
      textColor = "#2e8540";
      break;
    case "no":
      borderColor = "#e843cd";
      textColor = "#e843cd";
      break;
    default:
      borderColor = "transparent";
      textColor = "black";
  }

  return {
    border: `1px solid ${borderColor}`,
    color: textColor, // Set text color
    padding: '5px',
    borderRadius: '5px'
  };
}



function PerformanceBookingsPage() {
  const [bookings, setBookings] = useState([]);
  const [editBookingId, setEditBookingId] = useState(null);
  const [editedComment, setEditedComment] = useState('');
  const [editCommentId, setEditCommentId] = useState(null);
  const auth = useAuth() || {};
  const { isLoggedIn } = auth;
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Performance Bookings';
    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    try {
      const response = await axios.get('https://nachdijawani-melanie.com/api/get_all_performance_bookings/');
      const allBookings = response.data.data;
      allBookings.sort((a, b) => new Date(a.date_of_event) - new Date(b.date_of_event));
      setBookings(allBookings);
    } catch (error) {
      console.error('Error fetching performance bookings:', error);
    }
  };

  const handleCompleteClick = async (bookingId) => {
    try {
      await axios.post(`https://nachdijawani-melanie.com/api/mark_booking_complete/${bookingId}/`);
      updateBookingStatus(bookingId, 'Complete');
    } catch (error) {
      console.error('Error marking booking as complete:', error);
    }
  };

  const handleCancelClick = async (bookingId) => {
    try {
      await axios.post(`https://nachdijawani-melanie.com/api/mark_booking_cancelled/${bookingId}/`);
      updateBookingStatus(bookingId, 'Cancelled');
    } catch (error) {
      console.error('Error marking booking as cancelled:', error);
    }
  };

  const handleRevertClick = async (bookingId) => {
    try {
      await axios.post(`https://nachdijawani-melanie.com/api/mark_booking_revert/${bookingId}/`);
      updateBookingStatus(bookingId, null);
    } catch (error) {
      console.error('Error reverting booking status:', error);
    }
  };

  const updateBookingStatus = (bookingId, newStatus) => {
    const updatedBookings = bookings.map((booking) =>
      booking.id === bookingId ? { ...booking, status: newStatus } : booking
    );
    setBookings(updatedBookings);
  };

  const handleEditClick = (bookingId) => {
    setEditBookingId(bookingId);
  };

  const handleSaveClick = async (bookingId, updatedBooking) => {
    try {
      const response = await axios.post(`https://nachdijawani-melanie.com/api/update_booking/${bookingId}/`, updatedBooking);
      fetchBookings();
      setEditBookingId(null);
    } catch (error) {
      console.error('Error updating booking:', error);
    }
  };

  const handleCancelEditClick = () => {
    setEditBookingId(null);
  };

  const handleInputChange = (e, bookingId, field) => {
    const { value } = e.target;
    const updatedBookings = bookings.map((booking) => 
      booking.id === bookingId ? { ...booking, [field]: value } : booking
    );
    setBookings(updatedBookings);
  };

  const handleCommentEdit = (bookingId, comment) => {
    setEditCommentId(bookingId);
    setEditedComment(comment);
  };

  const handleCommentSave = async (bookingId, updatedComment) => {
    try {
      await axios.post(`https://nachdijawani-melanie.com/api/update_booking_comment/${bookingId}/`, { comment: updatedComment });
      fetchBookings();
      setEditCommentId(null);
    } catch (error) {
      console.error('Error updating comment:', error);
    }
  };

  const handleCancelCommentEditClick = () => {
    setEditCommentId(null);
    setEditedComment('');
  };

  const handleCommentChange = (event) => {
    setEditedComment(event.target.value);
  };

  if (!isLoggedIn || localStorage.getItem('userType') !== 'admin') {
    navigate('/404');
    return null;
  }

  return (
    <div className={`container-fluid position-relative ${styles.main}`}>
      <div className="row">
        <Navbarin />
        <div className={styles.dholBookingsList}>
          <h1 className={styles.heading}>View All Performance Bookings</h1>
          <table className={`table table-bordered table-light table-responsive ${styles.table}`}>
            <thead>
              <tr>
                <th>Email</th>
                <th>Full Name</th>
                <th>Phone Number</th>
                <th>Banquet Hall</th>
                <th>Event Address</th>
                <th>Event Date</th>
                <th>Performance Time</th>
                <th>Performance Booked</th>
                <th>Performers</th>
                <th>Occasion</th>
                <th>Registration Date Time</th>
                <th>Payment Made</th>
                <th>Comments</th>
                <th>Status</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking) => (
                <tr key={booking.id}>
                  <td>{editBookingId === booking.id ? <input type="text" value={booking.email} onChange={(e) => handleInputChange(e, booking.id, 'email')} /> : booking.email}</td>
                  <td>{editBookingId === booking.id ? <input type="text" value={booking.full_name} onChange={(e) => handleInputChange(e, booking.id, 'full_name')} /> : booking.full_name}</td>
                  <td>{editBookingId === booking.id ? <input type="text" value={booking.phone_number} onChange={(e) => handleInputChange(e, booking.id, 'phone_number')} /> : booking.phone_number}</td>
                  <td>{editBookingId === booking.id ? <input type="text" value={booking.banquet_hall_name} onChange={(e) => handleInputChange(e, booking.id, 'banquet_hall_name')} /> : booking.banquet_hall_name}</td>
                  <td>{editBookingId === booking.id ? <input type="text" value={booking.banquet_hall_address} onChange={(e) => handleInputChange(e, booking.id, 'banquet_hall_address')} /> : booking.banquet_hall_address}</td>
                  <td>
                    {editBookingId === booking.id ? (
                      <input
                        type="date"
                        value={booking.date_of_event} // Use the raw date string value for input
                        onChange={(e) => handleInputChange(e, booking.id, 'date_of_event')}
                      />
                    ) : (
                      formatDate(booking.date_of_event) // Display formatted date when not editing
                    )}
                  </td>
                  <td>{editBookingId === booking.id ? <input type="text" value={booking.performance_time} onChange={(e) => handleInputChange(e, booking.id, 'performance_time')} /> : booking.performance_time}</td>
                  <td>{editBookingId === booking.id ? <input type="text" value={booking.performance_booked} onChange={(e) => handleInputChange(e, booking.id, 'performance_booked')} /> : booking.performance_booked}</td>
                  <td>{editBookingId === booking.id ? <input type="text" value={booking.performers_count} onChange={(e) => handleInputChange(e, booking.id, 'performers_count')} /> : booking.performers_count}</td>
                  <td>{editBookingId === booking.id ? <input type="text" value={booking.occasion} onChange={(e) => handleInputChange(e, booking.id, 'occasion')} /> : booking.occasion}</td>
                  <td>{formatDate(booking.timestamp)}</td>
                  <td>
                    {editBookingId === booking.id ? (
                      <select value={booking.payment_status} onChange={(e) => handleInputChange(e, booking.id, 'payment_status')}>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    ) : (
                      <span style={getBorderStylepayment(booking.payment_status)}>{booking.payment_status}</span>
                    )}
                  </td>
                  <td>
                    {editCommentId === booking.id ? (
                      <div>
                        <input
                          type="text"
                          value={editedComment}
                          onChange={handleCommentChange}
                          autoFocus
                        />
                        <button onClick={() => handleCommentSave(booking.id, editedComment)} className="btn btn-success mr-2">Save</button>
                        <button onClick={handleCancelCommentEditClick} className="btn btn-danger">Cancel</button>
                      </div>
                    ) : (
                      <div>
                        {booking.comments || '-'}
                        <button onClick={() => handleCommentEdit(booking.id, booking.comments)} className="btn btn-primary">Edit Comment</button>
                      </div>
                    )}
                  </td>
                  <td>
                    {booking.status ? (
                      <span style={getBorderStyle(booking.status)}>{booking.status}</span>
                    ) : (
                      <>
                        <button onClick={() => handleCompleteClick(booking.id)} className="btn btn-success mr-2">Complete</button>
                        <button onClick={() => handleCancelClick(booking.id)} className="btn btn-danger">Cancel</button>
                      </>
                    )}
                    {booking.status && (
                      <button onClick={() => handleRevertClick(booking.id)} style={{ marginTop: '2%' }} className="btn btn-info ml-2">Revert</button>
                    )}
                  </td>
                  <td>
                    {editBookingId === booking.id ? (
                      <>
                        <button onClick={() => handleSaveClick(booking.id, booking)} className="btn btn-primary mr-2">Save</button>
                        <button onClick={handleCancelEditClick} className="btn btn-secondary">Cancel</button>
                      </>
                    ) : (
                      <button onClick={() => handleEditClick(booking.id)} className="btn btn-warning">Edit</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PerformanceBookingsPage;
