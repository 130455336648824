import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Navbarin from '../components/Navbarin';
import axios from 'axios';
import styles from './teamsinside.module.css';
import { useAuth } from '../components/AuthContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';

function getBorderStyle(paymentStatus) {
  let borderColor;
  let textColor;

  if (paymentStatus === "Success") {
    borderColor = "#2e8540";
    textColor = "#2e8540"; // Match text color with border color
  } else if (paymentStatus === "Pending") {
    borderColor = "#f39c11";
    textColor = "#f39c11"; // Match text color with border color
  } else if (paymentStatus === "declined" || paymentStatus === "Declined") {
    borderColor = "#e843cd";
    textColor = "#e843cd"; // Match text color with border color
  } else {
    borderColor = "transparent";
    textColor = "black"; // Default text color
  }

  return {
    border: `1px solid ${borderColor}`,
    color: textColor, // Set text color
    padding: '5px',
    borderRadius: '5px'
  };
}


function Teamsinside() {

  const categories = [
    'Select a category',
    'Bhangra-5-10-boys',
    'Bhangra-5-10-girls',
    'Bhangra-5-10-coed',
    'Bhangra-10-14-boys',
    'Bhangra-10-14-girls',
    'Bhangra-10-14-coed',
    'Bhangra-14+boys',
    'Bhangra 14+girls', // Corrected: Removed the dash
    'Bhangra-14+coed',
    'Dhol',
    'Kids-Gidda',
    'Ladies-Gidda',
  ];

  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };


  const { teamName } = useParams();
  const { isLoggedIn } = useAuth() || {};
  const navigate = useNavigate();
  const [teamData, setTeamData] = useState(null);
  const [students, setStudents] = useState([]);
  const [dressData, setDressData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
  const [announcementTitle, setAnnouncementTitle] = useState('');
  const [announcementContent, setAnnouncementContent] = useState('');
  const [selectedStudentForAnnouncement, setSelectedStudentForAnnouncement] = useState(null);
  const [showAttendanceModal, setShowAttendanceModal] = useState(false);
  const [selectedStudentAttendance, setSelectedStudentAttendance] = useState(null);
  const [selectedStudentAttendanceDetails, setSelectedStudentAttendanceDetails] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [showCashHistoryModal, setShowCashHistoryModal] = useState(false);
  const [selectedStudentCashHistory, setSelectedStudentCashHistory] = useState(null);
  const [cashHistoryData, setCashHistoryData] = useState([]);
  const [showCashPaymentModal, setShowCashPaymentModal] = useState(false); // Define this variable
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedStudentForCashPayment, setSelectedStudentForCashPayment] = useState(null); // Define this variable
  const [showEditTeamModal, setShowEditTeamModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newTeamName, setNewTeamName] = useState('');
  const [newInstructor, setNewInstructor] = useState('');
  const [backupInstructor, setBackupInstructor] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [instructors, setInstructors] = useState([]);
  const [amount, setAmount] = useState('');
  const [paymentType, setPaymentType] = useState('cash');
  const [cardStatus, setCardStatus] = useState('success');


  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true);
  };

  // Function to handle closing the delete team modal
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // Function to handle deleting the team
  const handleDeleteTeam = () => {
    // Add your logic to delete the team here
    axios.delete(`https://nachdijawani-melanie.com/api/deleteteam/${teamName}/`)
      .then((response) => {


        // Close the modal after deletion
        handleCloseDeleteModal();

        // Redirect the user or perform any other action after deletion
      })
      .catch((error) => {
        // console.error('Error deleting team', error);
      });
  };

  // Function to handle opening the edit team modal
  const handleOpenEditTeamModal = () => {
    setShowEditTeamModal(true);
    setNewTeamName(teamData ? teamData.Team_Name_Batch : '');
    setNewInstructor(teamData ? teamData.instructor : '');
    // Add other properties you want to edit
  };

  // Function to handle closing the edit team modal
  const handleCloseEditTeamModal = () => {
    setShowEditTeamModal(false);
    setNewTeamName('');
    setNewInstructor('');
    // Reset other properties
  };

  // Function to handle submitting the edited team
  const handleEditTeamSubmit = () => {
    // Add your logic to update the team information here
    const updatedTeamData = {
      new_team_name: newTeamName,
      instructor_name: newInstructor,
      new_category: newCategory,
      backup_instructor: backupInstructor
      // Add other properties you want to update
    };

    axios.put(`https://nachdijawani-melanie.com/api/update_team_name/${teamName}/`, updatedTeamData)
      .then((response) => {

        fetchData(); // Assuming fetchData() is a function that fetches data
        handleCloseEditTeamModal();
        navigate('/teams');

      })
      .catch((error) => {
        console.error('Error updating team', error);
      });
  };



  useEffect(() => {
    document.title = 'Team';



    axios.get(`https://nachdijawani-melanie.com/api/instructor-names/`)
      .then(response => {

        setInstructors(response.data);

      })
      .catch((error) => {
        console.error('Error fetching students', error);
      });


    axios.get(`https://nachdijawani-melanie.com/api/teams/${teamName}/`)
      .then((response) => {
        setTeamData(response.data);


        axios
          .get(`https://nachdijawani-melanie.com/api/studentsbyteam/${response.data.Team_Name_Batch}/`)
          .then((studentsResponse) => {
            const sortedStudents = studentsResponse.data.sort((a, b) => {
              // Extracting first names
              const firstNameA = a.First_Name.toLowerCase();
              const firstNameB = b.First_Name.toLowerCase();
              // Extracting last names
              const lastNameA = a.Last_Name.toLowerCase();
              const lastNameB = b.Last_Name.toLowerCase();

              // Compare first names
              if (firstNameA < firstNameB) {
                return -1;
              }
              if (firstNameA > firstNameB) {
                return 1;
              }

              // If first names are the same, compare last names
              if (lastNameA < lastNameB) {
                return -1;
              }
              if (lastNameA > lastNameB) {
                return 1;
              }

              return 0; // Names are equal
            });

            setStudents(sortedStudents);
          })
          .catch((error) => {
            console.error('Error fetching students', error);
          });


        axios.get(`https://nachdijawani-melanie.com/api/dress-by-team/${teamName}/`)
          .then((dressResponse) => {
            setDressData(dressResponse.data);
          })
          .catch((dressError) => {
            console.error('Error fetching dress data', dressError);
          });

        axios.get(`https://nachdijawani-melanie.com/api/teamattendance/${response.data.ID}/`)
          .then((attendanceResponse) => {
            setAttendanceData(attendanceResponse.data);

          })
          .catch((attendanceError) => {
            console.error('Error fetching attendance data', attendanceError);
          });

        // axios.get(`https://nachdijawani-melanie.com/api/payment-details/${teamData.ID}/`)
        //   .then((paymentDetailsResponse) => {
        //     setPaymentDetails(paymentDetailsResponse.data);
        //   })
        //   .catch((paymentDetailsError) => {
        //     console.error('Error fetching payment details', paymentDetailsError);
        //   });


      })
      .catch((error) => {
        console.error('Error fetching team data', error);
      });



    // Fetch payment details for the team
    axios
      .post('https://nachdijawani-melanie.com/api/get_payment_data_by_type/', {
        name: teamName,
        type: 'team',  // Added type variable with value 'team'
      })
      .then((paymentDetailsResponse) => {
        setPaymentDetails(paymentDetailsResponse.data.payment_data);
      })
      .catch((paymentDetailsError) => {
        console.error('Error fetching payment details', paymentDetailsError);
      });
    axios.post('https://nachdijawani-melanie.com/api/check-and-update-student-status/', {
      type: 'team',
      name: teamName,
    })
      .then((response) => {
        // Handle the response if needed

      })
      .catch((error) => {
        console.error('Error calling check-and-update-student-status API', error);
      });


  }, [teamName]);

  useEffect(() => {
    // Set the interval to check every minute
    const interval = setInterval(() => {
      fetchData();
      const result = isWithinWindow();
      // Do something with the result, for example, update UI or take further actions based on the value

    }, 60000); // 60000 milliseconds = 1 minute

    // Optionally, you can clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    students.forEach((student) => {
      const dressInfo = dressData.find((dress) => dress.student_id === student.ID);

      if (dressInfo && isToday(new Date(dressInfo.Next_Dress_Payment_Date))) {
        axios
          .post('https://nachdijawani-melanie.com/api/send-dress-payment-due-notification/', {
            studentId: student.ID,
          })
          .then((notificationResponse) => {

          })
          .catch((notificationError) => {
            console.error('Error sending notification', notificationError);
          });
      }
    });
  }, [students, dressData]);

  if (!isLoggedIn) {
    navigate('/404');
  }


  const isToday = (someDate) => {
    const today = new Date();
    const currentDate = new Date(someDate);

    today.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    return currentDate.getTime() === today.getTime();
  };

  const markAttendance = (studentID, present) => {
    // Disable present buttons for the clicked student
    var presentButtons = document.querySelectorAll(`.present-${studentID}`);
    presentButtons.forEach(button => {
      button.disabled = true;
    });

    // Disable absent buttons for the clicked student
    var absentButtons = document.querySelectorAll(`.absent-${studentID}`);
    absentButtons.forEach(button => {
      button.disabled = true;
    });

    // Define the timestamp based on the user type, team start time, team end time, and whether today is the day of the team
    let timestamp;
    const userType = localStorage.getItem('userType');
    const todayIsTeamDay = teamData.Days.includes(new Date().toLocaleDateString('en-US', { weekday: 'long' }));

    if (todayIsTeamDay && userType !== 'admin') {
      // If today is the day of the team and the user is not an admin
      const currentTime = new Date();
      const teamStartTime = new Date(`${new Date().toDateString()} ${teamData.Start_Time}`);
      const teamEndTime = new Date(`${new Date().toDateString()} ${teamData.End_Time}`);

      if (currentTime >= teamStartTime && currentTime < teamEndTime) {
        // If the current time is within the window (from team start time to team end time), set timestamp to current time
        timestamp = currentTime.toLocaleString();
      } else {
        // If the current time is outside the window, set timestamp to team start time
        timestamp = teamStartTime.toLocaleString();
      }
    } else {
      // If today is not the day of the team or the user is an admin, set timestamp to team start time
      const currentDateStr = new Date().toISOString().split('T')[0]; // Get current date in "YYYY-MM-DD" format
      const teamStartDateTimeStr = `${currentDateStr}T${teamData.Start_Time}`;
      const teamStartTime = new Date(teamStartDateTimeStr); // Convert team start time string to Date object
      timestamp = teamStartTime.toLocaleString(); // Set timestamp to team start time
    }

    const data = {
      student_ID: studentID,
      team_ID: teamData.ID, // Assuming teamData contains team ID
      present: present,
      timestamp: timestamp,
    };

    axios.post('https://nachdijawani-melanie.com/api/markTeamAttendance/', data)
      .then((response) => {
        fetchData(); // Assuming fetchData() is a function that fetches data
      })
      .catch((error) => {
        console.error('Error marking attendance', error);
      });
  };



  const markExtraAttendance = (studentID, present) => {

    // Disable present buttons for the clicked student
    var presentButtons = document.querySelectorAll(`.extrapresent-${studentID}`);
    presentButtons.forEach(button => {
      button.disabled = true;
    });

    // Disable absent buttons for the clicked student
    var absentButtons = document.querySelectorAll(`.extraabsent-${studentID}`);
    absentButtons.forEach(button => {
      button.disabled = true;
    });
    const localTimestamp = new Date().toLocaleString();
    const data = {
      student_ID: studentID,
      team_ID: teamData.ID,
      present: present,
      extra_classes: true,
      timestamp: localTimestamp,

    };

    axios.post('https://nachdijawani-melanie.com/api/markTeamAttendance/', data)
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        console.error('Error marking attendance', error);
      });
  };

  const fetchData = () => {
    axios.get(`https://nachdijawani-melanie.com/api/teams/${teamName}/`)
      .then((response) => {
        setTeamData(response.data);

        axios
          .get(`https://nachdijawani-melanie.com/api/studentsbyteam/${response.data.Team_Name_Batch}/`)
          .then((studentsResponse) => {
            const sortedStudents = studentsResponse.data.sort((a, b) => {
              // Extracting first names
              const firstNameA = a.First_Name.toLowerCase();
              const firstNameB = b.First_Name.toLowerCase();
              // Extracting last names
              const lastNameA = a.Last_Name.toLowerCase();
              const lastNameB = b.Last_Name.toLowerCase();

              // Compare first names
              if (firstNameA < firstNameB) {
                return -1;
              }
              if (firstNameA > firstNameB) {
                return 1;
              }

              // If first names are the same, compare last names
              if (lastNameA < lastNameB) {
                return -1;
              }
              if (lastNameA > lastNameB) {
                return 1;
              }

              return 0; // Names are equal
            });

            setStudents(sortedStudents);
          })
          .catch((error) => {
            console.error('Error fetching students', error);
          });


        axios.get(`https://nachdijawani-melanie.com/api/dress-by-team/${teamName}/`)
          .then((dressResponse) => {
            setDressData(dressResponse.data);
          })
          .catch((dressError) => {
            console.error('Error fetching dress data', dressError);
          });

        axios.get(`https://nachdijawani-melanie.com/api/teamattendance/${response.data.ID}/`)
          .then((attendanceResponse) => {
            setAttendanceData(attendanceResponse.data);
          })
          .catch((attendanceError) => {
            console.error('Error fetching attendance data', attendanceError);
          });

        axios
          .post('https://nachdijawani-melanie.com/api/get_payment_data_by_type/', {
            name: teamName,
            type: 'team',  // Added type variable with value 'team'
          })
          .then((paymentDetailsResponse) => {
            setPaymentDetails(paymentDetailsResponse.data.payment_data);
          })
          .catch((paymentDetailsError) => {
            console.error('Error fetching payment details', paymentDetailsError);
          });



      })
      .catch((error) => {
        console.error('Error fetching team data', error);
      });
  };

  const userType = localStorage.getItem('userType');
  const canViewTable = userType === 'Instructor' || userType === 'admin';

  const handleOpenAnnouncementModal = (student) => {
    setSelectedStudentForAnnouncement(student);
    setShowAnnouncementModal(true);
  };

  const handleCloseAnnouncementModal = () => {
    setShowAnnouncementModal(false);
    setAnnouncementTitle('');
    setAnnouncementContent('');
    setSelectedStudentForAnnouncement(null);
  };

  const handleAnnouncementSubmit = () => {
    if (selectedStudentForAnnouncement) {
      const instructorNameParts = teamData.instructor.split(' ');
      const firstName = instructorNameParts[0];
      const lastName = localStorage.getItem('lastName');

      const payload = {
        Title: announcementTitle,
        Content: announcementContent,
        team_name: teamName,
        team_id: teamData.ID,
        instructor_name: firstName,
        last_name: lastName, // Include lastName in the payload
        type: 'announcement',
        student_id: selectedStudentForAnnouncement.ID,
      };

      axios
        .post('https://nachdijawani-melanie.com/api/announcement/', payload)
        .then((response) => {
          if (response.data.message === 'sent successfully') {

            handleCloseAnnouncementModal();
          } else {
            console.error('Error sending announcement:', response.data);
          }
        })
        .catch((error) => {
          console.error('Error sending announcement:', error);
        });
    }
  };

  const handleOpenAttendanceModal = (student) => {
    setSelectedStudentAttendance(student);
    setShowAttendanceModal(true);

    // Fetch attendance details for the selected student
    axios
      .get(`https://nachdijawani-melanie.com/api/teamattendance/${teamData.ID}/${student.ID}/`)
      .then((attendanceDetailsResponse) => {
        setSelectedStudentAttendanceDetails(attendanceDetailsResponse.data);
      })
      .catch((attendanceDetailsError) => {
        console.error('Error fetching attendance details', attendanceDetailsError);
      });
  };

  const handleCloseAttendanceModal = () => {
    setShowAttendanceModal(false);
    setSelectedStudentAttendance(null);
    setSelectedStudentAttendanceDetails(null);
  };

  const handlePayment = (studentID) => {
    const data = {
      student_ID: studentID,
      team_ID: teamData.ID,
    };

    axios.post('https://nachdijawani-melanie.com/api/make-payment/', data)
      .then((response) => {
        // Handle success, e.g., show a success message

      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error('Error making payment', error);
      });
  };


  const handleSetCashMethod = (student) => {
    setShowCashPaymentModal(true);
    setSelectedStudentForCashPayment(student); // Set the selected student
    setStartDate(''); // Set initial values as empty
    setEndDate('');
  };

  const handleCloseCashPaymentModal = () => {
    setShowCashPaymentModal(false);
    setStartDate(''); // Reset values when the modal is closed
    setEndDate('');
  };

  const handleViewCashHistory = (student) => {
    setSelectedStudentCashHistory(student);

    // Fetch cash history data for the selected student
    axios
      .get(`https://nachdijawani-melanie.com/api/payment-updates/${student.First_Name}/${student.Last_Name}/${student.Email}/`)
      .then((cashHistoryResponse) => {
        setCashHistoryData(cashHistoryResponse.data);
      })
      .catch((cashHistoryError) => {
        console.error('Error fetching cash history data', cashHistoryError);
      });

    setShowCashHistoryModal(true);
  };

  const handleCloseCashHistoryModal = () => {
    setShowCashHistoryModal(false);
    setSelectedStudentCashHistory(null);
    setCashHistoryData([]);
  };

  const handleCashPaymentSubmit = () => {
    if (selectedStudentForCashPayment) {
      const data = {
        first_name: selectedStudentForCashPayment.First_Name,
        last_name: selectedStudentForCashPayment.Last_Name,
        phone_number: selectedStudentForCashPayment.phoneNumber,
        email: selectedStudentForCashPayment.Email,
        new_next_recurring_date: startDate,
        new_payment_date: endDate,
        amount: amount,
        paymentType: paymentType,
        cardStatus: cardStatus
      };

      axios
        .post('https://nachdijawani-melanie.com/api/set-cash-method/', data)
        .then((response) => {

          handleCloseCashPaymentModal();

          // Call the students API again to get updated student information
          fetchData(); // Assuming fetchData() is a function that fetches data
        })
        .catch((error) => {
          console.error('Error setting cash method:', error);
        });
    } else {
      console.warn('Invalid student data.');
    }
  };

  const isWithinWindow = () => {
    const currentTime = new Date();
    const classStartTimeStr = teamData.Start_Time;

    // Combine class start time with the current date
    const currentDateStr = currentTime.toISOString().split('T')[0]; // Get current date in "YYYY-MM-DD" format
    const classStartDateTimeStr = `${currentDateStr}T${classStartTimeStr}`;

    const classStartTime = new Date(classStartDateTimeStr);

    // Check if classStartTime is a valid date
    if (isNaN(classStartTime.getTime())) {
      console.error('Invalid class start time:', teamData.Start_Time);
      return false; // or handle it appropriately
    }



    const timeDiffInMinutes = Math.floor((currentTime - classStartTime) / (1000 * 60));


    // Check if today is the day of the team
    const todayIsTeamDay = teamData.Days.includes(currentTime.toLocaleDateString('en-US', { weekday: 'long' }));

    // Check if it's within the window (30 minutes after team start) or after 12 am next day
    const isAdmin = localStorage.getItem('userType') === 'admin';

    // If today is the day of the team, and the user is an admin or instructor,
    // and the time difference is within the window, return true
    if (isAdmin || (todayIsTeamDay && (0 <= timeDiffInMinutes && timeDiffInMinutes <= 30))) {
      // console.log('User is an admin or instructor. Allow access.');
      return true;
    }

    // console.log('Team has already started or today is not the day of the team.');
    return false;
  };


  const handleExtraTeamsButtonClick = (studentID) => {
    const localTimestamp = new Date().toLocaleString();
    // Make an HTTP request to your Django backend
    const requestData = {
      student_ID: studentID,
      team_ID: teamData.ID,
      present: 'present',
      extra_classes: true,
      timestamp: localTimestamp,
    };

    axios.post('https://nachdijawani-melanie.com/api/markTeamAttendance/', requestData)
      .then(response => {
        // Handle success, e.g., update UI or take further actions based on the response
        fetchData();
      })
      .catch(error => {
        // Handle error, e.g., display an error message to the user
        console.error('Error:', error);
      });
  };

  const handleWelcome = (firstName, lastName) => {
    // Replace 'your-api-endpoint' with the actual endpoint of your Django API
    const apiUrl = 'https://nachdijawani-melanie.com/api/welcome/';

    // Prepare the data to be sent in the POST request
    const postData = {
      firstName: firstName,
      lastName: lastName,
    };

    // Send the POST request using Axios
    axios.post(apiUrl, postData)
      .then(response => {
        // Handle the successful response, if needed
        window.alert('Email sent')
        // console.log(response.data);
      })
      .catch(error => {
        // Handle the error, if needed
        console.error(error);
      });
  };

  const handleDeleteRecord = (record, selectedStudentCashHistory) => {
    // Extracting required data from the record object
    const { First_Name, Last_Name } = selectedStudentCashHistory
    const { new_next_recurring_date, new_payment_date, amount } = record;

    // Prepare data to be sent in the request body
    const requestData = {
      first_name: First_Name,
      last_name: Last_Name,
      next_payment_date: new_next_recurring_date,
      new_payment_date: new_payment_date,
      amount: amount
    };

    // Assuming your Django API endpoint is '/api/delete_cash_record/'
    const apiUrl = 'https://nachdijawani-melanie.com/api/delete_payment_record/';

    // Send POST request to the Django API
    axios.post(apiUrl, requestData)
      .then(response => {
        // Handle successful response from the server
        // console.log('Record deleted successfully:', response.data);
        setShowCashHistoryModal(false);
        setSelectedStudentCashHistory(null);
        setCashHistoryData([]);
        axios
          .post('https://nachdijawani-melanie.com/api/get_payment_data_by_type/', {
            name: teamName,
            type: 'team',
          })
          .then((paymentDetailsResponse) => {
            setPaymentDetails(paymentDetailsResponse.data.payment_data);
          })
          .catch((paymentDetailsError) => {
            console.error('Error fetching payment details', paymentDetailsError);
          });
        // Assuming you want to update the UI or perform any other action after successful deletion
        // For example, you may want to refresh the cash history data
        // You can call a function here to update the UI accordingly
      })
      .catch(error => {
        // Handle error case
        console.error('Error deleting record:', error);
        // You may want to show an error message to the user or handle the error in any other way
      });
  };



  return (
    <div className="container-fluid position-relative" id={styles.main}>
      <div className="row">
        <Navbarin />
        <h1 className={styles.heading}>{teamName}</h1>
        {userType === 'admin' && (
          <div className={styles.adminButtons}>
            {/* Button to Open Delete Team Modal */}
            <Button variant="danger" onClick={handleOpenDeleteModal}>
              Delete Team
            </Button>

            {/* Button to Open Edit Team Modal */}
            <Button variant="primary" onClick={handleOpenEditTeamModal}>
              Edit Team
            </Button>
          </div>
        )}
        <div className={styles.container}>
          <div className={styles.navCard}>
            <ul>
              <li>
                <h4>
                  <a href={`/team/${teamName}`}>Home</a>
                </h4>
              </li>
              {canViewTable && (
                <li>
                  <h4>
                    <a href={`/team-attendance/${teamName}`}>Attendance</a>
                  </h4>
                </li>
              )}

              <li>
                <h4>
                  <a href={`/team-announcements/${teamName}`}>Announcements</a>
                </h4>
              </li>


              <li>
                <h4>
                  <a href={`/team-video/${teamName}`}>Videos</a>
                </h4>
              </li>
            </ul>

          </div>

          <div className={styles.gridContainer}>
            <div className={styles.card}>
              <h4>
                {teamData &&
                  `${teamData.Days.join(', ')} ${teamData.Start_Time} - ${teamData.End_Time}`}
              </h4>
            </div>

            <div className={styles.card}>
              <h4>Coach: {teamData && teamData.instructor || 'Not Assigned'}</h4>
            </div>

            <div className={styles.card}>
              <h4>Count: {teamData && teamData.students_count}</h4>
            </div>

            <div className={styles.card}>
              <h4> Category: {teamData && teamData.Category}</h4>
            </div>
          </div>
        </div>
        {canViewTable && (
          <table className="table table-light table-bordered" id={styles.tablestudent}>
            <thead id={styles.thteamsclasses}>
              <tr>
                <th>Student ID</th>
                <th>Name</th>
                <th>Image</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Last Dress Payment Date</th>
                <th>Next Dress Payment Date</th>
                <th>Rate Amount</th>
                <th>Attendance</th>
                <th>Actions</th>
                <th>Payment Tracker</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => {
                const dressInfo = dressData.find((dress) => dress.dress_info && dress.student_id === student.ID);
                const studentAttendance = attendanceData.find((item) => item.Student_ID === student.ID);
                const paymentDetail = paymentDetails.find(
                  (payment) => payment.first_name === student.First_Name && payment.last_name === student.Last_Name
                );

                let paymentTrackerStatus;
                if (paymentDetail) {
                  const nextRecurringDate = new Date(paymentDetail.next_recurring_date);
                  const currentDate = new Date();

                  if ((currentDate <= nextRecurringDate || currentDate.getTime() === nextRecurringDate.getTime()) && student.status === 'active') {
                    paymentTrackerStatus = 'Success';
                    // console.log('yes');
                  } else if (currentDate > nextRecurringDate && student.paymentMethod === null) {
                    paymentTrackerStatus = (
                      <Button variant="primary" onClick={() => handleSetCashMethod(student)} id={styles.paybutton}>
                        Update Payment
                      </Button>
                    );
                  } else if (student.status === 'declined') {
                    paymentTrackerStatus = 'Declined';
                  } else if (student.status === 'Hold') {
                    paymentTrackerStatus = 'On Hold';
                  } else if (currentDate > nextRecurringDate && student.status === 'active') {
                    paymentTrackerStatus = 'Pending';
                  }
                } else {
                  // Display a button saying 'Set Cash Payment'
                  paymentTrackerStatus = (
                    <Button variant="primary" onClick={() => handleSetCashMethod(student)} id={styles.paybutton}>
                      Set Payment
                    </Button>
                  );
                }

                return (
                  <tr
                    key={student.ID}
                    className={
                      dressInfo && isToday(new Date(dressInfo.Next_Dress_Payment_Date))
                        ? styles.todayBackground
                        : ''
                    }
                  >
                    <td>{student.ID}</td>
                    <td>
                      <Link
                        to={`/student-details/${encodeURIComponent(
                          `${student.First_Name} ${student.Last_Name}`
                        )}/${encodeURIComponent(student.Email)}`}
                        className={styles.Link}
                      >
                        {student.First_Name} {student.Last_Name}
                      </Link>
                    </td>
                    <td>
                      {student.Image_URL && (
                        <img
                          src={student.Image_URL}
                          alt={`${student.First_Name} ${student.Last_Name}`}
                          style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                        />
                      )}
                    </td>
                    <td> <a href={`mailto:${student.Email}`}>{student.Email}</a></td>
                    <td><a href={`tel:${student.phoneNumber}`}>{student.phoneNumber}</a></td>
                    <td>
                      {dressInfo && dressInfo.dress_info && dressInfo.dress_info.Last_Dress_Payment_Date ? (
                        new Date(dressInfo.dress_info.Last_Dress_Payment_Date).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric'
                        })
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>
                      {dressInfo && dressInfo.dress_info && dressInfo.dress_info.Next_Dress_Payment_Date ? (
                        new Date(dressInfo.dress_info.Next_Dress_Payment_Date).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric'
                        })
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>
                      {dressInfo && dressInfo.dress_info && dressInfo.dress_info.Rate_Amount !== undefined && dressInfo.dress_info.Rate_Amount !== null ? (
                        dressInfo.dress_info.Rate_Amount
                      ) : (
                        'N/A'
                      )}
                    </td>

                    <td>
                      {studentAttendance ? (
                        <p className={`text-${studentAttendance.Present ? 'success' : 'danger'}`}>
                          {studentAttendance.Present ? 'Present' : 'Absent'}
                        </p>
                      ) : (
                        student.status === 'Hold' && new Date(student.hold_start_date) <= new Date() ? (
                          <p>On Hold</p>
                        ) : (
                          <div id={styles.attendancebtn}>
                            <button
                              className={`btn btn-success present-${student.ID}`}
                              id={styles.attendancepresent}
                              onClick={() => markAttendance(student.ID, 'present')}
                              disabled={!isWithinWindow()}
                            >
                              Mark Present
                            </button>
                            <br /> <br />
                            <button
                              className={`btn btn-success absent-${student.ID}`}
                              id={styles.attendanceabsent}
                              onClick={() => markAttendance(student.ID, 'absent')}
                              disabled={!isWithinWindow()}
                            >
                              Mark Absent
                            </button>
                            {isWithinWindow() && userType === 'admin' && (
                              <>
                                <button className={`btn btn-primary extrapresent-${student.ID}`} onClick={() => handleExtraTeamsButtonClick(student.ID)}>
                                  Extra Classes Present
                                </button>
                                <button
                                  className={`btn btn-danger extraabsent-${student.ID}`}
                                  id={styles.attendanceabsent}
                                  onClick={() => markExtraAttendance(student.ID, 'absent')}

                                >
                                  Extra Classes Absent
                                </button>
                              </>
                            )}
                            {!isWithinWindow() && (
                              <>
                                <button className={`btn btn-primary extrapresent-${student.ID}`} onClick={() => handleExtraTeamsButtonClick(student.ID)}>
                                  Extra Classes Present
                                </button>
                                <button
                                  className={`btn btn-danger extraabsent-${student.ID}`}
                                  id={styles.attendanceabsent}
                                  onClick={() => markExtraAttendance(student.ID, 'absent')}

                                >
                                  Extra Classes Absent
                                </button>
                              </>
                            )}
                          </div>
                        )
                      )}
                    </td>
                    <td className={styles.actionButtons}>
                      <Button variant="primary" onClick={() => handleWelcome(student.First_Name, student.Last_Name)} id={styles.announcebtn}>
                        Send Welcome Email
                      </Button>{' '}
                      <Button variant="primary" onClick={() => handleOpenAnnouncementModal(student)} id={styles.announcebtn}>
                        Make Announcement
                      </Button>{' '}
                      <Button variant="info" onClick={() => handleOpenAttendanceModal(student)} id={styles.attendbtn}>
                        View Attendance
                      </Button>
                    </td>

                    <td>
                      <div className={styles.tdflexed}>
                        <p style={getBorderStyle(paymentTrackerStatus)}>{paymentTrackerStatus}</p>

                        <Button variant="info" onClick={() => handleViewCashHistory(student)} id={styles.cashHistoryBtn}>
                          View Payment History
                        </Button>
                        <Button variant="primary" onClick={() => handleSetCashMethod(student)} id={styles.paybutton}>
                          Update Payment
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {/* Announcement modal */}
        <Modal show={showAnnouncementModal} onHide={handleCloseAnnouncementModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Make Announcement for: {selectedStudentForAnnouncement
                ? `${selectedStudentForAnnouncement.First_Name} ${selectedStudentForAnnouncement.Last_Name}`
                : ''}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="announcementTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={announcementTitle}
                onChange={(e) => setAnnouncementTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="announcementContent">
              <Form.Label>Content</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={announcementContent}
                onChange={(e) => setAnnouncementContent(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAnnouncementModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAnnouncementSubmit}>
              Submit Announcement
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showAttendanceModal} onHide={handleCloseAttendanceModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              View Attendance for:{' '}
              {selectedStudentAttendance
                ? `${selectedStudentAttendance.First_Name} ${selectedStudentAttendance.Last_Name}`
                : ''}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedStudentAttendanceDetails && selectedStudentAttendanceDetails.length > 0 ? (
              <div>
                <h5>Attendance Records:</h5>
                <table className="table table-light table-bordered">
                  <thead>
                    <tr>
                      <th>Attendance Datetime</th>
                      <th>Present</th>
                      <th>Extra Class</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedStudentAttendanceDetails.map((record, index) => (
                      <tr key={index}>
                        <td>
                          {record.attendance_datetime ? (
                            new Date(record.attendance_datetime).toLocaleString('en-US', options)
                          ) : (
                            'Invalid Date'
                          )}
                        </td>
                        <td>{record.present ? 'Yes' : 'No'}</td>
                        <td>{record.Extra_Class ? 'Yes' : 'No'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No Attendance Data Found</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAttendanceModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showCashHistoryModal} onHide={handleCloseCashHistoryModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Cash History for: {selectedStudentCashHistory ? `${selectedStudentCashHistory.First_Name} ${selectedStudentCashHistory.Last_Name}` : ''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {cashHistoryData.length > 0 ? (
              <table className="table table-light table-bordered">
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>New Next Recurring Date</th>
                    <th>New Payment Date</th>
                    <th>Actions</th>
                    <th>Classes and Teams Enrolled</th>
                  </tr>
                </thead>
                <tbody>
                  {cashHistoryData.map((record, index) => {

                    const matchingStudent = students.find(student => (student.First_Name === record.student_first_name && student.Last_Name === record.student_last_name));
                    return (
                      <tr key={index}>
                        <td>{record.amount}</td>
                        <td>{record.new_next_recurring_date}</td>
                        <td>{record.new_payment_date}</td>
                        <td>
                          <Button variant="danger" onClick={() => handleDeleteRecord(record, selectedStudentCashHistory)}>Delete</Button> {/* Delete button */}
                        </td>
                        <td>
                          {matchingStudent ? (
                            <div>
                              {matchingStudent.Classes_Enrolled_In && matchingStudent.Classes_Enrolled_In.length > 0 && (
                                <div>{matchingStudent.Classes_Enrolled_In.join(', ')}</div>
                              )}
                              {matchingStudent.Teams_Enrolled_In && matchingStudent.Teams_Enrolled_In.length > 0 && (
                                <div>{matchingStudent.Teams_Enrolled_In.join(', ')}</div>
                              )}
                            </div>
                          ) : (
                            <div>No classes or teams enrolled</div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p>No cash history available for this student.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            {/* Footer content */}
          </Modal.Footer>
        </Modal>

        <Modal show={showCashPaymentModal} onHide={handleCloseCashPaymentModal}>
          <Modal.Header closeButton>
            <Modal.Title>Set Payment for: {selectedStudentForCashPayment
              ? `${selectedStudentForCashPayment.First_Name} ${selectedStudentForCashPayment.Last_Name}`
              : ''}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCashPaymentModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleCashPaymentSubmit}>
              Submit Cash Payment
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Edit Team Modal */}
        <Modal show={showEditTeamModal} onHide={handleCloseEditTeamModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Team</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="newTeamName">
              <Form.Label>New Team Name</Form.Label>
              <Form.Control
                type="text"
                value={newTeamName}
                onChange={(e) => setNewTeamName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="newInstructor">
              <Form.Label>New Instructor</Form.Label>
              <Form.Control
                as="select"
                value={newInstructor}
                onChange={(e) => setNewInstructor(e.target.value)}
              >
                <option value="">Select an instructor</option>
                {instructors.map((instructor, index) => (
                  <option key={index} value={`${instructor.First_Name} ${instructor.Last_Name}`}>
                    {`${instructor.First_Name} ${instructor.Last_Name}`}
                  </option>
                ))}
              </Form.Control>

            </Form.Group>
            <Form.Group controlId="backupInstructor">
            <Form.Label>Backup Instructor</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => setBackupInstructor(e.target.value)}
            >
              {/* Populate the dropdown with instructors */}
              <option value=''>Select A Backup Instructor</option>
              {instructors.map((instructor) => (
                <option key={instructor.ID} value={instructor.Username}>
                  {`${instructor.First_Name} ${instructor.Last_Name}`}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
            <Form.Group controlId="newCategory">
              <Form.Label>New Category</Form.Label>
              <Form.Control
                as="select"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
              >
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </Form.Control>

            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditTeamModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleEditTeamSubmit}>
              Submit Changes
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Delete Team Modal */}
        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Team</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete the team "{teamName}"?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteTeam}>
              Delete Team
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div>

  );
}

export default Teamsinside;