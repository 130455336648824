
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import Navbarin from '../components/Navbarin';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import styles from './excel.module.css';

function Excel() {

    const { userName, isLoggedIn, userClasses, lastName } = useAuth() || {};
    const navigate = useNavigate();


    useEffect(() => {
        document.title = 'Classes';

    }, []);

    if (!isLoggedIn) {
        navigate('/404');
        return null;
    }

  return (
   <div className="container-fluid position-relative" id={styles.main}>
            <div className="row">
                <Navbarin />
            </div>
      <div style={{ width: '100%', height: '85vh' }}>
        <iframe
          title="Google Slides"
          src="https://docs.google.com/spreadsheets/d/1KzfXqZgvM-EVnfovrvHwdMh_ccCM47yT1jC-KmYDs9Y/edit?usp=sharing"
          frameBorder="0"
          width="100%"
          height="100%"
          allowFullScreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        />
      </div>
    </div>
  );
}

export default Excel;
