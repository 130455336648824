import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../global.css';
import styles from '../pages/Adminhome.module.css';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import main from '../assets/img/logo5.png';

function Navbarin() {
    const { isLoggedIn, logout, userIsAdmin, userIsInstructor, userIsUser } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
    }

    // Check userType in localStorage for determining the home link
    const userType = localStorage.getItem('userType'); // Assuming 'userType' is stored in localStorage

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent pb-2 pt-2">
            <a className="navbar-brand" id={styles.brandnav}>
                <img src={main} alt="Nachdi Jawani" id={styles.logo} />
                <h6 className={styles.overlayheading}>Melanie</h6>
            </a>
           
            <button className="navbar-toggler" id={styles.hamburger} type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ml-auto" id="custom">
                    {userType === 'admin' ? (
                        <li className="nav-item active">
                            <a className="nav-link" id={styles.navlink} href='/admin'>Home <span className="sr-only">(current)</span></a>
                        </li>
                    ) : userType === 'Instructor' ? (
                        <li className="nav-item active">
                            <a className="nav-link" id={styles.navlink} href='/instructor'>Home <span className="sr-only">(current)</span></a>
                        </li>
                    ) : userType === 'student' ? (
                        <li className="nav-item active">
                            <a className="nav-link" id={styles.navlink} href='/user'>Home <span className="sr-only">(current)</span></a>
                        </li>
                    ) : null}
                    
                    {userType === 'admin' || userType === 'Instructor' || userType === 'student' ? (
                        <li className="nav-item  active">
                            <a className="nav-link" id={styles.navlink} href="/teams">Teams</a>
                        </li>
                    ) : null}
                    {userType === 'admin' || userType === 'Instructor' || userType === 'student' ? (
                        <li className="nav-item  active">
                            <a className="nav-link" id={styles.navlink} href="/classes">Classes</a>
                        </li>
                    ) : null}
                    {userType === 'admin' ? (
                        <li className="nav-item  active">
                            <a className="nav-link" id={styles.navlink} href="/dresses">Dresses</a>
                        </li>
                    ) : null}
                    {userType === 'admin'  ? (
                        <li className="nav-item active ">
                            <a className="nav-link" id={styles.navlink} href="/schedule">Schedule</a>
                        </li>
                    ) : null}
                    {userType === 'admin' || userType === 'volunteer' ? (
                        <li className="nav-item  active">
                            <a className="nav-link" id={styles.navlink} href="/volunteers">Volunteers</a>
                        </li>
                    ) : null}
                      {userType === 'admin' || userType === 'Instructor' ? (
                        <li className="nav-item  active">
                            <a className="nav-link" id={styles.navlink} href="/excel">Excel</a>
                        </li>
                    ) : null}
                    {isLoggedIn ? (
                        <li className="nav-item  active">
                            <button className={`btn btn-primary ${styles.btnlogout} ${styles.mr2}`} onClick={handleLogout}>Logout</button>
                        </li>
                    ) : null}
                </ul>
            </div>
        </nav>
    );
}

export default Navbarin;
