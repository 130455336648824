import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbarin from '../components/Navbarin';
import styles from './AllStudents.module.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-US', options);
}

function ViewCancelStudents() {
  const [students, setStudents] = useState([]);
  const [originalStudents, setOriginalStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const { isLoggedIn, userClasses } = useAuth() || {};
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Cancelled Students';
    return () => {
      document.title = 'Original Title';
    };
  }, []);

  useEffect(() => {
    // Make an API request to fetch all cancelled students
    axios.get('https://nachdijawani-melanie.com/api/cancelled-students/')
      .then((response) => {
        setStudents(response.data.cancelled_students || []); // Access cancelled_students key
        setOriginalStudents(response.data.cancelled_students || []);
      })
      .catch((error) => {
        console.error('Error fetching students:', error);
      });
  }, []);

  const filterStudents = () => {
    let filteredStudents = [...originalStudents];

    // Apply search term filter
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      const searchTermsArray = searchTermLower.split(' ');

      filteredStudents = filteredStudents.filter((student) => {
        return searchTermsArray.every((term) =>
          student.First_Name.toLowerCase().includes(term) ||
          student.Last_Name.toLowerCase().includes(term)
        );
      });
    }

    filteredStudents.sort((a, b) => a.First_Name.localeCompare(b.First_Name));

    setStudents(filteredStudents);
  };

  useEffect(() => {
    filterStudents();
  }, [searchTerm, selectedClass, selectedTeam, originalStudents]);

  const filteredStudentCount = students.length;

  return (
    <div className={`container-fluid position-relative ${styles.main}`}>
      <div className="row">
        <Navbarin />
        <div className={styles.studentList}>
          <h1 className={styles.heading}>Cancelled Students</h1>

          <div className={styles.searchBar}>
            <input
              type="text"
              placeholder="Search by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className={styles.filteredCount}>
            {filteredStudentCount === 0
              ? 'No students found'
              : `Showing ${filteredStudentCount} students`}
          </div>
          <table className={`table table-bordered table-light table-responsive ${styles.table}`}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Image</th>
                <th>Date of Birth</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Classes Enrolled In</th>
                <th>Teams Enrolled In</th>
                <th>Health Conditions</th>
                <th>Parent Phone</th>
                <th>Parent First Name</th>
                <th>Parent Last Name</th>
                <th>Terms</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/student-details/${encodeURIComponent(`${student.First_Name} ${student.Last_Name}`)}/${encodeURIComponent(student.Email)}`} className={styles.Link}>
                      {student.First_Name} {student.Last_Name}
                    </Link>
                  </td>
                  <td>
                    <img src={student.Image_URL} alt={`${student.First_Name} ${student.Last_Name}`} className={styles.avatarImage} />
                  </td>
                  <td>{formatDate(student.DOB)}</td>
                  <td><a href={`mailto:${student.Email}`}>{student.Email}</a></td>
                  <td><a href={`tel:${student.phoneNumber}`}>{student.phoneNumber}</a></td>
                  <td>
                    {student.Classes_Enrolled_In ? student.Classes_Enrolled_In.join(', ') : ''}
                  </td>
                  <td>
                    {student.Teams_Enrolled_In ? student.Teams_Enrolled_In.join(', ') : ''}
                  </td>
                  <td>{student.HealthConditions}</td>
                  <td>{student.parentPhoneNumber}</td>
                  <td>{student.parentFirstName}</td>
                  <td>{student.parentLastName}</td>
                  <td>{student.terms}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ViewCancelStudents;
