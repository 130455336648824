import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PaymentBookingRedirect = () => {
  const { email, dholPackage } = useParams();

  // Parse the dholPackage to get the base amount
  const baseAmountMatch = dholPackage.match(/\d+(\.\d{1,2})?/);
  const baseAmount = baseAmountMatch ? parseFloat(baseAmountMatch[0]) : 0.0;

  // Calculate tax amount (13%)
  const taxRate = 0.13;
  const taxAmount = baseAmount * taxRate;

  // Update charge_total with tax
  const chargeTotalWithTax = (baseAmount + taxAmount).toFixed(2);

  useEffect(() => {
    // Create a JSON string with the required parameters
    const preloadResponse = `{
      "ps_store_id": "N4D5Z71176",
      "hpp_key": "hpT2MLX9ZKRN",
      "charge_total": "${chargeTotalWithTax}",
      "email": "${email}",
      "rvar_email": "${email}",
      "rvar_dholPackage": "${dholPackage}"
    }`;

    const data = {
      paymentUrl: 'https://www3.moneris.com/HPPDP/index.php', // Use the production URL
      formContent: JSON.parse(preloadResponse),
    };

    performRedirect(data);
  }, [chargeTotalWithTax, email, dholPackage]);

  const performRedirect = (data) => {
    const form = document.createElement('form');
    form.id = 'PaymentForm';
    form.method = 'post'; // Set the form method to POST
    form.action = data.paymentUrl;

    // Add each parameter as a hidden input field
    for (const key in data.formContent) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = data.formContent[key];
      form.appendChild(input);
    }

    document.body.appendChild(form);

    try {
      form.submit();
    } catch (error) {
      // Log the error if there is any
      console.error('Error during form submission:', error);
    }
  };

  return (
    <div>
      Redirecting to Moneris hosted page...
    </div>
  );
};

export default PaymentBookingRedirect;
