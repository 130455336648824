// VolunteersTable.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Navbarin from '../components/Navbarin';
import { Modal, Button, Form } from 'react-bootstrap';
import styles from './Volunteers.module.css';

const VolunteersTable = () => {
  const [volunteers, setVolunteers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showSendToAllModal, setShowSendToAllModal] = useState(false);
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);

  const { isLoggedIn } = useAuth() || {};
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Volunteers';
    axios
      .get('https://nachdijawani-melanie.com/api/volunteers/')
      .then((response) => setVolunteers(response.data))
      .catch((error) => console.error('Error fetching volunteers:', error));
  }, []);

  if (!isLoggedIn) {
    navigate('/404');
    return null;
  }

  const filteredVolunteers = volunteers.filter(
    (volunteer) =>
      volunteer.First_Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      volunteer.Last_Name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleMakeAnnouncementClick = (volunteer) => {
    setSelectedVolunteer(volunteer);
    setShowModal(true);
  };

  const handleAnnouncementSubmit = () => {
    setShowModal(false);

    const title = document.getElementById('titleInput').value;
    const content = document.getElementById('contentInput').value;

    if (!selectedVolunteer) {
      console.error('No volunteer selected for announcement.');
      return;
    }

    const announcementData = {
      Title: title,
      Content: content,
      instructor_name: localStorage.getItem('userName'),
      last_name: localStorage.getItem('lastName'),
      type: 'volunteer_announcement',
      volunteer_id: selectedVolunteer.ID,
    };

    axios
      .post('https://nachdijawani-melanie.com/api/volunteersAnnouncement/', announcementData)
      .then((response) => {
        console.log('Announcement sent successfully:', response.data);
      })
      .catch((error) => {
        console.error('Announcement submission error:', error);
      });
  };

  const handleSendToAllClick = () => {
    setShowSendToAllModal(true);
  };

  const handleSendToAllSubmit = () => {
    setShowSendToAllModal(false);

    const title = document.getElementById('titleInputSendToAll').value;
    const content = document.getElementById('contentInputSendToAll').value;

    const announcementData = {
      Title: title,
      Content: content,
      instructor_name: localStorage.getItem('userName'),
      last_name: localStorage.getItem('lastName'),
      type: 'volunteer_announcement',
      volunteer_id: null,
    };

    axios
      .post('https://nachdijawani-melanie.com/api/volunteersAnnouncement/', announcementData)
      .then((response) => {
        console.log('Announcement sent to all successfully:', response.data);
      })
      .catch((error) => {
        console.error('Announcement to all submission error:', error);
      });
  };

  const goToAnnouncementPage = () => {
    navigate('/Volunteerannouncement');
  };

  return (
    <div className={`container-fluid position-relative ${styles.main}`}>
      <div className="row">
        <Navbarin />
        <div className={styles.studentList}>
          <h1 className={styles.heading}>View All Volunteers</h1>
          <div className={styles.searchBar}>
            <input
              type="text"
              id="searchInput"
              className="form-control"
              value={searchTerm}
              placeholder="Search By Name"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className={styles.filteredCount}>
            <p className="mb-3">Displaying {filteredVolunteers.length} volunteers</p>
          </div>
      
          <button className="btn btn-secondary" id={styles.otherbtn} onClick={handleSendToAllClick}>
            Send Announcement to All Volunteers
          </button>
          <a href='/volunteerAnnouncements'>
          <button className="btn btn-secondary"  id={styles.otherbtn} >
            View Announcements
          </button>
          </a>
          <table className={`table table-bordered table-light ${styles.table}`}>
            <thead>
              <tr>
                <th>ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>DOB</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredVolunteers.map((volunteer) => (
                <tr key={volunteer.ID}>
                  <td>{volunteer.ID}</td>
                  <td>{volunteer.First_Name}</td>
                  <td>{volunteer.Last_Name}</td>
                  <td>{volunteer.DOB}</td>
                  <td>{volunteer.Email}</td>
                  <td>{volunteer.phoneNumber}</td>
                  <td>
                    <button className="btn btn-primary" onClick={() => handleMakeAnnouncementClick(volunteer)}>
                      Make Announcement
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Individual Announcement Modal */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Make Announcement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="titleInput">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="Enter announcement title" />
              </Form.Group>
              <Form.Group controlId="contentInput">
                <Form.Label>Announcement</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Enter your announcement" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAnnouncementSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Send to All Modal */}
        <Modal show={showSendToAllModal} onHide={() => setShowSendToAllModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Send Announcement to All Volunteers</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="titleInputSendToAll">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="Enter announcement title" />
              </Form.Group>
              <Form.Group controlId="contentInputSendToAll">
                <Form.Label>Announcement</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Enter your announcement" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowSendToAllModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSendToAllSubmit}>
              Send to All
            </Button>
          </Modal.Footer>
        </Modal>
        {/* End Send to All Modal */}
      </div>
    </div>
  );
};

export default VolunteersTable;
