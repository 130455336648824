import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Navbarin from '../components/Navbarin';
import axios from 'axios';
import styles from './teams.module.css';
import { Modal, Form, Button } from 'react-bootstrap';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import TeamCard from '../components/TeamCard';

function Teams() {
    const [teams, setTeams] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        Team_Name_Batch: '',
        Start_Time: '',
        End_Time: '',
        Category: '',
        Days: [],
        Image: null, // Store the selected image file
        Room: ''
    });

    const { userName, isLoggedIn, userClasses, lastName } = useAuth() || {};
    const navigate = useNavigate();

    const allDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setFormData({
            Team_Name_Batch: '',
            Start_Time: '',
            End_Time: '',
            Category: '',
            Days: [],
            Image: null, // Clear the selected image when closing the modal
            Room: ''
        });
    };

    const handleInputChange = (e) => {
        const { name, value, checked, files } = e.target;

        if (name === "Days") {
            const updatedDays = [...formData.Days];
            if (checked) {
                updatedDays.push(value);
            } else {
                const index = updatedDays.indexOf(value);
                if (index !== -1) {
                    updatedDays.splice(index, 1);
                }
            }
            setFormData({
                ...formData,
                [name]: updatedDays,
            });
        } else if (name === "Image") {
            // Handle the image file selection
            setFormData({
                ...formData,
                [name]: files[0], // Use files array to get the selected image
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    useEffect(() => {
        document.title = 'Teams';

        const storedUsername = localStorage.getItem('userName')
        axios.get(`https://nachdijawani-melanie.com/api/Teams/?username=${storedUsername}&&lastname=${localStorage.getItem('lastName')}`)
            .then((response) => {
                setTeams(response.data); // Set team directly from the API response
            })
            .catch((error) => {
                console.error('Error fetching teams:', error);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Create a FormData object to send the form data
        const formDataToSend = new FormData();
        for (const key in formData) {
            if (key === "Days") {
                // Convert the "Days" array to a JSON string
                formDataToSend.append(key, JSON.stringify(formData[key]));
            } else {
                formDataToSend.append(key, formData[key]);
            }
        }

        // Send the FormData to your backend using Axios
        axios.post('https://nachdijawani-melanie.com/api/createTeam/', formDataToSend)
            .then((response) => {
                // Handle success
                const storedUsername = localStorage.getItem('userName')
                axios.get(`https://nachdijawani-melanie.com/api/Teams/?username=${storedUsername}&&lastname=${localStorage.getItem('lastName')}`)
                    .then((response) => {
                        setTeams(response.data); // Set team directly from the API response
                    })
                    .catch((error) => {
                        console.error('Error fetching teams:', error);
                    });
                // Optionally, you can clear the form data after a successful submission
                handleCloseModal();
            })
            .catch((error) => {
                // Handle errors
                console.error('Error creating team:', error);
            });
    };

    if (!isLoggedIn) {
        navigate('/404');
        return null;
    }

    return (
        <div className="container-fluid position-relative" id={styles.main}>
            <div className="row">
                <Navbarin />
            </div>

            {localStorage.getItem('userType') === 'admin' && (
                <button onClick={handleOpenModal} className="btn btn-primary" id={styles.createbtn}>
                    Create Team
                </button>
            )}
            <h1 className={styles.headings}>Your <span>Teams:</span></h1>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a Team</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Team Name:</Form.Label>
                            <Form.Control
                                type="text"
                                name="Team_Name_Batch"
                                value={formData.Team_Name_Batch}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Start Time:</Form.Label>
                            <Form.Control
                                type="time"
                                name="Start_Time"
                                value={formData.Start_Time}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>End Time:</Form.Label>
                            <Form.Control
                                type="time"
                                name="End_Time"
                                value={formData.End_Time}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Category:</Form.Label>
                            <select
                                name="Category"
                                value={formData.Category}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            >
                                <option value="">Select a category</option>
                                <option value="Dhol">Dhol</option>
                                <option value="Kids-Gidda">Kids Gidda</option>
                                <option value="Ladies-Gidda">Ladies Gidda</option>
                                <option value="Kids-Bhangra">Kids Bhangra</option>
                                <option value="Adult-Bhangra">Adult Bhangra</option>
                                <option value="Turban-Tying">Free Pagg Tying Class</option>
                                <option value="Bhangra-5-10-boys">Bhangra (5-10 Boys)</option>
                                <option value="Bhangra-5-10-girls">Bhangra (5-10 Girls)</option>
                                <option value="Bhangra-5-10-coed">Bhangra (5-10 Coed)</option>

                                <option value="Bhangra-10-14-boys"> Bhangra (10-14 Boys)</option>
                                <option value="Bhangra-10-14-girls">Bhangra (10-14 Girls)</option>
                                <option value="Bhangra-10-14-coed">Bhangra (10-14 Coed)</option>

                                <option value="Bhangra-14+boys">Bhangra (14+ Boys)</option>
                                <option value="Bhangra 14+girls">Bhangra (14+ Girls)</option>
                                <option value="Bhangra-14+coed">Bhangra (14+ Coed)</option>

                            </select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Days:</Form.Label>
                            {allDays.map((day) => (
                                <Form.Check
                                    key={day}
                                    type="checkbox"
                                    label={day}
                                    name="Days"
                                    value={day}
                                    checked={formData.Days.includes(day)}
                                    onChange={handleInputChange}
                                />
                            ))}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Image:</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                name="Image"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Room:</Form.Label>
                            <Form.Control
                                as="select"
                                name="Room"
                                value={formData.Room}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select a room</option>
                                <option value="Room1">Room 1</option>
                                <option value="Room2">Room 2</option>
                            </Form.Control>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button type="submit" variant="primary">
                            Save Team
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <div className="class-list" id={styles.cardlist}>
                <section>
                    <h2 className={styles.headings}>Weekday <span>Teams:</span></h2>
                    <div className={styles.cardclass}>
                        {teams.weekday_teams && teams.weekday_teams.length > 0 ? (
                            teams.weekday_teams.map((teamData) => (
                                <TeamCard key={teamData.ID} teamData={teamData} />
                            ))
                        ) : (
                            <p>No weekday teams available.</p>
                        )}
                    </div>
                </section>
                <section>
                    <h2 className={styles.headings}>Weekend <span>Teams:</span></h2>
                    <div className={styles.cardclass}>
                        {teams.weekend_teams && teams.weekend_teams.length > 0 ? (
                            teams.weekend_teams
                                .slice() // Create a copy of the array to avoid mutating the original
                                .sort((a, b) => {
                                    // Extract day, start time, and end time from the team data
                                    const dayA = a.Days[0];
                                    const dayB = b.Days[0];
                                    const startTimeA = a.Start_Time;
                                    const startTimeB = b.Start_Time;
                                    const endTimeA = a.End_Time;
                                    const endTimeB = b.End_Time;

                                    // Compare days first
                                    if (dayA !== dayB) {
                                        // Sort by day
                                        return dayA.localeCompare(dayB);
                                    } else if (startTimeA !== startTimeB) {
                                        // If days are the same, compare start times
                                        return startTimeA.localeCompare(startTimeB);
                                    } else {
                                        // If start times are the same, compare end times
                                        return endTimeA.localeCompare(endTimeB);
                                    }
                                })
                                .map((teamData) => (
                                    <TeamCard key={teamData.ID} teamData={teamData} />
                                ))
                        ) : (
                            <p>No weekend teams available.</p>
                        )}
                    </div>
                </section>



            </div>
        </div>
    );
}

export default Teams;
