//external register
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PaymentRedirect = () => {
  const { customerId, email, firstName, lastName,category } = useParams();

  let chargeTotal = 0.00;
  if(category === 'Gidda'){
      chargeTotal = 90.00
  }else if(category == 'Bhangra'){
    chargeTotal = 100.00
  }else if(category == 'Adult-Bhangra'){
    chargeTotal = 90.00
  }else if(category === 'Dhol'){
    chargeTotal = 120.00
  }
  const taxRate = 0.13;
  const taxAmount = chargeTotal * taxRate;
  const totalWithTax = chargeTotal + taxAmount;

  useEffect(() => {
    // Create a JSON string with the required parameters
    const prelaoadResponse = `{
      "ps_store_id": "NUG3971176",
      "hpp_key": "hpKHKG6PB31N",
      "charge_total":  "${totalWithTax.toFixed(2)}",
      "cust_id": "${customerId}",
      "email": "${email}",
      "rvar_firstname": "${firstName}",
      "rvar_lastname": "${lastName}"
    }`;

    console.log(prelaoadResponse)


    const data = {
      paymentUrl: 'https://www3.moneris.com/HPPDP/index.php',
      formContent: JSON.parse(prelaoadResponse),
    };

    performRedirect(data);
  }, [customerId, email, firstName, lastName, totalWithTax]);
  
  const performRedirect = (data) => {
    const form = document.createElement('form');
    form.id = 'PaymentForm';
    form.method = 'post'; // Set the form method to POST
    form.action = data.paymentUrl;

    // Add each parameter as a hidden input field
    for (const key in data.formContent) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = data.formContent[key];
      form.appendChild(input);
    }

    document.body.appendChild(form);

    try {
      form.submit();
    } catch (error) {
      // Log the error if there is any
      console.error('Error during form submission:', error);
    }
  };

  return (
    <div>
      Redirecting to Moneris hosted page...
    </div>
  );
};

export default PaymentRedirect;